// import { apiUrl,isEmpty } from "@/config";
// import Axios from "axios";

export default {
    methods: {
        viewSpentList(item){
            this.item_in_view = item
            this.spentListDialog = true
            this.spent_list_in_view = item.tmsamountspentfuelings
            this.total_spent = 0
            this.spent_list_in_view.forEach(spent =>{
                this.total_spent += parseInt(spent.amount) 
            })
        },
        
        
    }
}