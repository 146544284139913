import { apiUrl,isEmpty } from "@/config";
import Axios from "axios";

export default {
    methods: {
        formatBytes(bytes, decimals) {
            if (bytes == 0) return '0 Bytes';
            const k = 1024,
                dm = decimals <= 0 ? 0 : decimals || 2,
                sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },
        pickFile() {
            this.$refs.fileInput.click()
        },
        onFilePicked(e) {
            const minSize = 2097152 // 2MB
            const files = e.target.files
            const file = files[0]
            const imageSize = file.size
            if (file !== undefined) {
                this.image_name = file.name
                this.image_size = this.formatBytes(file.size)
                this.image_type = file.type

                if (this.image_name.lastIndexOf('.') <= 0) {
                    return
                }

                // Validations
                if (imageSize > minSize) {
                    this.canUpload = false
                    this.err = true
                    this.errMsg = "The chosen file is higher than " + this.min_size + " MegaByte"
                } else {
                    const fr = new FileReader()
                    fr.readAsDataURL(file)
                    fr.addEventListener('load', () => {
                        this.image_url = fr.result
                        this.image = file
                    })
                    this.canUpload = true
                    this.err = false
                }

            } else {
                this.image = ""
                this.image_name = ""
                this.image_size = ""
                this.image_type = ""
                this.image_url = ""
            }
        },
        openEvidenceDialog(item){
            this.item_in_view = item
            this.evidenceDialog = true
        },
        uploadEvidenceImage(item) {
            this.saving = true;
            const fd = new FormData();
            fd.append("evidence", this.image);
            fd.append("damage_id", item.id);
            Axios.post(
                `${apiUrl}/tmsdamages/damage/evidence`,
                 fd,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                    this.saving = false;
                    let data = response.data.data
                    let new_items = []
                    this.items.forEach((element,index) => {
                        new_items[index] = element
                    if(element == item){
                            new_items[index].evidence = data.evidence    
                            new_items[index] = item
                        }
                    });             
                    this.items = new_items
                    this.evidenceDialog = false

                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'success',
                        title: 'Request successful',
                        text: response.data.message
                    });
                    
                })
                .catch(({ response }) => {
                    this.saving = false;
                    console.log(response.data)
                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: "Upload failed!",
                        text: response.data.message
                    });
                });
        },
        clearFields(){
            this.date_damaged = ''
            this.description = ''
            this.evidence = ''
            this.vehicle = {}
        },
        removeItemFromAPI(item){
            Axios.delete(
                `${apiUrl}/tmsdamages/${item.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Deleted Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Delete Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
        },
        proceedToRemoveItem(item){
            this.item_in_view = item
            this.confirmDeleteDialog = true
        },
        removeItem(item){
            this.items.forEach((element,index) => {
                if(element == item){
                    this.removeItemFromAPI(item)
                    this.items.splice(index,1) 
                }
            });
            this.confirmDeleteDialog = false
             
        },
        updateItemOnAPI(item){
            Axios.put(
                `${apiUrl}/tmsdamages/${item.id}`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Updated Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Update Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
      
        },
        editItemArray(item){
            
            let mainn = 
            {
                id: item.id,
                vehicle: this.vehicle,
                date_damaged: this.date_damaged,
                description: this.description,
                evidence: this.evidence,
                // user: item.user, old user on DB
                 user: this.user,//logged in user
                    
            };

            let new_items = []
            this.items.forEach((element,index) => {
                new_items[index] = element
              if(element == item){
                    new_items[index] = mainn
                    let itemm = 
                    {
                        id: item.id,
                        description: mainn.description,
                        date_damaged: mainn.date_damaged,
                        reported_by: mainn.user.id,
                    };
                    this.updateItemOnAPI(itemm)
                }
            });             
            this.items = new_items 
            this.editItemDialog = false
            this.clearFields()
        },
        editItem(item){
            this.item_in_view = item
            this.date_damaged = this.item_in_view.date_damaged
            this.description = this.item_in_view.description
            this.evidence = this.item_in_view.evidence
            this.vehicle = this.item_in_view.vehicle
            this.editItemDialog = true 
        },
        addItem() {
            let item = 
            {
                id: 0,
                vehicle: this.vehicle,
                date_damaged: this.date_damaged,
                description: this.description,
                evidence: this.evidence,
                user: this.user,
                    
            };
            this.items.push(item)
            this.items.reverse()
            this.addItemDialog = false
            let new_item = 
            {
                description: item.description,
                date_damaged: item.date_damaged,
                vehicle_id: isEmpty(item.vehicle)? 0 : item.vehicle.id,
                reported_by: isEmpty(this.user)? 0 : item.user.id ,
            };
            this.addItemToAPI(new_item)
            this.clearFields()
        },

        addItemToAPI(item){
            Axios.post(
                `${apiUrl}/tmsdamages`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                   let data = response.data.data
                   //update the ID of the truck
                    if(data.tmsvehicle_id == item.vehicle_id && data.date_damaged == item.date_damaged && data.description == item.description){
                        this.items.forEach((storedData,index)=>{
                            if(storedData.date_damaged == item.date_damaged && storedData.vehicle.id == item.vehicle_id && storedData.description == item.description){
                                this.items[index].id = data.id
                            }             
                        })
                    }
                   this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Damage Added!",
                    text: response.data.message
                });
                })
                .catch(({ response }) => {
                    //since the id on the datatable is defaulted to ZERO
                    item.id = 0
                    this.items.forEach((element,index) => {
                        if(element.date_damaged == item.date_damaged && element.vehicle.id == item.vehicle_id && element.id == item.id){
                            this.items.splice(index,1) 
                        }
                    });

                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: "An Error Occured",
                        text: response.data.message
                    });
                    console.log(response.data)
                });
        },
        async getAllVehicles() {
            await Axios.get(
                 `${apiUrl}/tmsvehicles`,
                 {
                     headers: {
                         Authorization: `Bearer ${this.$root.auth}`
                     }
                 }
             )
             .then(response => {
                let data = response.data.data.reverse()
                this.vehicles_list = data
             })
             .catch(({ response }) => {
                 console.log(response)
                 this.loading = false
             });
        },
        publishTable(data){
            //for some weird reasons, vehicles_list doesn't update on mounted with async and await
            if(isEmpty(this.vehicles_list)){
              //  alert('List is empty')
                this.getAllVehicles() 
                this.refreshTable()
                return
            }
            data.forEach(item=>{
                var user = {}
                this.users_list.forEach(userr => {
                    if(userr.id == item.user_id){
                        user = userr            
                    }
                })

                var vehicle = {}
                this.vehicles_list.forEach(vehiclee => {
                    if(vehiclee.id == item.tmsvehicle_id){
                        vehicle = vehiclee           
                    }
                })

                var row = {
                    id: item.id,
                    vehicle: vehicle,
                    date_damaged: item.date_damaged,
                    description: item.description,
                    evidence: item.evidence,
                    user: user,
                };

                this.items.push(row)
            })
            
        },
        refreshTable(){
            this.loading = true
            this.items = []
            this.getAllData()
        },
       async getAllData() {
               await Axios.get(
                    `${apiUrl}/tmsdamages`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$root.auth}`
                        }
                    }
                )
                .then(response => {
                    let data = response.data.data.reverse()
                    this.publishTable(data)
                    this.loading = false
                })
                .catch(({ response }) => {
                    console.log(response)
                    this.loading = false
                });
        },
        
      

        async getAllVendors() {
            await  Axios.get(
                    `${apiUrl}/tmsvendors`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$root.auth}`
                        }
                    }
                )
                .then(response => {
                    console.log(response)
               
                      let data = response.data.data.reverse()
                      this.vendors_list = data
                    // this.publishTable(data)
                   // this.loading = false 
                })
                .catch(({ response }) => {
                    console.log(response)
                });
        },
        
       async getAllUsers() {
          await Axios.get(
                `${apiUrl}/users/all`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.users_list = response.data.data;
            })
            .catch(({ response }) => {
                console.log(response.data)
            });
        },   
    }
}