<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="addItemDialog = true">
                <v-icon left>add</v-icon>Add a Vendor
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td width="10">
                        <v-chip
                          class="ma-2"
                          color="green"
                          text-color="white"
                        >
                          <v-icon left>perm_identity</v-icon>
                          <h2>{{item.name}}</h2>   
                        </v-chip>
                        
                      </td>
                      <td>{{ item.company }}</td>
                      <td>{{ item.phone_number }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.address }}</td>
                      <td>{{ item.contact_person }}</td>
                      <td>{{ item.state }}</td>
                      <td>{{ item.country }}</td>
                      <td>
                        <v-btn color="blue" fab small dark @click="editItem(item)">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td>
                      
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>Total: {{ itemCount | formatNumber}}</v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- add item dialog -->
    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{add_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>

              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="title"
                    v-model="title"
                    label="Name Of Vendor"
                    type="text"
                    :rules="titleRules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="company"
                    v-model="company"
                    type="text"
                    label="Company"
                    class="ml-5"
                    :rules="companyRules"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="phone_number"
                    v-model="phone_number"
                    label="Phone Number"
                    type="text"
                    :rules="phoneRules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="email"
                    v-model="email"
                    type="text"
                    label="Email"
                    class="ml-5"
                    :rules="emailRules"
                  ></v-text-field>
                </v-flex>
              </v-layout>


              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="address"
                    v-model="address"
                    label="Address"
                    type="text"
                    :rules="addressRules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="contact_person"
                    v-model="contact_person"
                    type="text"
                    label="Contact Person"
                    class="ml-5"
                    :rules="contactPersonRules"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="state"
                    v-model="state"
                    label="State"
                    type="text"
                    :rules="addressRules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="country"
                    v-model="country"
                    type="text"
                    label="Country"
                    class="ml-5"
                    :rules="contactPersonRules"
                  ></v-text-field>
                </v-flex>
              </v-layout>


            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large  class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




<!-- edit item dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{edit_item_dialog_title}} ID: {{item_in_view.id}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>

              
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="title"
                    v-model="title"
                    label="Name Of Vendor"
                    type="text"
                    :rules="titleRules"
                    :value="title"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="company"
                    v-model="company"
                    type="text"
                    label="Company"
                    class="ml-5"
                    :rules="companyRules"
                    :value="company"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="phone_number"
                    v-model="phone_number"
                    label="Phone Number"
                    type="text"
                    :rules="phoneRules"
                    :value="phone_number"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="email"
                    v-model="email"
                    type="text"
                    label="Email"
                    class="ml-5"
                    :rules="emailRules"
                    :value="email"
                  ></v-text-field>
                </v-flex>
              </v-layout>


              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="address"
                    v-model="address"
                    label="Address"
                    type="text"
                    :rules="addressRules"
                    :value="address"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="contact_person"
                    v-model="contact_person"
                    type="text"
                    label="Contact Person"
                    class="ml-5"
                    :rules="contactPersonRules"
                    :value="contact_person"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="state"
                    v-model="state"
                    label="State"
                    type="text"
                    :rules="addressRules"
                    :value="state"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    name="country"
                    v-model="country"
                    type="text"
                    label="Country"
                    class="ml-5"
                    :rules="contactPersonRules"
                    :value="country"
                  ></v-text-field>
                </v-flex>
              </v-layout>



            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="warning" x-large  class="px-6" @click="editItemArray(item_in_view)">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Confirm delete dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="confirmDeleteDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{delete_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
               <h1>Do you want to delete {{item_in_view.name}} ?</h1>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn color="warning" x-large  class="px-6 white--text" @click="confirmDeleteDialog = false">
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" x-large  class="px-6 white--text" @click="removeItem(item_in_view)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    


  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";

export default {
  created() {
    document.title = "Vendors";
  },

  components: {
    InnerHeader
  },

  data() {
    return {
      sort_item_by_vendor_dialog_title: 'Search by Vendor',
      sortItemByVendorDialog: false,
      search_location: '',
      search_vendor: '',
      sortItemDialog: false,
      delete_item_dialog_title: 'Delete a Truck',
      confirmDeleteDialog: false,
      item_in_view : {},
      add_item_dialog_title : 'Add a Vendor',
      edit_item_dialog_title : 'Edit Vendor',
      minYear: new Date().getFullYear() + '-' + '01' + '-' + '01',
      maxYear: new Date().toISOString().substr(0, 10),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Vendors",
        subtitle: "View and manage Vendors.",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard"
          },
          {
            text: "Vendors",
            disabled: true,
            href: "vendor"
          }
        ]
      },
      items: [],
      itemCount: 0,
      search: null,
      title: "",
      status: "Just Added",
      headers: [
        { text: "Name", value: "name" },
        { text: "Company", value: "company" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Address", value: "address" },
        { text: "Contact Person", value: "contact_person" },
        { text: "State", value: "state" },
        { text: "Country", value: "country" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
      avatars: [],
      uploadDialog: false,
      naira: "&#8358;",
      users: [],
      proc_msg: "",
      showPins: false,
      dialog: false,
      modal: false,
      fetching: false,
      loading: true,
      saving: false,
      min_size: '2MB',
      checking: false,
      canUpload: false,
      upload_progress: 0,
      addItemDialog: false,
      editItemDialog: false,
      mrgn: "ma-4",
      image: null,
      error: false,
      phone_number:'',
      email:'',
      company:'',
      address:'',
      contact_person:'',
      state:'',
      country:'',
      errors: {},
      titleRules: [v => !!v || "Name of Vendor is required"],
      companyRules: [v => !!v || "Company Name is required"],
      phoneRules: [v => !!v || "Phone number is required"],
      emailRules: [v => !!v || "Email is required"],
      contactPersonRules: [v => !!v || "Contact Person is required"],
      addressRules: [v => !!v || "Address is required"],
      transactionDateRules: [
        v => !!v || "Date at which expense occured is required"
      ],
      amountRules: [v => !!v || "Amount spent is required"],
    };
  },

  mixins: [mounted, computed, methods]
};
</script>

<style>
.v-time-ago__text {
  font-size: 14px !important;
  cursor: pointer;
  color: #0097a7 !important;
}
.pinSize {
  font-size: 80px;
  width: 70% !important;
  text-align: center !important;
}
.myFocus{
  cursor: pointer;
  text-decoration: underline;
}
</style>
