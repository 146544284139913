<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              
              <!-- <v-spacer></v-spacer> 
              <v-btn fab color="blue" class="white--text" @click="refreshTable()">
                <v-icon>refresh</v-icon>
              </v-btn> -->
              <v-spacer></v-spacer> 
              <v-btn  fab color="secondary" @click="addItemDialog = true">
                <v-icon>add</v-icon>
                <!-- Add a Budget -->
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td width="10">
                        <v-chip
                          class="ma-2"
                          color="green"
                          text-color="white"
                        >
                          <v-icon left>local_shipping</v-icon>
                          <h2>
                            <span v-if="item.vehicle != {}" class="myFocus" @click="viewVehicle(item.vehicle)">{{item.vehicle.name}}</span>  
                            <span v-else>No Vehicle was added</span>  
                          </h2>   
                        </v-chip>
                        
                      </td>
                      <td class="myFocus">
                        <span v-if="item.job_order != {}" @click="viewJobOrder(item.job_order)">{{item.job_order.tracking_number}}</span>  
                        <span v-else>No Job Order attached</span>
                      </td>
                      <td class="myFocus" @click="viewDetail(item)">
                        View Detail
                        <!-- {{ item.spare_parts }} ({{item.spare_part_qty}}) -->
                      </td>
                      <!-- <td>
                        {{ item.description }}
                      </td> -->
                      <td>
                        {{ item.status }}
                      </td>
                      
                      <td> 
                          <v-chip color="success" dark>{{item.created_at}}</v-chip>    
                      </td>
                      <td>
                        <v-btn color="blue" fab small dark @click="editItem(item)">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td>
                      
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>Total: {{ itemCount | formatNumber}}</v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- add item dialog -->
    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{add_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="addItemDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>

                
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="vehicle"
                        v-model="vehicle"
                        :items="vehicles_list"
                        item-text="name"
                        label="Choose a Vehicle"
                        :rules="vehicleRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="spare_parts"
                          v-model="spare_parts"
                          type="text"
                          label="Spare Parts"
                          :rules="damagedWhatRules"
                          class="ml-5"
                        ></v-text-field>
                    </v-flex>
                    
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="job_order_list"
                        v-model="job_order"
                        :items="job_order_list"
                        item-text="name"
                        label="Choose a Job Order"
                        :rules="vehicleRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="status"
                        v-model="status"
                        :items="status_list"
                        item-text="name"
                        label="Choose Status"
                        :rules="vehicleRules"
                        return-object
                        class="ml-5"
                    >
                    </v-autocomplete>
                    </v-flex>
                    
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm6>
                        <v-text-field
                          name="description"
                          v-model="description"
                          type="text"
                          label="Description"
                          :rules="damagedWhatRules"
                        ></v-text-field>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="spare_part_qty"
                          v-model="spare_part_qty"
                          type="tel"
                          v-mask="['###########']"
                          label="Spare Parts Qty."
                          :rules="damagedWhatRules"
                          class="ml-5"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large  class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




<!-- edit item dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{edit_item_dialog_title}} ID: {{item_in_view.id}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>

                
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="vehicle"
                        v-model="vehicle"
                        :items="vehicles_list"
                        item-text="name"
                        label="Choose a Vehicle"
                        :rules="vehicleRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="spare_parts"
                          v-model="spare_parts"
                          type="text"
                          label="Spare Parts"
                          :rules="damagedWhatRules"
                          class="ml-5"
                        ></v-text-field>
                    </v-flex>
                    
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="job_order_list"
                        v-model="job_order"
                        :items="job_order_list"
                        item-text="name"
                        label="Choose a Job Order"
                        :rules="vehicleRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="status"
                        v-model="status"
                        :items="status_list"
                        item-text="name"
                        label="Choose Status"
                        :rules="vehicleRules"
                        return-object
                        class="ml-5"
                    >
                    </v-autocomplete>
                    </v-flex>
                    
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm6>
                        <v-text-field
                          name="description"
                          v-model="description"
                          type="text"
                          label="Description"
                          :rules="damagedWhatRules"
                        ></v-text-field>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="spare_part_qty"
                          v-model="spare_part_qty"
                          type="tel"
                          v-mask="['###########']"
                          label="Spare Parts Qty."
                          :rules="damagedWhatRules"
                          class="ml-5"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="warning" x-large  class="px-6" @click="editItemArray(item_in_view)">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Confirm delete dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="confirmDeleteDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{delete_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
               <h1 v-if="hasLoaded">Do you want to delete <br><br>"{{item_in_view.description}}"<br><br> from Repairs log?</h1>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn color="warning" x-large  class="px-6 white--text" @click="confirmDeleteDialog = false">
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" x-large  class="px-6 white--text" @click="removeItem(item_in_view)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- job order in view -->
    <v-dialog v-if="job_order_in_view != {}" v-model="jobOrderDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>
              <!-- {{job_order_in_view.name}} -->
              Job Order Detail
              </span>
            <v-spacer></v-spacer>
            <v-btn text @click="jobOrderDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
              <h3>Tracking Number: {{job_order_in_view.name}}</h3>
              <h3>Description: {{job_order_in_view.description}}</h3>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- view vehicle -->
    <v-dialog v-if="vehicle_in_view != {}" v-model="vehicleDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>
                <router-link class="myFocus" :to="'truckDetail/'+vehicle_in_view.slug+'/'+vehicle_in_view.id">
                    {{vehicle_in_view.name}}
                </router-link>
              </span>
            <v-spacer></v-spacer>
            <v-btn text @click="vehicleDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
              <h3>Plate Number: {{vehicle_in_view.plate_number}}</h3>
              <h3>Status: {{vehicle_in_view.status}}</h3>
              <div v-if="hasLoaded == true">
                   <h3>Location: {{vehicle_in_view.tmslocation.name}}</h3>
                   <h3>Region: {{vehicle_in_view.tmslocation.region}}</h3>
             </div>
            
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-if="item_in_view != {}" v-model="detailDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>Repair Detail</span>
            <v-spacer></v-spacer>
            <v-btn text @click="detailDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
              <h3>Description: {{item_in_view.description}}</h3>
              <h3>Spare Parts: {{item_in_view.spare_parts}}</h3>
              <h3>Spare Parts QTY: {{item_in_view.spare_part_qty}} unit(s)</h3>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";

export default {
  created() {
    document.title = "Repairs";
  },

  components: {
    InnerHeader
  },

  data() {
    return {
      detailDialog: false,
      vehicle_in_view: {},
      jobOrderDialog: false,
      vehicleDialog: false,
      spare_parts: '',
      job_order:{},
      job_order_in_view:{},
      job_order_list:[
        // {id:1,name:'Order 1',tracking_number:"87576"},
        // {id:2,name:'Order 2',tracking_number:"87576khj"},
        // {id:3,name:'Order 3',tracking_number:"ghy87576"},
      ],
      status_list: [
        {id:1,name:'Ongoing'},
        {id:2,name:'Completed'},
        {id:3,name:'Cancelled'},
      ],
      spare_part_qty: '',
      description: '',
      tracking_number: '',
      hasLoaded: false,
      vehicle: {},
      user: this.$root.curuser,
      vehicles_list:[],
      delete_item_dialog_title: 'Delete a Repair',
      confirmDeleteDialog: false,
      item_in_view : {},
      add_item_dialog_title : 'Add a Repair',
      edit_item_dialog_title : 'Edit Repair',
      minYear: new Date().getFullYear() + '-' + '01' + '-' + '01',
      maxYear: new Date().toISOString().substr(0, 10),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Repairs",
        subtitle: "View and manage vehicle Repair.",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard"
          },
          {
            text: "Repairs",
            disabled: true,
            href: "repair"
          }
        ]
      },
      users_list: [
      //  { id : 1, firstname : 'Chika', lastname : 'Enemnuo', othernames:'othername' },
      ],
      items: [],
      itemCount: 0,
      search: null,
      title: "",
      location: "",
      vendor: "",
      plate_number: "",
      status: {},
      headers: [
        { text: "Vehicle", value: "vehicle" },
        { text: "Job Order", value: "job_order" },
        { text: "View Detail", value: "view_detail" },
       // { text: "Description", value: "description" },
        { text: "Status", value: "status" },
        { text: "Date", value: "date" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
      avatars: [],
      uploadDialog: false,
      naira: "&#8358;",
      users: [],
      proc_msg: "",
      showPins: false,
      dialog: false,
      modal: false,
      fetching: false,
      loading: true,
      saving: false,
      min_size: '2MB',
      checking: false,
      canUpload: false,
      upload_progress: 0,
      addItemDialog: false,
      editItemDialog: false,
      mrgn: "ma-4",
      image: null,
      error: false,
      errors: {},
      checkedWhatRules: [v => !!v || "This field is required"],
      replacedWhatRules: [v => !!v || "This field is required"],
      vehicleRules: [v => !!v || "Choose a vehicle"],
      damagedWhatRules: [
        v => !!v || "This field is required"
      ],
      amountRules: [v => !!v || "Amount spent is required"],
    };
  },

  mixins: [mounted, computed, methods]
};
</script>

<style>
.v-time-ago__text {
  font-size: 14px !important;
  cursor: pointer;
  color: #0097a7 !important;
}
.pinSize {
  font-size: 80px;
  width: 70% !important;
  text-align: center !important;
}
.myFocus{
  cursor: pointer;
  text-decoration: underline;
}
</style>
