<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              
              <!-- <v-spacer></v-spacer> 
              <v-btn fab color="blue" class="white--text" @click="refreshTable()">
                <v-icon>refresh</v-icon>
              </v-btn> -->
              <v-spacer></v-spacer> 
              <v-btn  fab color="secondary" @click="addItemDialog = true">
                <v-icon>add</v-icon>
                <!-- Add a Budget -->
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td width="10">
                        <v-chip
                          class="ma-2"
                          color="green"
                          text-color="white"
                        >
                          <v-icon left>local_shipping</v-icon>
                          <h2>
                            <span v-if="item.vehicle != {}">
                                <router-link class="white--text myFocus" :to="'truckDetail/'+item.vehicle.slug+'/'+item.vehicle.id">
                                    {{item.vehicle.name}}
                                </router-link>
                              </span>  
                            <span v-else>No Vehicle was added</span>  
                          </h2>   
                        </v-chip>
                        
                      </td>
                      <td>
                        {{ item.tracking_number }}
                      </td>
                      <td>
                        {{ item.description }}
                      </td>
                      <!-- <td>
                          <span v-if="item.user != {}">{{item.user.surname + ' '+item.user.firstname}}</span>  
                          <span v-else>User not found</span> 
                      </td> -->
                      <td> 
                          <v-chip color="success" dark>{{item.created_at}}</v-chip>    
                      </td>
                      <td>
                        <v-btn color="blue" fab small dark @click="editItem(item)">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td>
                      
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>Total: {{ itemCount | formatNumber}}</v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- add item dialog -->
    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{add_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="addItemDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>

                
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="vehicle"
                        v-model="vehicle"
                        :items="vehicles_list"
                        item-text="name"
                        label="Choose a Vehicle"
                        :rules="vehicleRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="tracking_number"
                          v-model="tracking_number"
                          type="text"
                          label="Tracking Number"
                          :rules="damagedWhatRules"
                          class="ml-5"
                        ></v-text-field>
                    </v-flex>
                    
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm12>
                        <v-text-field
                          name="description"
                          v-model="description"
                          type="text"
                          label="Description"
                          :rules="damagedWhatRules"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large  class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




<!-- edit item dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{edit_item_dialog_title}} ID: {{item_in_view.id}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>

                
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="vehicle"
                        v-model="vehicle"
                        :items="vehicles_list"
                        item-text="name"
                        label="Choose a Vehicle"
                        :rules="vehicleRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="tracking_number"
                          v-model="tracking_number"
                          type="text"
                          label="Tracking Number"
                          :rules="damagedWhatRules"
                          class="ml-5"
                        ></v-text-field>
                    </v-flex>
                    
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm6>
                        <v-text-field
                          name="description"
                          v-model="description"
                          type="text"
                          label="Description"
                          :rules="damagedWhatRules"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="warning" x-large  class="px-6" @click="editItemArray(item_in_view)">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Confirm delete dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="confirmDeleteDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{delete_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
               <h1>Do you want to delete <br><br>"{{item_in_view.description}}"<br><br> from Job order log?</h1>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn color="warning" x-large  class="px-6 white--text" @click="confirmDeleteDialog = false">
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" x-large  class="px-6 white--text" @click="removeItem(item_in_view)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";

export default {
  created() {
    document.title = "Job Orders";
  },

  components: {
    InnerHeader
  },

  data() {
    return {
      description: '',
      tracking_number: '',
      hasLoaded: false,
      vehicle: {},
      damaged_what: '',
      checked_what: '',
      replaced_what: '',
      user: this.$root.curuser,
      vehicles_list:[],
      delete_item_dialog_title: 'Delete a Job Orders',
      confirmDeleteDialog: false,
      item_in_view : {},
      add_item_dialog_title : 'Add Job Orders',
      edit_item_dialog_title : 'Edit Job Orders',
      minYear: new Date().getFullYear() + '-' + '01' + '-' + '01',
      maxYear: new Date().toISOString().substr(0, 10),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Job Orders",
        subtitle: "View and manage Job Orders.",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard"
          },
          {
            text: "Job Orders",
            disabled: true,
            href: "job orders"
          }
        ]
      },
      users_list: [
      //  { id : 1, firstname : 'Chika', lastname : 'Enemnuo', othernames:'othername' },
      ],
      items: [],
      itemCount: 0,
      search: null,
      title: "",
      location: "",
      vendor: "",
      plate_number: "",
      status: "Available",
      headers: [
        { text: "Vehicle", value: "vehicle" },
        { text: "Tracking Number", value: "tracking_number" },
        { text: "Description", value: "description" },
        { text: "Date", value: "date" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
      avatars: [],
      uploadDialog: false,
      naira: "&#8358;",
      users: [],
      proc_msg: "",
      showPins: false,
      dialog: false,
      modal: false,
      fetching: false,
      loading: true,
      saving: false,
      min_size: '2MB',
      checking: false,
      canUpload: false,
      upload_progress: 0,
      addItemDialog: false,
      editItemDialog: false,
      mrgn: "ma-4",
      image: null,
      error: false,
      errors: {},
      checkedWhatRules: [v => !!v || "This field is required"],
      replacedWhatRules: [v => !!v || "This field is required"],
      vehicleRules: [v => !!v || "Choose a vehicle"],
      damagedWhatRules: [
        v => !!v || "This field is required"
      ],
      amountRules: [v => !!v || "Amount spent is required"],
    };
  },

  mixins: [mounted, computed, methods]
};
</script>

<style>
.v-time-ago__text {
  font-size: 14px !important;
  cursor: pointer;
  color: #0097a7 !important;
}
.pinSize {
  font-size: 80px;
  width: 70% !important;
  text-align: center !important;
}
.myFocus{
  cursor: pointer;
  text-decoration: underline;
}
</style>
