<template>
  <div class="dashboard">
    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title class="primary--text">
              <h1 class="active-box-title">
                <v-icon medium left color="primary">{{ active_box_icon }}</v-icon>
                <span class="text-uppercase">{{ active_box_title }}</span>
              </h1>
              <v-spacer></v-spacer>
              <span>&nbsp;</span>
              <v-spacer></v-spacer>
              <h4>{{ total_messages | formatNumber }} &nbsp; {{ active_box_notice }}</h4>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text v-slimscroll="slimScrollOptions">
              <v-layout row wrap>
                <v-flex xs12 sm3>
                  <v-btn class="primary mt-2 mb-2" @click="newMessageDialog = true">
                    <v-icon color="white" left>edit</v-icon>Compose Message
                  </v-btn>
                  <v-list-item
                    :class="{ msgactive : is_active == 1 }"
                    class="msg"
                    @click="activeBox(1)"
                  >
                    <v-list-item-action>
                      <v-icon :color="inbox_icon_color">all_inbox</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Inbox
                        <span class="to-right">
                          <v-chip
                            small
                            color="primary"
                            text-color="white"
                          >{{ total_unread_messages | formatNumber }}</v-chip>
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    :class="{ msgactive : is_active == 2 }"
                    class="msg"
                    @click="activeBox(2)"
                  >
                    <v-list-item-action>
                      <v-icon :color="outbox_icon_color">inbox</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Outbox</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    :class="{ msgactive : is_active == 3 }"
                    class="msg"
                    @click="activeBox(3)"
                  >
                    <v-list-item-action>
                      <v-icon :color="unread_icon_color">move_to_inbox</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Unread
                        <span class="to-right">
                          <v-chip
                            small
                            color="primary"
                            text-color="white"
                          >{{ total_unread_messages | formatNumber }}</v-chip>
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <div class="mt-4">
                    <div class="mt-8 mb-3"><h2>Labels</h2></div>
                    <p>
                      <v-icon small left color="primary">star</v-icon>High Priority
                    </p>
                    <p>
                      <v-icon small left color="cyan">star</v-icon>Medium Priority
                    </p>
                    <p>
                      <v-icon small left color="blue-grey">star</v-icon>Low Priority
                    </p>
                  </div>
                </v-flex>
                <v-flex xs12 sm9>
                  <div v-if="messages.length > 0">
                    <div v-if="!openedMessage">
                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-text-field
                            v-model="search"
                            clear-icon="close"
                            clearable
                            label="Enter Message Title Here To Search"
                            type="text"
                            solo
                            @click:clear="clearSearchTerm"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout
                        row
                        wrap
                        v-for="(msg, index) in filteredMessages"
                        :key="index"
                        class="msg pa-2 base-line"
                      >
                        <v-flex xs12 sm3>
                          <v-layout row wrap>
                            <v-flex xs2 sm1>
                              <span v-if="msg.priority === 'high'">
                                <span class="text-capitalize">
                                  <v-icon left small color="primary">star</v-icon>
                                </span>
                              </span>
                              <span v-else-if="msg.priority === 'medium'">
                                <span class="text-capitalize">
                                  <v-icon left small color="cyan">star</v-icon>
                                </span>
                              </span>
                              <span v-else>
                                <span class="text-capitalize">
                                  <v-icon left small color="blue-grey">star</v-icon>
                                </span>
                              </span>
                            </v-flex>

                            <v-flex xs10 sm11 class="is-clickable" @click="readMessage(msg.id)">
                              <span v-for="(user, index) in users" :key="index">
                                <span
                                  v-if="user.id === msg.sent_by"
                                >{{ user.surname }} {{ user.firstname }}</span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm7 class="is-clickable" @click="readMessage(msg.id)">
                          <span v-if="msg.is_read === 'NO'">
                            <span class="bold">{{ msg.title }}</span>
                          </span>
                          <span v-else>{{ msg.title }}</span>
                        </v-flex>
                        <v-flex xs12 sm2>
                          <span class="to-right">
                            <time-ago
                              refresh
                              :datetime="msg.sent_on"
                              long
                              tooltip="top"
                              class="time-ago-small"
                            ></time-ago>
                          </span>
                        </v-flex>
                      </v-layout>
                    </div>

                    <div v-else>
                      <div v-if="fetching">
                        <v-progress-linear indeterminate height="10" color="loadingcolor"></v-progress-linear>
                      </div>
                      <div v-else>
                        <v-card
                          flat
                          v-for="(message_content, index) in message_contains"
                          :key="index"
                        >
                          <v-card-title>
                            <h3>{{ message_content.title }}</h3>
                            <v-spacer></v-spacer>
                            <span v-if="message_content.priority === 'high'">
                              <span class="text-capitalize">
                                <v-icon left small color="primary">star</v-icon>
                              </span>
                            </span>
                            <span v-else-if="message_content.priority === 'medium'">
                              <span class="text-capitalize">
                                <v-icon left small color="cyan">star</v-icon>
                              </span>
                            </span>
                            <span v-else>
                              <span class="text-capitalize">
                                <v-icon left small color="blue-grey">star</v-icon>
                              </span>
                            </span>
                            <time-ago
                              refresh
                              :datetime="message_content.sent_on"
                              long
                              tooltip="top"
                              class="time-ago-small"
                            ></time-ago>
                          </v-card-title>
                          <v-card-text>
                            <v-layout row wrap>
                              <v-flex xs12 sm1>
                                <span v-for="(avatar, index) in avatars" :key="index">
                                  <span
                                    v-if="avatar.user_id == message_content.sent_by"
                                    class="msg-not"
                                  >
                                    <img :src="avatar.avatar" />
                                  </span>
                                </span>
                              </v-flex>
                              <v-flex xs12 sm11>
                                <span v-for="(user, index) in users" :key="index">
                                  <span v-if="user.id === message_content.sent_by">
                                    <h3>{{user.surname}} {{user.firstname}}</h3>
                                    <p>
                                      <span>
                                        <small>Email Address:</small>
                                        {{user.email}}
                                      </span>
                                      <br />
                                      <span>
                                        <small>Phone Number:</small>
                                        {{user.phone_number}}
                                      </span>
                                    </p>
                                  </span>
                                </span>
                              </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                              <v-flex xs12>
                                <v-card flat class="ml-msg-cont">
                                  <v-card-text class="no-overflow">{{ message_content.message }}</v-card-text>
                                </v-card>
                              </v-flex>
                            </v-layout>
                          </v-card-text>
                          <v-card-actions class="base-line">
                            <v-spacer></v-spacer>
                            <v-btn
                              flat
                              @click="replyMessage(message_content.id, message_content.msg_id, message_content.title, message_content.sent_by)"
                            >
                              <v-icon left>reply</v-icon>Reply
                            </v-btn>
                            <v-btn
                              flat
                              @click="forwardMessage(message_content.id, message_content.msg_id, message_content.title)"
                            >
                              Forward
                              <v-icon left>forward</v-icon>
                            </v-btn>
                          </v-card-actions>
                        </v-card>

                        <v-card v-if="replyMessageComponent" class="mt-5">
                          <v-card-title class="primary--text lighten-3">
                            <h3>Reply Message</h3>
                            <v-spacer></v-spacer>
                            <v-btn flat @click="closeMessageComponent">
                              <v-icon left>cancel</v-icon>Close
                            </v-btn>
                          </v-card-title>
                          <v-divider class="primary"></v-divider>
                          <v-card-text>
                            <v-layout row wrap>
                              <v-flex xs12 sm7>
                                <p>Recipient:</p>
                                <p>
                                  <span>
                                    {{ selected_user_names }} (
                                    <strong>{{ selected_user_email }}</strong>
                                    )
                                  </span>
                                </p>
                              </v-flex>
                              <v-flex xs12 sm2>
                                <v-autocomplete
                                  class="mr-2"
                                  v-model="priority"
                                  :items="priorities"
                                  item-text="name"
                                  label="Select Message Priority"
                                  :rules="priorityRules"
                                  ref="repMsg"
                                ></v-autocomplete>
                              </v-flex>
                              <v-flex xs12 sm3>
                                <span v-if="priority === 'high'">
                                  <v-chip pill class="white--text">
                                    <v-avatar left color="primary">H</v-avatar>
                                    <span class="primary--text text-uppercase">High Priority</span>
                                  </v-chip>
                                </span>
                                <span v-else-if="priority === 'medium'">
                                  <v-chip pill class="white--text">
                                    <v-avatar left color="cyan">M</v-avatar>
                                    <span class="cyan--text text-uppercase">Medium Priority</span>
                                  </v-chip>
                                </span>
                                <span v-else>
                                  <v-chip pill class="white--text">
                                    <v-avatar left color="grey">L</v-avatar>
                                    <span class="grey--text text-uppercase">Low Priority</span>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>

                            <v-layout row wrap>
                              <v-flex xs12>
                                <v-text-field
                                  name="name"
                                  v-model="message_title"
                                  type="text"
                                  label="Message Title"
                                  disabled
                                ></v-text-field>
                              </v-flex>
                            </v-layout>

                            <v-layout row wrap>
                              <v-flex xs12>
                                <v-textarea
                                  v-model="message"
                                  name="message"
                                  auto-grow
                                  clearable
                                  clear-icon="cancel"
                                  solo
                                  counter
                                  :rules="messageRules"
                                  label="What do you have to say?"
                                ></v-textarea>
                                <v-alert
                                  v-if="message.length > 500"
                                  :value="true"
                                  type="error"
                                >The message has exceeded the maximum number of characters of 500</v-alert>
                              </v-flex>
                            </v-layout>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn flat @click="closeMessageComponent">
                              <v-icon left>cancel</v-icon>Close
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn :disabled="sending" class="primary" @click="sendReply()">
                              {{ sending ? 'Sending Message...' : 'Send Message'}}
                              <v-icon right>send</v-icon>
                            </v-btn>
                          </v-card-actions>
                        </v-card>

                        <v-card v-if="forwardMessageComponent" class="mt-5">
                          <v-card-title class="grey lighten-3">
                            <h3>Forward Message</h3>
                            <v-spacer></v-spacer>
                            <v-btn flat @click="closeMessageComponent">
                              <v-icon left>cancel</v-icon>Close
                            </v-btn>
                          </v-card-title>
                          <v-card-text>
                            <v-layout row wrap>
                              <v-flex xs12 sm9>
                                <v-autocomplete
                                  v-model="selectedUser"
                                  :items="users"
                                  item-text="email"
                                  label="Select Recipient"
                                  persistent-hint
                                  return-object
                                  single-line
                                  @change="getSelectedUser"
                                  :rule="recipientRules"
                                ></v-autocomplete>
                                <span v-if="selected_user_email != ''">
                                  {{ selected_user_names }} (
                                  <strong>{{ selected_user_email }}</strong> )
                                </span>
                              </v-flex>
                              <v-flex xs12 sm3>
                                <v-autocomplete
                                  class="mr-2"
                                  v-model="priority"
                                  :items="priorities"
                                  item-text="name"
                                  label="Select Message Priority"
                                  :rules="priorityRules"
                                ></v-autocomplete>
                                <span v-if="priority === 'high'">
                                  <v-chip pill class="white--text">
                                    <v-avatar left color="primary">H</v-avatar>
                                    <span class="primary--text text-uppercase">High Priority</span>
                                  </v-chip>
                                </span>
                                <span v-else-if="priority === 'medium'">
                                  <v-chip pill class="white--text">
                                    <v-avatar left color="cyan">M</v-avatar>
                                    <span class="cyan--text text-uppercase">Medium Priority</span>
                                  </v-chip>
                                </span>
                                <span v-else>
                                  <v-chip pill class="white--text">
                                    <v-avatar left color="grey">L</v-avatar>
                                    <span class="grey--text text-uppercase">Low Priority</span>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>

                            <v-layout row wrap>
                              <v-flex xs12>
                                <v-text-field name="name" v-model="message_title" type="text"></v-text-field>
                              </v-flex>
                            </v-layout>

                            <v-layout row wrap>
                              <v-flex xs12>
                                <v-textarea
                                  v-model="message"
                                  name="message"
                                  auto-grow
                                  clearable
                                  clear-icon="cancel"
                                  solo
                                  counter
                                  :rules="messageRules"
                                  label="What do you have to say?"
                                ></v-textarea>
                                <v-alert
                                  v-if="message.length > 500"
                                  :value="true"
                                  type="error"
                                >The message has exceeded the maximum number of characters of 500</v-alert>
                              </v-flex>
                            </v-layout>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn flat @click="closeMessageComponent">
                              <v-icon left>cancel</v-icon>Close
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn :disabled="sending" class="primary" @click="sendReply()">
                              {{ sending ? 'Sending Message...' : 'Send Message'}}
                              <v-icon right>send</v-icon>
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <h3>No message found</h3>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog
      v-model="newMessageDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="newMessageDialog = false">
            <v-icon color="white">close</v-icon>
          </v-btn>
          <v-toolbar-title>Compose New Message</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn @click="sendMessage">
              Send Message
              <v-icon color="white" right>send</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card flat>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="message_title"
                  clear-icon="close"
                  clearable
                  label="Enter Message Title Here"
                  type="text"
                  solo
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm9>
                <v-autocomplete
                  v-model="selectedUser"
                  :items="users"
                  item-text="email"
                  label="Select Recipient"
                  persistent-hint
                  return-object
                  single-line
                  @change="getSelectedUser"
                  :rule="recipientRules"
                  solo
                ></v-autocomplete>
                <span v-if="selected_user_email != ''">
                  {{ selected_user_names }} (
                  <strong>{{ selected_user_email }}</strong> )
                </span>
              </v-flex>
              <v-flex xs12 sm3>
                <v-autocomplete
                  class="ml-2"
                  v-model="priority"
                  :items="priorities"
                  item-text="name"
                  label="Select Message Priority"
                  :rules="priorityRules"
                  solo
                ></v-autocomplete>
                <span v-if="priority === 'high'">
                  <v-chip pill class="white--text">
                    <v-avatar left color="primary">H</v-avatar>
                    <span class="primary--text text-uppercase">High Priority</span>
                  </v-chip>
                </span>
                <span v-else-if="priority === 'medium'">
                  <v-chip pill class="white--text">
                    <v-avatar left color="cyan">M</v-avatar>
                    <span class="cyan--text text-uppercase">Medium Priority</span>
                  </v-chip>
                </span>
                <span v-else>
                  <v-chip pill class="white--text">
                    <v-avatar left color="grey">L</v-avatar>
                    <span class="grey--text text-uppercase">Low Priority</span>
                  </v-chip>
                </span>
              </v-flex>
            </v-layout>

            <v-layout row wrap class="mt-4">
              <v-flex xs12>
                <v-textarea
                  v-model="message"
                  name="message"
                  auto-grow
                  clearable
                  clear-icon="close"
                  solo
                  counter
                  :rules="messageRules"
                  label="What do you have to say?"
                ></v-textarea>
                <v-alert
                  v-if="message.length > 500"
                  :value="true"
                  type="error"
                >The message has exceeded the maximum number of characters of 500</v-alert>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mounted from "./mounted";
import methods from "./methods";

export default {
  created() {
    document.title = "Message";
  },

  computed: {
    filteredMessages: function() {
      return this.messages.filter(msg => {
        return msg.title.match(this.search);
      });
    }
  },

  data() {
    return {
      slimScrollOptions: {
        height: "490px",
        size: 12
      },
      user_id: this.$root.curuser.id,
      sender_surname: this.$root.curuser.surname,
      sender_firstname: this.$root.curuser.firstname,

      search: "",
      query: "",
      activeFilters: [],

      message_type: "all",
      is_active: 1,
      inbox_icon_color: "primary",
      outbox_icon_color: "grey darken-1",
      unread_icon_color: "grey darken-1",
      active_box_title: "Inbox",
      active_box_icon: "all_inbox",
      active_box_notice: "Messages",
      deleteDialog: false,
      openedMessage: false,
      newMessageDialog: false,
      selected: [],
      isLoading: true,
      sending: false,
      avatars: [],
      users: [],
      selectedUser: [],
      priorities: [{ name: "low" }, { name: "medium" }, { name: "high" }],
      fetchNotitfications: "",
      messages: [],
      total_messages: 0,
      total_unread_messages: 0,
      message_contains: [],
      openMessage: false,
      fetchMessages: "",
      fetching: false,
      message_content: [],
      response: "",
      forwardMessageComponent: false,
      replyMessageComponent: "",
      message_id: "",
      message_title: "",
      title: "",
      message: "",
      priority: "low",
      sent_to: "",
      msg_id: "",
      selected_user_names: "",
      selected_user_email: "",
      priorityRules: [v => !!v || "Message priority is required"],
      titleRules: [v => !!v || "Message title is required"],
      messageRules: [
        v => !!v || "Message is required",
        v => v.length <= 500 || "Max 300 characters"
      ],
      recipientRules: [v => !!v || "Message recipient is required"]
    };
  },

  mixins: [mounted, methods]
};
</script>

<style>
.v-chip--small {
  height: 20px !important;
}
</style>
