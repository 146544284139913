import { apiUrl } from "@/config";
import Axios from "axios";

export default {
    methods: {
        formatBytes(bytes, decimals) {
            if (bytes == 0) return '0 Bytes';
            const k = 1024,
                dm = decimals <= 0 ? 0 : decimals || 2,
                sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },
        pickFile() {
            this.$refs.fileInput.click()
        },
        onFilePicked(e) {
            const minSize = 2097152 // 2MB
            const files = e.target.files
            const file = files[0]
            const imageSize = file.size
            if (file !== undefined) {
                this.image_name = file.name
                this.image_size = this.formatBytes(file.size)
                this.image_type = file.type

                if (this.image_name.lastIndexOf('.') <= 0) {
                    return
                }

                // Validations
                if (imageSize > minSize) {
                    this.canUpload = false
                    this.err = true
                    this.errMsg = "The chosen file is higher than " + this.min_size + " MegaByte"
                } else {
                    const fr = new FileReader()
                    fr.readAsDataURL(file)
                    fr.addEventListener('load', () => {
                        this.image_url = fr.result
                        this.image = file
                    })
                    this.canUpload = true
                    this.err = false
                }

            } else {
                this.image = ""
                this.image_name = ""
                this.image_size = ""
                this.image_type = ""
                this.image_url = ""
            }
        },
        openEvidenceDialog(item){
            this.item_in_view = item
            this.evidenceDialog = true
        },
        uploadEvidenceImage(item) {
            this.saving = true;
            const fd = new FormData();
            fd.append("evidence", this.image);
        //    fd.append('id',item.id)
            Axios.put(
                // `${apiUrl}/profiles/user/${this.user_id}/upload-image`,
                `${apiUrl}/tmsdamages/${item.id}`,
                 fd,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                    this.saving = false;
                    
                    // this.$forceUpdate();
                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'success',
                        title: 'Request successful',
                        text: response.data.message
                    });
                    
                })
                .catch(({ response }) => {
                    this.saving = false;
                    console.log(response.data)
                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: "Upload failed!",
                        text: response.data.message
                    });
                });
        },
        
 
    }
}