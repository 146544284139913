import { todayDate,timeNow } from "@/config";

export default {
    methods: {
        
        openTripFeedbackDialog(Detail){
            Detail.dialog = false
            this.tripFeedbackDialog = true
            this.trip_feedback = Detail.item_in_view.trip_feedback
        },
        openDispatchedByDialog(Detail){
            Detail.dialog = false
            this.dispatchedByDialog = true
            this.user = Detail.item_in_view.dispatched_by_object
        },
        updateTrip(Detail){
            this.tripFeedbackDialog = false
            Detail.item_in_view.trip_feedback = this.trip_feedback
            this.$emit('updateDetailComponent', Detail)
        },  
        updateDispatchedBy(Detail){
            this.dispatchedByDialog = false
            Detail.item_in_view.dispatched_by = this.user.id
            Detail.item_in_view.dispatched_by_object = this.user
            Detail.item_in_view.dispatched_on = todayDate()
            Detail.item_in_view.is_dispatched = 'YES'
            Detail.item_in_view.dispatch_time = timeNow()
            
            this.$emit('updateDetailComponent', Detail)
        },  
        openConfirmedByDialog(Detail){
            Detail.dialog = false
            this.confirmedByDialog = true
            this.user = Detail.item_in_view.confirmed_by_object
        },
        updateConfirmedBy(Detail){
            this.confirmedByDialog = false
            Detail.item_in_view.confirmed_by = this.user.id
            Detail.item_in_view.confirmed_by_object = this.user
            Detail.item_in_view.confirmed_on = todayDate()
            this.$emit('updateDetailComponent', Detail)
        },
        openCancelledByDialog(Detail){
            Detail.dialog = false
            this.user = Detail.item_in_view.cancelled_by_object
            this.cancel_reason = Detail.item_in_view.reason_for_cancel
            this.cancelledByDialog = true
           
        },
        updateCancelledBy(Detail){
            this.cancelledByDialog = false
            Detail.item_in_view.cancelled_by = this.user.id
            Detail.item_in_view.cancelled_by_object = this.user
            Detail.item_in_view.reason_for_cancel = this.cancel_reason
            Detail.item_in_view.is_cancelled = 'YES'
            Detail.item_in_view.cancelled_on = todayDate()
            this.$emit('updateDetailComponent', Detail)
        },
        openReturnedTimeDialog(Detail){
            Detail.dialog = false
            this.return_time = Detail.item_in_view.returned_time
            this.returnedTimeDialog = true
        },
        updateReturnTime(Detail){
            this.returnedTimeDialog = false
            Detail.item_in_view.returned_time = this.return_time
            Detail.item_in_view.is_returned = 'YES'
            this.$emit('updateDetailComponent', Detail)
        },
    }
}