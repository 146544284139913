import { apiUrl,isEmpty } from "@/config";
import Axios from "axios";

export default {
    methods: {
        viewDetail(item){
            this.item_in_view = item
            this.detailDialog = true
        },
        viewVehicle(vehicle){
            this.vehicle_in_view = vehicle
            this.hasLoaded = true
            this.vehicleDialog = true
        },
        viewJobOrder(job_order){
            this.job_order_in_view = job_order
            this.jobOrderDialog = true
        },
        clearFields(){
            this.spare_part_qty = ''
            this.spare_parts = ''
            this.job_order = {}
            this.description = ''
            this.vehicle = {}
        },
        removeItemFromAPI(item){
            Axios.delete(
                `${apiUrl}/tmsrepairs/${item.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Deleted Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Delete Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
        },
        proceedToRemoveItem(item){
            this.item_in_view = item
            this.confirmDeleteDialog = true
        },
        removeItem(item){
            this.items.forEach((element,index) => {
                if(element == item){
                    this.removeItemFromAPI(item)
                    this.items.splice(index,1) 
                }
            });
            this.confirmDeleteDialog = false
             
        },
        updateItemOnAPI(item){
            Axios.put(
                `${apiUrl}/tmsrepairs/${item.id}`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Updated Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Update Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
      
        },
        editItemArray(item){
            
            let mainn = 
            {
                id: item.id,
                vehicle: item.vehicle,
                job_order: this.job_order,
                description: this.description,
                spare_parts: this.spare_parts,
                spare_part_qty: this.spare_part_qty,
                status: this.status.name,
          //      user: this.user,
                created_at: item.created_at,
                    
            };



            let new_items = []
            this.items.forEach((element,index) => {
                new_items[index] = element
              if(element == item){
                    new_items[index] = mainn
                    
                    let itemm = 
                    {
                        id: item.id,
                        vehicle_id: item.vehicle.id,
                        job_order_id: item.job_order.id,
                        description: mainn.description,
                        spare_parts: mainn.spare_parts,
                        spare_part_qty: mainn.spare_part_qty,
                     //   added_by: mainn.user.id,
                    };
                    this.updateItemOnAPI(itemm)
                }
            });             
            this.items = new_items 
            this.editItemDialog = false
            this.clearFields()
        },
        editItem(item){
            this.item_in_view = item
            this.tracking_number = this.item_in_view.tracking_number
            this.description = this.item_in_view.description
            this.vehicle = this.item_in_view.vehicle
            this.status = this.item_in_view.status
            this.job_order = this.item_in_view.job_order
            this.spare_part_qty = this.item_in_view.spare_part_qty
            this.spare_parts = this.item_in_view.spare_parts
            this.editItemDialog = true 
        },
        addItem() {
            let item = 
            {
                id: 0,
                vehicle: this.vehicle,
                job_order: this.job_order,
                description: this.description,
                spare_parts: this.spare_parts,
                spare_part_qty: this.spare_part_qty,
                status: this.status.name,
          //      user: this.user,
                created_at: 'Just Now'
                    
            };
            
            this.items.push(item)
            this.items.reverse()
            this.addItemDialog = false
            let new_item = 
            {
                job_order_id: item.job_order.id,
                status: item.status,
                spare_parts: item.spare_parts,
                spare_part_qty: item.spare_part_qty,
                description: item.description,
                vehicle_id: isEmpty(item.vehicle)? 0 : item.vehicle.id,
            //    added_by: isEmpty(this.user)? 0 : item.user.id ,
            };
            this.addItemToAPI(new_item)
            this.clearFields()
        },

        addItemToAPI(item){
            Axios.post(
                `${apiUrl}/tmsrepairs`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                   let data = response.data.data
                   //update the ID of the truck
                    if(data.tmsvehicle_id == item.vehicle_id && data.tracking_number == item.tracking_number){
                        this.items.forEach((storedData,index)=>{
                            if(storedData.description == item.description && storedData.vehicle.id == item.vehicle_id && storedData.spare_part_qty == item.spare_part_qty){
                                this.items[index].id = data.id
                                this.items[index].created_at = data.created_at
                            }             
                        })
                    }
                   this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Repair Added!",
                    text: response.data.message
                });
                })
                .catch(({ response }) => {
                    //since the id on the datatable is defaulted to ZERO
                    item.id = 0
                    this.items.forEach((element,index) => {
                        if(element.description == item.description && element.vehicle.id == item.vehicle_id && element.id == item.id){
                            this.items.splice(index,1) 
                        }
                    });

                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: "An Error Occured",
                        text: response.data.message
                    });
                    console.log(response.data)
                });
        },
        async getAllVehicles() {
            await Axios.get(
                 `${apiUrl}/tmsvehicles`,
                 {
                     headers: {
                         Authorization: `Bearer ${this.$root.auth}`
                     }
                 }
             )
             .then(response => {
                let data = response.data.data.reverse()
                this.vehicles_list = data
             })
             .catch(({ response }) => {
                 console.log(response)
                 this.loading = false
             });
        },
        publishTable(data){
            //for some weird reasons, vehicles_list doesn't update on mounted with async and await
            if(isEmpty(this.vehicles_list)){
              //  alert('List is empty')
                this.getAllVehicles()
                this.refreshTable()
                return
            }
            if(isEmpty(this.job_order_list)){
              //  alert('List is empty')
                this.getAllJobOrders()
                this.refreshTable()
                return
            }
            
            data.forEach(item=>{
                // var user = {}
                // this.users_list.forEach(userr => {
                //     if(userr.id == item.added_by){
                //         user = userr            
                //     }
                // })

                var vehicle = {}
                this.vehicles_list.forEach(vehiclee => {
                    if(vehiclee.id == item.tmsvehicle_id){
                        vehicle = vehiclee           
                    }
                })
                
                var job_order = {}
                this.job_order_list.forEach(job=>{
                    if(job.id == item.job_order_id){
                        job_order = job
                    }
                })

                
              //  var status = {}
                var status_name = ''
                this.status_list.forEach(statuss=>{
                    if(statuss.name == item.status){
                  //      status = statuss
                        status_name = item.status
                    }
                })

                let row = {
                        id: item.id,
                        vehicle: vehicle,
                        job_order: job_order,
                        description: item.description,
                        spare_parts: item.spare_parts,
                        spare_part_qty: item.spare_part_qty,
                        status: status_name,
                  //      user: this.user,
                        created_at: item.created_at
                            
                    };
                    
                this.items.push(row)
            })
            
        },
        refreshTable(){
            this.loading = true
            this.items = []
            this.getAllData()
        },
       async getAllData() {
               await Axios.get(
                    `${apiUrl}/tmsrepairs`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$root.auth}`
                        }
                    }
                )
                .then(response => {
                    let data = response.data.data.reverse()
                    this.publishTable(data)
                    this.loading = false
                })
                .catch(({ response }) => {
                    console.log(response)
                    this.loading = false
                });
        },
        
      

        async getAllVendors() {
            await  Axios.get(
                    `${apiUrl}/tmsvendors`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$root.auth}`
                        }
                    }
                )
                .then(response => {
                    console.log(response)
               
                      let data = response.data.data.reverse()
                      this.vendors_list = data
                    // this.publishTable(data)
                   // this.loading = false 
                })
                .catch(({ response }) => {
                    console.log(response)
                });
        },
        
       async getAllUsers() {
          await Axios.get(
                `${apiUrl}/users/all`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.users_list = response.data.data;
            })
            .catch(({ response }) => {
                console.log(response.data)
            });
        },
        async getAllJobOrders() {
            await Axios.get(
                 `${apiUrl}/tmsjoborders`,
                 {
                     headers: {
                         Authorization: `Bearer ${this.$root.auth}`
                     }
                 }
             )
             .then(response => {
                 let data = response.data.data.reverse()
                 var jo = []
                 data.forEach(order =>{
                    var lm = { 
                        id:order.id,
                        name:order.tracking_number,
                        description:order.description,
                        tracking_number:order.tracking_number
                    }
                    jo.push(lm)
                 })//duke
                 this.job_order_list = jo
             })
             .catch(({ response }) => {
                 console.log(response)
                 this.loading = false
             });
     },   
    }
}