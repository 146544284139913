import { apiUrl,isEmpty } from "@/config";
import Axios from "axios";

export default {
    methods: {
        
        addAmountSpentToAPI(budget,item,index){
            
            Axios.post(
                `${apiUrl}/tmsamountspents`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                   let data = response.data.data
                   //update the ID of the item
                    if(data.tmsbudget_id == item.budget_id && data.amount == item.amount){
                     //   alert(JSON.stringify(this.items[index].amount_spent_list))
                        this.items[index].amount_spent_list.forEach((storedData,indexx)=>{
                            if(storedData.amount == item.amount && storedData.tmsbudget_id == item.budget_id){
                                this.items[index].amount_spent_list[indexx].id = data.id
                                this.items[index].amount_spent_list[indexx].created_at = data.created_at
                                this.items[index].amount_spent_list[indexx].updated_at = data.updated_at
                            }             
                        })
                    }
                   this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Added Successfully!",
                    text: response.data.message
                });
                })
                .catch(({ response }) => {
                    //since the id on the datatable is defaulted to ZERO
                    item.id = 0
                    this.items[index].amount_spent_list.forEach((storedData,indexx)=>{
                        if(storedData.amount == item.amount && storedData.tmsbudget_id == item.budget_id){
                            this.items[index].amount_spent_list.splice(indexx,1)
                        }             
                    })
                    

                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: "An Error Occured",
                        text: response.data.message
                    });
                    console.log(response.data)
                });
        },
        
        addSpentItemToArray(budget){
            let spent = { 
                id:0,
                tmsbudget_id:budget.id,
                added_by:null,
                created_at:'Just Now',
                updated_at:'Just Now',
                amount: this.amount_spent,
                description: this.amount_spent_description,
                }
            this.items.forEach((item,index)=>{
                if(item == budget){
                    this.items[index].amount_spent_list.push(spent)
                    this.addAmountSpentItemDialog = false
                    let data = {
                        amount: spent.amount,
                        description: spent.description,
                        budget_id: budget.id,
                //      added_by: isEmpty(this.user)? 0 : this.user.id ,
                    }
                    this.addAmountSpentToAPI(budget,data,index)
                }
            })
        },
        showBudgetLeft(item){
            //alert(JSON.stringify(item))//duke
            let spent = 0
            item.amount_spent_list.forEach(spentt=>{
                spent += parseInt(spentt.amount)
            })
            //var output = item.amount - this.amount_spent
            var output = (item.amount - spent) - this.amount_spent
            return (output > 0)?output:0;
        },
        calculateBalance(item){
            var bal = this.showBudgetLeft(item)
            if(bal > 0){
                return 'Available: N'+bal 
            }
            return 'You cannot spend more than the budget' 
        },
        addAmountSpent(item){
            this.amount_spent = 0;
            this.amount_spent_description = '';
            this.budget_in_view = item
            this.addAmountSpentItemDialog = true
            this.isAdding = true
        },
        viewSpentList(item){
            this.item_in_view = item
            this.spentListDialog = true
            this.spent_list_in_view = item.amount_spent_list
            this.total_spent = 0
            this.spent_list_in_view.forEach(spent =>{
                this.total_spent += parseInt(spent.amount) 
            })
        },
        clearFields(){
            this.amount = ''
            this.title = ''
            this.vehicle = {}
        },
        removeItemFromAPI(item){
            Axios.delete(
                `${apiUrl}/tmsbudgets/${item.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Deleted Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Delete Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
        },
        proceedToRemoveItem(item){
            this.item_in_view = item
            this.confirmDeleteDialog = true
        },
        removeAmountSpentFromAPI(item){
            Axios.delete(
                `${apiUrl}/tmsamountspents/${item.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Deleted Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Delete Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
        },
        proceedToRemoveAmountSpent(item){
            // this.budget_in_view = item
             this.spent_in_view = item
             this.confirmDeleteAmountSpentDialog = true
        },
        removeAmountSpent(item){
            this.items.forEach((row,index)=>{
                var amount_spent_list = row.amount_spent_list
                amount_spent_list.forEach((spent,i)=>{
                    if(item == spent){
                        this.items[index].amount_spent_list.splice(i,1) 
                        this.confirmDeleteAmountSpentDialog = false
                        this.removeAmountSpentFromAPI(item)
                    }
                })
            })
             
        },
        
        removeItem(item){
            this.items.forEach((element,index) => {
                if(element == item){
                    this.removeItemFromAPI(item)
                    this.items.splice(index,1) 
                }
            });
            this.confirmDeleteDialog = false
             
        },
        updateItemOnAPI(item){
            Axios.put(
                `${apiUrl}/tmsbudgets/${item.id}`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Updated Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Update Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
      
        },
        editItemArray(item){
            this.$refs.editItemForm.validate()
            if(this.validateForm()== false){
                return
            }
            let mainn = 
            {
                id: item.id,
                vehicle: item.vehicle,//since they shouldn't change the vehicle
                title: this.title,
                amount: this.amount,
                created_at: item.created_at,
                amount_spent_list: item.amount_spent_list
                // user: item.user, old user on DB
               //  user: this.user,//logged in user
                    
            };

            let new_items = []
            this.items.forEach((element,index) => {
                new_items[index] = element
              if(element == item){
                    new_items[index] = mainn
                    let itemm = 
                    {
                        id: item.id,
                        vehicle_id: item.vehicle.id,
                        title: mainn.title,
                        amount: mainn.amount,
                    };
                    this.updateItemOnAPI(itemm)
                }
            });             
            this.items = new_items 
            this.editItemDialog = false
            this.clearFields()
        },
        editItem(item){
            this.item_in_view = item
            this.amount = this.item_in_view.amount
            this.title = this.item_in_view.title
            this.vehicle = this.item_in_view.vehicle
            this.editItemDialog = true 
        },
        validateForm(){
            if(this.title == ''){
                return false
            }
            if(this.amount == ''){
                return false
            }
            if(isEmpty(this.vehicle)){
                return false
            }
            return true
        },
        addItem() {
            this.$refs.addItemForm.validate()
            if(this.validateForm()== false){
                return
            }
            let item = 
            {
                id: 0,
                vehicle: this.vehicle,
                amount: this.amount,
                title: this.title,
            //    user: this.user,
                created_at: 'Just Now',
                amount_spent_list: this.amount_spent_list,
                    
            };
            this.items.push(item)
            this.items.reverse()
            this.addItemDialog = false
            let new_item = 
            {
                amount: item.amount,
                title: item.title,
                vehicle_id: isEmpty(item.vehicle)? 0 : item.vehicle.id,
          //      reported_by: isEmpty(this.user)? 0 : item.user.id ,
            };
            this.addItemToAPI(new_item)
            this.clearFields()
        },

        addItemToAPI(item){
            Axios.post(
                `${apiUrl}/tmsbudgets`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                   let data = response.data.data
                   //update the ID of the truck
                    if(data.tmsvehicle_id == item.vehicle_id && data.title == item.title && data.amount == item.amount){
                        this.items.forEach((storedData,index)=>{
                            if(storedData.amount == item.amount && storedData.vehicle.id == item.vehicle_id && storedData.title == item.title){
                                this.items[index].id = data.id
                                this.items[index].created_at = data.created_at
                            }             
                        })
                    }
                   this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Budget Added!",
                    text: response.data.message
                });
                })
                .catch(({ response }) => {
                    //since the id on the datatable is defaulted to ZERO
                    item.id = 0
                    this.items.forEach((element,index) => {
                        if(element.amount == item.amount && element.vehicle.id == item.vehicle_id && element.id == item.id){
                            this.items.splice(index,1) 
                        }
                    });

                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: "An Error Occured",
                        text: response.data.message
                    });
                    console.log(response.data)
                });
        },
        async getAllVehicles() {
            await Axios.get(
                 `${apiUrl}/tmsvehicles`,
                 {
                     headers: {
                         Authorization: `Bearer ${this.$root.auth}`
                     }
                 }
             )
             .then(response => {
                let data = response.data.data.reverse()
                this.vehicles_list = data
             })
             .catch(({ response }) => {
                 console.log(response)
                 this.loading = false
             });
        },
        publishTable(data){
            //for some weird reasons, vehicles_list doesn't update on mounted with async and await
            if(isEmpty(this.vehicles_list)){
              //  alert('List is empty')
                this.getAllVehicles() 
                this.refreshTable()
                return
            }
            data.forEach(item=>{
                // var user = {}
                // this.users_list.forEach(userr => {
                //     if(userr.id == item.user_id){
                //         user = userr            
                //     }
                // })

                var vehicle = {}
                this.vehicles_list.forEach(vehiclee => {
                    if(vehiclee.id == item.tmsvehicle_id){
                        vehicle = vehiclee           
                    }
                })

           //     alert(JSON.stringify(item.tmsamountspents))
                let row = 
                    {
                        id: item.id,
                        vehicle: vehicle,
                        amount: item.amount,
                        title: item.title,
                    //    user: user,
                        created_at: item.created_at,
                        amount_spent_list: item.tmsamountspents,      
                    };
                this.items.push(row)
            })
            
        },
        refreshTable(){
            this.loading = true
            this.items = []
            this.getAllData()
        },
       async getAllData() {
               await Axios.get(
                    `${apiUrl}/tmsbudgets`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$root.auth}`
                        }
                    }
                )
                .then(response => {
                    let data = response.data.data.reverse()
                    this.publishTable(data)
                    this.loading = false
                })
                .catch(({ response }) => {
                    console.log(response)
                    this.loading = false
                });
        },
        
      

        async getAllVendors() {
            await  Axios.get(
                    `${apiUrl}/tmsvendors`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$root.auth}`
                        }
                    }
                )
                .then(response => {
                    console.log(response)
               
                      let data = response.data.data.reverse()
                      this.vendors_list = data
                    // this.publishTable(data)
                   // this.loading = false 
                })
                .catch(({ response }) => {
                    console.log(response)
                });
        },
        
       async getAllUsers() {
          await Axios.get(
                `${apiUrl}/users/all`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.users_list = response.data.data;
            })
            .catch(({ response }) => {
                console.log(response.data)
            });
        },   
    }
}