<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              
              
              <v-spacer></v-spacer>
              <v-btn color="green white--text" @click="sortItemByStatusDialog = true">
                <v-icon left>search</v-icon> by Status
              </v-btn>
              <v-spacer></v-spacer> 
              <v-btn  fab color="secondary" @click="addItemDialog = true">
                <v-icon>add</v-icon>
                <!-- Add a Maintenance -->
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td width="10" @click="viewClient(item)">
                        <v-chip
                          class="ma-2"
                          color="green"
                          text-color="white"
                        >
                          <h2 class="white--text myFocus">
                            {{item.name}}
                          </h2>   
                        </v-chip>
                        
                      </td>
                      <!-- <td>{{ item.contact_phone }}</td>
                      <td>{{ item.contact_email }}</td> -->
                      <td @click="openLogoDialog(item)" class="myFocus">
                        <span v-if="item.logo != '' && item.logo != null">
                           <v-img
                              :src="item.logo"
                              class="is-clickable"
                              alt="Logo Img"
                              height="100%"
                              width="40px"
                            ></v-img>
                          </span>
                          <span v-else>
                            add Logo
                          </span>
                      </td>
                      <td>
                        <span v-if="item.added_by != {}">{{ item.added_by.lastname }} {{ item.added_by.firstname }}</span>
                        <span v-else>User was not Found</span>
                      </td>
                      <td> 
                          {{item.contact_person}}    
                      </td>
                      <td> 
                          <v-chip :color="(item.status =='Active')?'success':'red'" dark>{{item.status}}</v-chip>    
                      </td>
                      <td class="myFocus" @click="proceedToViewBranches(item)">Branches</td>
                      <td>
                        <v-btn color="green" fab small dark @click="proceedToaddBranchItem(item)">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn color="blue" fab small dark @click="editItem(item)">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td>
                      
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>Total: {{ itemCount | formatNumber}}</v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- add item dialog -->
    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{add_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
          <v-form ref="addItemForm" @submit.prevent="addItem">
         
            <v-card-text>

                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="name"
                          v-model="name"
                          type="text"
                          label="Name of Client"
                          :rules="requiredRules"
                        ></v-text-field>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="contact_person"
                          v-model="contact_person"
                          type="text"
                          class="ml-4"
                          label="Contact Person"
                          :rules="requiredRules"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="contact_phone"
                          v-model="contact_phone"
                          type="text"
                          label="Phone Number"
                          :rules="requiredRules"
                        ></v-text-field>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="contact_email"
                          v-model="contact_email"
                          type="text"
                          class="ml-4"
                          label="Email Address"
                          :rules="requiredRules"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="client_status"
                        v-model="client_status"
                        :items="status_list"
                        item-text="name"
                        label="Status"
                        :rules="statusRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    
                </v-layout>
                             
            </v-card-text>
          </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large  class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




<!-- edit item dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{edit_item_dialog_title}} ID: {{item_in_view.id}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="editItemForm" @submit.prevent="editItemArray(item_in_view)">
            <v-card-text>

                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="name"
                          v-model="name"
                          type="text"
                          label="Name of Client"
                          :rules="requiredRules"
                        ></v-text-field>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="contact_person"
                          v-model="contact_person"
                          type="text"
                          class="ml-4"
                          label="Contact Person"
                          :rules="requiredRules"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="contact_phone"
                          v-model="contact_phone"
                          type="text"
                          label="Phone Number"
                          :rules="requiredRules"
                        ></v-text-field>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="contact_email"
                          v-model="contact_email"
                          type="text"
                          class="ml-4"
                          label="Email Address"
                          :rules="requiredRules"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="client_status"
                        v-model="client_status"
                        :items="status_list"
                        item-text="name"
                        label="Vehicle Status"
                        :rules="statusRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    
                </v-layout>

              
            </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="warning" x-large  class="px-6" @click="editItemArray(item_in_view)">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Confirm delete dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="confirmDeleteDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{delete_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
               <h1>Do you want to delete <br><br>"{{item_in_view.description}}"<br><br> from damages log?</h1>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn color="warning" x-large  class="px-6 white--text" @click="confirmDeleteDialog = false">
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" x-large  class="px-6 white--text" @click="removeItem(item_in_view)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- evidence dialog -->
    <v-dialog v-if="item_in_view != {} && evidenceDialog" v-model="evidenceDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>Upload Evidence</span>
            <v-spacer></v-spacer>
            <v-btn text @click="evidenceDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-flex xs12>
                  <v-card flat>
                    <v-card-text>
                      <h3>
                        Ensure the image file is
                        <span class="bold">JPG</span> or
                        <span class="bold">PNG</span> and the size should not be more than
                        <span
                          class="bold"
                        >{{ min_size }} MegaByte</span>
                      </h3>
                      <v-layout row wrap>
                        <v-flex xs12 sm3></v-flex>
                        <v-flex xs12 sm6>
                          <input
                            type="file"
                            ref="fileInput"
                            :rules="uploadRules"
                            class="no-display"
                             accept="image/jpeg"
                            @change="onFilePicked"
                          />
                          <v-card class="ma-4" shaped max-width="400">
                            <v-img
                              v-if="image_url"
                              class="white--text align-end"
                              height="400px"
                              :src="image_url"
                            ></v-img>
                            <v-img
                              v-else-if="item_in_view.logo != null"
                              :src="item_in_view.logo"
                              @click="pickFile"
                              class="is-clickable"
                            ></v-img>
                            <v-img
                              v-else
                              :src="images.avatar"
                              @click="pickFile"
                              class="is-clickable"
                            ></v-img>
                          </v-card>
                          <v-layout row wrap>
                            <v-flex xs12>
                              <h3>
                                Name:
                                <span class="bold">{{ image_name }}</span> &nbsp; Format:
                                <span class="bold">{{ image_type }}</span> &nbsp; Size:
                                <span class="bold">{{ image_size }}</span>
                              </h3>
                            </v-flex>
                          </v-layout>
                          <v-alert v-if="err" :value="true" type="error">{{ errMsg }}</v-alert>
                          <span class="ma-5">
                            <v-btn
                              x-large
                              outlined
                              color="primary"
                              @click="pickFile"
                            >Choose Image File To Upload</v-btn>
                          </span>
                          <v-layout row wrap v-if="saving" class="mt-5">
                            <v-flex xs12>
                              <v-progress-linear color="primary lighten-2" buffer-value="0" stream></v-progress-linear>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm3></v-flex>
                      </v-layout>
                    </v-card-text>
                    <v-card-actions v-if="canUpload">
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="canUpload"
                        :disabled="saving"
                        color="primary"
                        @click="uploadEvidenceImage(item_in_view)"
                      >
                        {{ saving ? 'Updating Logo...' : 'Upload &amp; Save' }}
                        <v-icon right v-if="!saving">cloud_upload</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>     
        </v-card-text>
      </v-card>
    </v-dialog>


        <!-- search by status -->
    <v-dialog v-model="sortItemByStatusDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>Search By Status </span>
            <v-spacer></v-spacer>
            <v-btn text @click="sortItemByStatusDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="search_status"
                        v-model="search_status"
                        :items="status_list"
                        item-text="name"
                        label="Choose a Status"
                        :rules="requiredRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                </v-layout>
    
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="warning" x-large class="px-6" @click="searchItemsByStatus(search_status)">
            <v-icon left>search</v-icon>
            Find
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Add branch dialog -->
    <v-dialog v-if="item_in_view != {} && client_branch_in_view != {}" v-model="addBranchDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>Add a branch to '{{item_in_view.name}}' </span>
            <v-spacer></v-spacer>
            <v-btn text @click="addBranchDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addBranchItemForm" @submit.prevent="addBranchItem(item_in_view)">
                <v-card-text>
                <v-layout row wrap>
                    <v-flex xs12 sm12>
                        <v-text-field
                          name="branch_name"
                          v-model="branch_name"
                          type="text"
                          label="Name of Branch"
                          :rules="requiredRules"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm12>
                        <v-text-field
                          name="branch_address"
                          v-model="branch_address"
                          type="text"
                          label="Address of Branch"
                          :rules="requiredRules"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="branch_status"
                        v-model="branch_status"
                        :items="status_list"
                        item-text="name"
                        label="Branch Status"
                        :rules="statusRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    
                </v-layout>
    
            </v-card-text>
          
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="red" x-large class="px-6 white--text" @click="addBranchItem(item_in_view)">
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

        <!-- edit branch dialog -->
    <v-dialog v-if="client_branch_in_view != {}" v-model="editBranchesDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>Edit Branch in '{{item_in_view.name}}' ID:{{client_branch_in_view.id}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="editBranchesDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="editBranchItemForm" @submit.prevent="doEditBranchItem(item_in_view,client_branch_in_view)">
                <v-card-text>
                <v-layout row wrap>
                    <v-flex xs12 sm12>
                        <v-text-field
                          name="branch_name"
                          v-model="branch_name"
                          type="text"
                          label="Name of Branch"
                          :rules="requiredRules"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm12>
                        <v-text-field
                          name="branch_address"
                          v-model="branch_address"
                          type="text"
                          label="Address of Branch"
                          :rules="requiredRules"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="branch_status"
                        v-model="branch_status"
                        :items="status_list"
                        item-text="name"
                        label="Branch Status"
                        :rules="statusRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    
                </v-layout>
    
            </v-card-text>
          
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="red" x-large class="px-6 white--text" @click="doEditBranchItem(item_in_view,client_branch_in_view)">
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>






    <!-- View branch dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="viewBranchesDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>Branches in '{{item_in_view.name}}' </span>
            <v-spacer></v-spacer>
            <v-btn text @click="viewBranchesDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
              
              <template>
                <v-simple-table fixed-header >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Address</th>
                        <th class="text-left">Status</th>
                        <th class="text-left">Edit</th>
                        <th class="text-left">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,i) in item_in_view.citclientbranches" :key="i">
                        <td>
                          {{ item.name }}
                        </td>
                        <td>
                            {{ item.address }}
                        </td>
                        <td>
                            <v-chip :color="(item.status =='Active' || item.status =='active')?'success':'red'" dark>{{item.status}}</v-chip>
                        </td>
                        <td>
                        <v-btn color="blue" fab small dark @click="editBranchItem(item)">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveBranchItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>

            </v-card-text>
          </v-card>
        </v-card-text>
        
      </v-card>
    </v-dialog>



    <!-- View Client dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="viewClientDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{item_in_view.name}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="viewClientDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
               <h4>Contact Person: {{item_in_view.contact_person}}</h4>
               <h4>Phone: {{item_in_view.contact_phone}}</h4>
               <h4>Email: {{item_in_view.contact_email}}</h4>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>



    <!-- Confirm delete branch dialog -->
    <!-- item_in_view remains unchanged  -->
    <v-dialog v-if="client_branch_in_view != {}" v-model="confirmDeleteBranchDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{delete_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteBranchDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
               <h1>Do you want to delete 
                 <br><br>"{{client_branch_in_view.name}}"<br><br> 
                from "{{item_in_view.name}}"?
                </h1>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn color="warning" x-large  class="px-6 white--text" @click="confirmDeleteBranchDialog = false">
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" x-large  class="px-6 white--text" @click="removeBranchItem(item_in_view,client_branch_in_view)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";

export default {
  created() {
    document.title = "CIT Clients";
  },

  components: {
    InnerHeader
  },

  data() {
    return {
      branch_status: {},
      confirmDeleteBranchDialog: false,
      viewClientDialog: false,
      viewBranchesDialog: false,
      addBranchDialog: false,
      editBranchesDialog: false,
      branch_name: '',
      branch_address: '',
      client_branch_in_view:{},
      sortItemByStatusDialog: false,
      search_status: "",
      contact_email: "",
      contact_phone: "",
      contact_person: "",
      client_status : "",
      name: "",
      status_list:this.$root.client_statuses,
      images: {
        avatar: require("@/assets/avatar.png")
      },
      err: false,
      errMsg: "",
      min_size: 0,
      image_name: "",
      image_size: "",
      image_type: "",
      image_url: "",
      uploadRules: [
        value =>
          !value ||
          value.size < 2000000 ||
          "Evidence size should be less than 2 MB!"
      ],
      statusRules: [v => !!v || "Location is required"],
      evidenceDialog: false,
      hasLoaded: false,
      user: this.$root.curuser,
      delete_item_dialog_title: 'Delete a CIT Client',
      confirmDeleteDialog: false,
      item_in_view : {},
      add_item_dialog_title : 'Add a CIT Client',
      edit_item_dialog_title : 'Edit CIT Client',
      minYear: new Date().getFullYear() + '-' + '01' + '-' + '01',
      maxYear: new Date().toISOString().substr(0, 10),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "CIT Clients",
        subtitle: "View and manage CIT Clients.",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard"
          },
          {
            text: "CIT Clients",
            disabled: true,
            href: "citclients"
          }
        ]
      },
      users_list: [
        { id : 1, firstname : 'Chika', lastname : 'Enemnuo', othernames:'othername' },
      ],
      items: [],
      itemCount: 0,
      search: null,
      title: "",
      location: "",
      vendor: "",
      plate_number: "",
      status: "Available",
      headers: [
        { text: "Name", value: "name" },
        // { text: "Phone", value: "Phone" },
        // { text: "Email", value: "email" },
        { text: "Logo", value: "logo" },
        { text: "User", value: "user" },
        { text: "Contact Person", value: "contact_person" },
        { text: "Status", value: "status" },
        { text: "Branches", value: "branches" },
        { text: "Add Branch", value: "add_branch" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
      avatars: [],
      uploadDialog: false,
      naira: "&#8358;",
      users: [],
      proc_msg: "",
      showPins: false,
      dialog: false,
      modal: false,
      fetching: false,
      loading: true,
      saving: false,
      canUpload: false,
      upload_progress: 0,
      addItemDialog: false,
      editItemDialog: false,
      mrgn: "ma-4",
      image: null,
      error: false,
      errors: {},
      checkedWhatRules: [v => !!v || "This field is required"],
      replacedWhatRules: [v => !!v || "This field is required"],
      vehicleRules: [v => !!v || "Choose a vehicle"],
      requiredRules: [
        v => !!v || "This field is required"
      ],
      amountRules: [v => !!v || "Amount spent is required"],
    };
  },

  mixins: [mounted, computed, methods]
};
</script>

<style>
.v-time-ago__text {
  font-size: 14px !important;
  cursor: pointer;
  color: #0097a7 !important;
}
.pinSize {
  font-size: 80px;
  width: 70% !important;
  text-align: center !important;
}
.myFocus{
  cursor: pointer;
  text-decoration: underline;
}
</style>
