<template>
  <nav>
    <v-app-bar app height="68">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="is-clickable" @click="home()">
        <v-img v-if="themeLogo != 'white'" :src="images.logoRed" alt="iCMS Portal"></v-img>
        <v-img v-else :src="images.logoWhite" alt="iCMS Portal"></v-img>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-list class="accent hidden-xs-only">
        <v-list-item>
          <v-list-item-action>
            <v-icon>today</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <h1>{{ date }}</h1>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-spacer></v-spacer>

      <v-list class="accent hidden-xs-only">
        <v-list-item>
          <v-list-item-action>
            <v-icon>access_time</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <h1>{{ time }}</h1>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->

      <v-spacer></v-spacer>

      <div v-if="authUser">
        <!-- <v-menu open-on-hover offset-y :min-width="350" :max-width="350">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              <v-badge v-on="on" left color="primary" class="hidden-xs-only ml-5">
                <template v-slot:badge>
                  <span>{{ unread_notifications | formatNumber }}</span>
                </template>
                <v-icon>notifications_active</v-icon>
              </v-badge>
            </v-btn>
          </template>

          <v-list two-line>
            <template>
              <v-subheader class="primary--text">
                <span class="bold">{{ notifications.length | formatNumber }}</span> &nbsp;
                Unread Notifications
              </v-subheader>
              <v-list-item
                v-for="(notification, index) in notifications.slice(0,4)"
                :key="index"
                @click="readNotification(notification.id)"
              >
                <v-list-item-avatar>
                  <img src="~@/assets/email50.png" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="notification.title"></v-list-item-title>
                  <v-list-item-subtitle v-text="notification.message"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text>
                    <time-ago
                      refresh
                      :datetime="notification.added_on"
                      long
                      tooltip="top"
                      class="time-ago-small"
                    ></time-ago>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>

              <v-divider v-if="notifications.length + 1 < notifications.length"></v-divider>
            </template>
          </v-list>
        </v-menu> -->

        <!-- <v-menu open-on-hover offset-y :min-width="350" :max-width="350">
           <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              <v-badge left color="primary" class="hidden-xs-only ml-5">
                <template v-slot:badge>
                  <span>{{ unread_messages | formatNumber }}</span>
                </template>
                <v-icon>email</v-icon>
              </v-badge>
            </v-btn>
          </template> 

          <v-list two-line>
            <template>
              <v-subheader class="primary--text">
                <span class="bold">{{ messages.length | formatNumber }}</span> &nbsp;
                Unread Messages
              </v-subheader>
              <v-list-item
                v-for="(message, index) in messages.slice(0,4)"
                :key="index"
                @click="readMessage(message.id)"
              >
                <v-list-item-avatar>
                  <span v-for="(avatar, index) in avatars" :key="index">
                    <span v-if="avatar.user_id == message.sent_by" class="msg-not">
                      <img :src="avatar.avatar" />
                    </span>
                  </span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <span v-for="(user, index) in users" :key="index">
                      <span
                        v-if="user.id === message.sent_by"
                      >{{ user.surname }} {{ user.firstname }}</span>
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text--primary" v-text="message.title"></v-list-item-subtitle>
                  <v-list-item-subtitle v-text="message.message"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text>
                    <time-ago
                      refresh
                      :datetime="message.sent_on"
                      long
                      tooltip="top"
                      class="time-ago-small"
                    ></time-ago>
                  </v-list-item-action-text>
                  <v-icon v-if="message.priority === 'high'" color="primary">star_border</v-icon>
                  <v-icon v-else-if="message.priority === 'medium'" color="cyan">star_border</v-icon>
                  <v-icon v-else color="grey">star</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-divider v-if="notifications.length + 1 < notifications.length"></v-divider>
            </template>
          </v-list>
        </v-menu> -->

        <v-menu open-on-hover offset-y class="ml-5">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              <v-icon left>person_pin</v-icon>
              <span class="hidden-xs-only">{{ curUserData.firstname }}</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <router-link :to="`/profile/${curUserData.firstname}${curUserData.id}`">
                <v-icon left>person</v-icon>Profile
              </router-link>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-icon color="primary" left>power_settings_new</v-icon>Sign Out
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-dialog v-model="openNotification" persistent max-width="800">
      <v-card>
        <v-card-title class="headline">
          <span>{{ notification.title }}</span>
          <v-spacer></v-spacer>
          <span>
            <time-ago
              refresh
              :datetime="notification.added_on"
              long
              tooltip="top"
              class="time-ago-small"
            ></time-ago>
          </span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12>
              <div v-if="fetching">
                <v-progress-linear indeterminate height="10" color="loadingcolor"></v-progress-linear>
              </div>
              <div v-else class="mb-4">
                <div v-html="notification.message"></div>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="openNotification = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="openMessage" persistent max-width="800">
      <v-card>
        <v-card-title class="headline">
          <span v-for="(avatar, index) in avatars" :key="index" class>
            <v-avatar size="50">
              <span v-if="avatar.user_id === user_id" class="msg-not">
                <img :src="avatar.avatar" alt />
              </span>
            </v-avatar>
          </span>
          <span>{{ message.title }}</span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12>
              <div v-if="fetching">
                <v-progress-linear indeterminate height="10" color="loadingcolor"></v-progress-linear>
              </div>
              <div v-else class="mb-4">
                <div v-html="message.message"></div>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="openMessage = false">Close</v-btn>
          <v-spacer></v-spacer>
          <span v-if="message.priority === 'high'">
            <v-chip pill class="white--text">
              <v-avatar left color="primary">H</v-avatar>
              <span class="primary--text text-uppercase">{{ message.priority }} Priority</span>
            </v-chip>
          </span>
          <span v-else-if="message.priority === 'medium'">
            <v-chip pill class="white--text">
              <v-avatar left color="teal">M</v-avatar>
              <span class="teal--text text-uppercase">{{ message.priority }} Priority</span>
            </v-chip>
          </span>
          <span v-else>
            <v-chip pill class="white--text">
              <v-avatar left color="blue-grey">L</v-avatar>
              <span class="blue-grey--text text-uppercase">{{ message.priority }} Priority</span>
            </v-chip>
          </span>
          <v-spacer></v-spacer>
          <span>
            <time-ago refresh :datetime="message.sent_on" long tooltip="top" class="time-ago-small"></time-ago>
          </span>
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="openMessage = false" :to="`/message`">
            <v-icon left>reply</v-icon>Reply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-navigation-drawer v-model="drawer" app class="elevation-3">
      <div v-if="authUser">
        <v-layout column align-center class="elevation-2">
          <v-flex class="mt-4">
            <span v-if="loadingProfile">
              <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
            </span>
            <span v-else>
              <v-avatar size="100">
                <img v-if="curUserAvatar" :src="curUserAvatar" alt />
                <img v-else :src="images.avatar" alt />
              </v-avatar>
            </span>
            <p
              class="sunheading mt-1 secondary--text is-clickable"
              @click="loadProfile(curUserData.firstname, curUserData.id)"
            >
              <v-icon left>link</v-icon>
              {{ curUserData.firstname + " " + curUserData.surname }}
            </p>
          </v-flex>
        </v-layout>
      </div>

      <v-list v-if="authUser">
        <div v-for="CITLink in CITLinks" :key="CITLink.text">
          <v-list-item v-if="!CITLink.subLinks" router :to="CITLink.route">
            <v-list-item-title>{{ CITLink.text }}</v-list-item-title>
          </v-list-item>

          <v-list-group v-else :prepend-icon="CITLink.icon">
            <template v-slot:activator>
              <v-list-item-title>{{ CITLink.text }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="(sublink, i) in CITLink.subLinks"
              :key="i"
              link
              :to="sublink.route"
            >
              <v-list-item-title v-text="sublink.text"></v-list-item-title>
              <v-list-item-icon>
                <v-icon v-text="sublink.icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </div>

        <div v-for="BudgetLink in BudgetLinks" :key="BudgetLink.text">
          <v-list-item v-if="!BudgetLink.subLinks" router :to="BudgetLink.route">
            <v-list-item-title>{{ BudgetLink.text }}</v-list-item-title>
          </v-list-item>

          <v-list-group v-else :prepend-icon="BudgetLink.icon">
            <template v-slot:activator>
              <v-list-item-title>{{ BudgetLink.text }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="(sublink, i) in BudgetLink.subLinks"
              :key="i"
              link
              :to="sublink.route"
            >
              <v-list-item-title v-text="sublink.text"></v-list-item-title>
              <v-list-item-icon>
                <v-icon v-text="sublink.icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </div>

        <div v-for="MaintenanceLink in MaintenanceLinks" :key="MaintenanceLink.text">
          <v-list-item v-if="!MaintenanceLink.subLinks" router :to="MaintenanceLink.route">
            <v-list-item-title>{{ MaintenanceLink.text }}</v-list-item-title>
          </v-list-item>

          <v-list-group v-else :prepend-icon="MaintenanceLink.icon">
            <template v-slot:activator>
              <v-list-item-title>{{ MaintenanceLink.text }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="(sublink, i) in MaintenanceLink.subLinks"
              :key="i"
              link
              :to="sublink.route"
            >
              <v-list-item-title v-text="sublink.text"></v-list-item-title>
              <v-list-item-icon>
                <v-icon v-text="sublink.icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </div>

        <div v-for="genLink in genLinks" :key="genLink.text">
          <v-list-item v-if="!genLink.subLinks" router :to="genLink.route">
            <v-list-item-icon>
              <v-icon>{{ genLink.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ genLink.text }}</v-list-item-title>
          </v-list-item>

          <v-list-group v-else :prepend-icon="genLink.icon">
            <template v-slot:activator>
              <v-list-item-title>{{ genLink.text }}</v-list-item-title>
            </template>
            <v-list-item v-for="(sublink, i) in genLink.subLinks" :key="i" link :to="sublink.route">
              <v-list-item-title v-text="sublink.text"></v-list-item-title>
              <v-list-item-icon>
                <v-icon v-text="sublink.icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </div>
        
        

        

        

        

        

        
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import navmethods from "./navmethods";
import navmounted from "./navmounted";

export default {
  data() {
    return {
      drawer: true,
      images: {
        logoRed: require("@/assets/trmsLogo.png"),
        logoWhite: require("@/assets/tmsLogoWhite.png"),
        avatar: require("@/assets/avatar.png")
      },
      themeLogo: "white",
      user_id: this.$root.curuser.id,
      cur_shift: this.$root.curlocation.cur_shift,
      user_signed_in_location: this.$root.curlocation.cur_location_id,
      clock: "",
      time: "",
      date: "",
      week: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      avatars: [],
      users: [],
      notifications: [],
      unread_notifications: 0,
      notification: [],
      openNotification: false,
      fetchNotitfications: "",
      messages: [],
      unread_messages: 0,
      message: [],
      loadingProfile: true,
      openMessage: false,
      fetchMessages: "",
      fetching: false,
      guestlinks: [
        {
          icon: "exit_to_app",
          text: "Sign In",
          route: "/login"
        },
        {
          icon: "group_add",
          text: "Sign Up",
          route: "/signup"
        }
      ],
      
      genLinks: [
        {
          route: "/dashboard",
          icon: "home",
          text: "Home"
        },
        // {
        //   route: "/message",
        //   icon: "message",
        //   text: "Message"
        // },
        {
          route: "/vendors",
          icon: "people",
          text: "Vendors"
        },
        {
          route: "/locations",
          icon: "room",
          text: "Locations"
        },
        {
          route: "/drivers",
          icon: "directions_walk",
          text: "Drivers"
        },
        {
          route: "/users",
          icon: "person",
          text: "Users"
        },
        
        {
          route: "/trucks",
          icon: "local_shipping",
          text: "Trucks"
        },
        // {
        //   route: "/lastJob",
        //   icon: "commute",
        //   text: "Last Job"
        // },
        // {
        //   route: "/repairs",
        //   icon: "extension",
        //   text: "Repairs"
        // },
        // {
        //   route: "/maintenance",
        //   icon: "build",
        //   text: "Maintenance"
        // },
        // {
        //   route: "/damages",
        //   icon: "departure_board",
        //   text: "Damages"
        // },
        {
          route: "/joborders",
          icon: "assignment",
          text: "Job Orders"
        },
        // {
        //   route: "/budget",
        //   icon: "account_balance_wallet",
        //   text: "Repair Budget"
        // },
        // {
        //   route: "/fueling",
        //   icon: "account_balance_wallet",
        //   text: "Fuel Budget"
        // },
        
        
        // {
        //   route: "/amountspent",
        //   icon: "done_outline",
        //   text: "Amount Spent"
        // },
        
      ],
      adminLinks: [
        {
          icon: "settings",
          text: "Settings",
          subLinks: [
            {
              icon: "security",
              text: "Roles and Permissions",
              route: "/roles-permissions"
            },
            {
              icon: "people",
              text: "User Management",
              route: "/user-management"
            },
            {
              icon: "emoji_transportation",
              text: "Expense Types",
              route: "/expense-types"
            },
            {
              icon: "assignment_turned_in",
              text: "Retirement Types",
              route: "/retirement-types"
            },
            {
              icon: "store_mall_directory",
              text: "Departments",
              route: "/departments"
            }
          ]
        }
      ],
      CITLinks: [
        {
          text: "CIT",
          subLinks: [
            {
              route: "/citclients",
              icon: "person",
              text: "Clients"
            },
            {
              icon: "nature_people",
              text: "Movements",
              route: "/citmovements"
            }
          ]
        }
      ],
      BudgetLinks: [
        {
          text: "Budgets",
          subLinks: [
            {
              route: "/budget",
              icon: "account_balance_wallet",
              text: "Repairs"
            },
            {
              icon: "account_balance_wallet",
              text: "Fueling",
              route: "/fueling"
            }
          ]
        }
      ],

      MaintenanceLinks: [
        {
          text: "General",
          subLinks: [
            {
              route: "/maintenance",
              icon: "build",
              text: "Maintenance"
            },
            {
              icon: "extension",
              text: "Repairs",
              route: "/repairs"
            },
            {
              icon: "commute",
              text: "Last Job",
              route: "/lastJob"
            },
            {
              icon: "departure_board",
              text: "Damages",
              route: "/damages"
            },
          ]
        }
      ],
      
      
      
      
      
      
      
    };
  },

  computed: {
    authUser() {
      return this.$root.authenticated;
    },
    curUserData() {
      return this.$root.curuser;
    },
    curUserAvatar() {
      return this.$root.curuseravatar;
    },
    curUserAvatarBig() {
      return this.$root.curuseravatarbig;
    },
    admin() {
      return this.$root.curuserroles[0] === "Super Administrator";
    },
    ermc() {
      return (this.$root.curuserroles[0] === "ERMC Executive" || this.$root.curuserroles[0] === "ERMC Officer");
    },
    cs() {
      return (this.$root.curuserroles[0] === "Corporate Services Executive" || this.$root.curuserroles[0] === "Corporate Services Officer");
    },
    finance() {
      return (this.$root.curuserroles[0] === "Finance Executive" || this.$root.curuserroles[0] === "Finance Officer");
    },
    manager() {
      return (this.$root.curuserroles[0] === "Manager");
    }
  },

  beforeDestroy() {
    clearInterval(this.clock);
    clearInterval(this.fetchNotitfications);
    clearInterval(this.fetchMessages);
  },

  mixins: [navmethods, navmounted]
};
</script>
