import { apiUrl,isEmpty } from "@/config";
import Axios from "axios";

export default {
    methods: {
        removeBranchItemFromAPI(item){
            Axios.delete(
                `${apiUrl}/citclientbranches/${item.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Deleted Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Delete Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
        },
        removeBranchItem(client,branch){
            this.items.forEach((element,index) => {
                if(element == client){
                    this.items[index].citclientbranches.forEach((branchh,position)=>{
                    if(branch == branchh){
                        //alert(this.items[index].citclientbranches[position].name)
                        this.removeBranchItemFromAPI(branch)
                        this.items[index].citclientbranches.splice(position,1)
                    }
                }) 
                }
            });
            this.confirmDeleteBranchDialog = false
        },
        proceedToRemoveBranchItem(branch){
            //note that item_in_view hasn't changed
            this.client_branch_in_view = branch
            this.confirmDeleteBranchDialog = true
        },
        viewClient(item){
            this.item_in_view = item
            this.viewClientDialog = true
        },
        updateBranchItemOnAPI(item){
            Axios.put(
                `${apiUrl}/citclientbranches/${item.id}`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Updated Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Update Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
      
        },
        doEditBranchItem(client,branch){
            this.$refs.editBranchItemForm.validate()
            if(this.validateBranchForm()== false){
                return
            }
            this.items.forEach((item,index)=>{
                if(item == client){
                    this.items[index].citclientbranches.forEach((branchh,indexx)=>{
                        if(branch == branchh){
                            this.items[index].citclientbranches[indexx].name = this.branch_name
                            this.items[index].citclientbranches[indexx].address = this.branch_address
                            this.items[index].citclientbranches[indexx].status = this.branch_status.name
                            this.updateBranchItemOnAPI(this.items[index].citclientbranches[indexx])
                        }    
                    })
                }
            })
        this.editBranchesDialog = false
        },
        editBranchItem(item){
            this.client_branch_in_view = item
            this.viewBranchesDialog = false
            this.branch_name = item.name
            this.branch_address = item.address
            this.editBranchesDialog = true
            this.branch_status = item.status
        },
        addBranchItemToAPI(item,index){
            Axios.post(
                `${apiUrl}/citclientbranches`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                   let data = response.data.data
                   //update the ID of the item
                    if(data.address == item.address && data.name == item.name && data.client_id == item.client_id){
                        this.items[index].citclientbranches.forEach((storedData,indexx)=>{
                            if(storedData.name == item.name && storedData.client_id == item.client_id && storedData.address == item.address){
                                this.items[index].citclientbranches[indexx].id = data.id
                            }             
                        })
                    }
                   this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Added Successfully!",
                    text: response.data.message
                });
                })
                .catch(({ response }) => {
                    //since the id on the datatable is defaulted to ZERO
                    item.id = 0
                    this.items[index].citclientbranches.forEach((element,indexx) => {
                        if(element.name == item.name && element.client_id == item.client_id && element.id == item.id && item.address == element.address){
                            this.items[index].citclientbranches.splice(indexx,1) 
                        }
                    });

                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: "An Error Occured",
                        text: response.data.message
                    });
                    console.log(response.data)
                });
        },
        proceedToViewBranches(item){
            this.item_in_view = item
            this.viewBranchesDialog = true
        },
        validateBranchForm(){
            if(this.branch_name == ''){
                return false
            }
            if(this.branch_address == ''){
                return false
            }
            if(this.branch_status == ''){
                return false
            }
            return true
        },
        addBranchItem(client){
            this.$refs.addBranchItemForm.validate()
            if(this.validateBranchForm()== false){
                return
            }
            this.items.forEach((item,index)=>{
                if(item == client){
                    let row = {
                        id: 0,
                        citclient_id: client.id,
                        name: this.branch_name,
                        address: this.branch_address,
                        status: this.branch_status.name
                    }
                    this.items[index].citclientbranches.push(row)
                    this.addBranchItemToAPI(row,index)
                }
            })
            this.addBranchDialog = false
        },
        proceedToaddBranchItem(item){
            this.item_in_view = item
            this.client_branch_in_view = item.citclientbranches
            this.addBranchDialog = true
        },
        searchItemsByStatus(status_object){
            this.searchData(status_object)    
        },
        searchData(status_object) {
            this.items = []
            this.loading = true 
            this.sortItemByStatusDialog = false
            Axios.get(
                 `${apiUrl}/citclients/status/${status_object.name}`,
                 {
                     headers: {
                         Authorization: `Bearer ${this.$root.auth}`
                     }
                 }
             )
             .then(response => {
                let data = response.data.data.reverse()
                this.publishTable(data)
                this.loading = false
             })
             .catch(({ response }) => {
                 console.log(response)
                 this.loading = false
             });
        },
        formatBytes(bytes, decimals) {
            if (bytes == 0) return '0 Bytes';
            const k = 1024,
                dm = decimals <= 0 ? 0 : decimals || 2,
                sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },
        pickFile() {
            this.$refs.fileInput.click()
        },
        onFilePicked(e) {
            const minSize = 2097152 // 2MB
            const files = e.target.files
            const file = files[0]
            const imageSize = file.size
            if (file !== undefined) {
                this.image_name = file.name
                this.image_size = this.formatBytes(file.size)
                this.image_type = file.type

                if (this.image_name.lastIndexOf('.') <= 0) {
                    return
                }

                // Validations
                if (imageSize > minSize) {
                    this.canUpload = false
                    this.err = true
                    this.errMsg = "The chosen file is higher than " + this.min_size + " MegaByte"
                } else {
                    const fr = new FileReader()
                    fr.readAsDataURL(file)
                    fr.addEventListener('load', () => {
                        this.image_url = fr.result
                        this.image = file
                    })
                    this.canUpload = true
                    this.err = false
                }

            } else {
                this.image = ""
                this.image_name = ""
                this.image_size = ""
                this.image_type = ""
                this.image_url = ""
            }
        },
        openLogoDialog(item){
            this.item_in_view = item
            this.evidenceDialog = true
        },
        uploadEvidenceImage(item) {
            this.saving = true;
            const fd = new FormData();
            fd.append("logo", this.image);
            fd.append("client_id", item.id);
            Axios.post(
                `${apiUrl}/citclients/upload/logo`,
                 fd,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                    this.saving = false;
                    let data = response.data.data
                    let new_items = []
                    this.items.forEach((element,index) => {
                        new_items[index] = element
                    if(element == item){
                            new_items[index].logo = data.logo    
                            new_items[index] = item
                        }
                    });             
                    this.items = new_items
                    this.evidenceDialog = false

                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'success',
                        title: 'Request successful',
                        text: response.data.message
                    });
                    
                })
                .catch(({ response }) => {
                    this.saving = false;
                    console.log(response.data)
                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: "Upload failed!",
                        text: response.data.message
                    });
                });
        },
        clearFields(){
            this.contact_phone = ''
            this.contact_person = ''
            this.contact_email = ''
            this.name = ''
        },
        removeItemFromAPI(item){
            Axios.delete(
                `${apiUrl}/citclients/${item.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Deleted Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Delete Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
        },
        proceedToRemoveItem(item){
            this.item_in_view = item
            this.confirmDeleteDialog = true
        },
        removeItem(item){
            this.items.forEach((element,index) => {
                if(element == item){
                    this.removeItemFromAPI(item)
                    this.items.splice(index,1) 
                }
            });
            this.confirmDeleteDialog = false
             
        },
        updateItemOnAPI(item){
            Axios.put(
                `${apiUrl}/citclients/${item.id}`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Updated Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Update Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
      
        },
        editItemArray(item){
            this.$refs.editItemForm.validate()
            if(this.validateForm()== false){
                return
            }
            let mainn = 
            {
                id: item.id,
                contact_person: this.contact_person,
                contact_email: this.contact_email,
                contact_phone: this.contact_phone,
                name: this.name,
                added_by: this.user,
                logo:item.logo,     
                status:this.client_status.name,     
                status_object:this.client_status,
                citclientbranches: item.citclientbranches,     
            };
            
            let new_items = []
            this.items.forEach((element,index) => {
                new_items[index] = element
              if(element == item){
                    new_items[index] = mainn
                    let itemm = 
                    {
                        id: item.id,
                        contact_email: mainn.contact_email,
                        contact_person: mainn.contact_person,
                        name: mainn.name,
                        status: mainn.status,
                        added_by: mainn.added_by.id,
                    };
                    this.updateItemOnAPI(itemm)
                }
            });             
            this.items = new_items 
            this.editItemDialog = false
            this.clearFields()
        },
        editItem(item){
        //    alert(JSON.stringify(item))
            this.item_in_view = item
            this.contact_email = this.item_in_view.contact_email
            this.contact_person = this.item_in_view.contact_person
            this.name = this.item_in_view.name
            this.contact_phone = this.item_in_view.contact_phone
            this.client_status = this.item_in_view.status_object
            this.editItemDialog = true 
        },
        validateForm(){
            if(this.name == ''){
                return false
            }
            if(this.contact_email == ''){
                return false
            }
            if(this.contact_phone == ''){
                return false
            }
            if(this.contact_person == ''){
                return false
            }
            if(isEmpty(this.client_status)){
                return false
            }
            return true
        },
        addItem() {
            this.$refs.addItemForm.validate()
            if(this.validateForm()== false){
                return
            }
            let item = 
            {
                id: 0,
                contact_person: this.contact_person,
                contact_email: this.contact_email,
                contact_phone: this.contact_phone,
                name: this.name,
                status: this.client_status.name,
                status_object: this.client_status,
                added_by: this.user,
                logo: {},
                citclientbranches:[]
                    
            };
            this.items.push(item)
            this.items.reverse()
            this.addItemDialog = false
            let new_item = 
            {
                contact_person: item.contact_person,
                contact_phone: item.contact_phone,
                contact_email: item.contact_email,
                name: item.name,
                status: item.status,
                added_by: item.added_by.id,
            };
            this.addItemToAPI(new_item)
            this.clearFields()
        },

        addItemToAPI(item){
            Axios.post(
                `${apiUrl}/citclients`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                   let data = response.data.data
                   //update the ID of the item
                    if(data.contact_phone == item.contact_phone && data.name == item.name && data.contact_person == item.contact_person){
                        this.items.forEach((storedData,index)=>{
                            if(storedData.name == item.name && storedData.added_by.id == item.added_by && storedData.contact_phone == item.contact_phone){
                                this.items[index].id = data.id
                            }             
                        })
                    }
                   this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Added Successfully!",
                    text: response.data.message
                });
                })
                .catch(({ response }) => {
                    //since the id on the datatable is defaulted to ZERO
                    item.id = 0
                    this.items.forEach((element,index) => {
                        if(element.name == item.name && element.added_by.id == item.added_by && element.id == item.id && item.contact_phone == element.contact_phone){
                            this.items.splice(index,1) 
                        }
                    });

                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: "An Error Occured",
                        text: response.data.message
                    });
                    console.log(response.data)
                });
        },
        
        publishTable(data){
            data.forEach(item=>{
                var user = {}
                this.users_list.forEach(userr => {
                    if(userr.id == item.added_by){
                        user = userr            
                    }
                })
                var status_object = {}
                this.status_list.forEach(status=>{
                    if(status.name == item.status){
                        status_object = status
                    }
                })
                item.added_by = user
                item.status_object = status_object 
                this.items.push(item)
            })
            
        },
        refreshTable(){
            this.loading = true
            this.items = []
            this.getAllData()
        },
       async getAllData() {
               await Axios.get(
                    `${apiUrl}/citclients`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$root.auth}`
                        }
                    }
                )
                .then(response => {
                    let data = response.data.data.reverse()
                    this.publishTable(data)
                    this.loading = false
                })
                .catch(({ response }) => {
                    console.log(response)
                    this.loading = false
                });
        },
               
       async getAllUsers() {
          await Axios.get(
                `${apiUrl}/users/all`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.users_list = response.data.data;
            })
            .catch(({ response }) => {
                console.log(response.data)
            });
        },   
    }
}