<template>
<v-card>
                      <v-card-title>
                        Last Job Information
                      </v-card-title>
                      <v-divider class="primary"></v-divider>
                      <v-card-text>
                        
                          <template>
                          <v-simple-table fixed-header >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Destination</th>
                                  <th class="text-left">Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item,i) in LastJobInformation" :key="i">
                                  <td>{{ item.destination }}</td>
                                  <td>
                                    <v-chip color="success" dark>
                                    {{ item.created_at }}
                                    </v-chip>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                      </template>

                      </v-card-text>
                      <v-card-actions></v-card-actions>
                    </v-card>
</template>

<script>
export default {
  name: "LastJobInformation",
  props: ["LastJobInformation"]
};
</script>
