<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              
              <!-- <v-spacer></v-spacer> 
              <v-btn fab color="blue" class="white--text" @click="refreshTable()">
                <v-icon>refresh</v-icon>
              </v-btn> -->
              <v-spacer></v-spacer> 
              <v-btn  fab color="secondary" @click="addItemDialog = true">
                <v-icon>add</v-icon>
                <!-- Add a Budget -->
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td width="10">
                        
                        <v-chip
                          class="ma-2"
                          color="green"
                          text-color="white"
                        >
                          <v-icon left>local_shipping</v-icon>
                          <h2>
                            <span v-if="item.vehicle != {}">
                              <router-link class="white--text myFocus" :to="'truckDetail/'+item.vehicle.slug+'/'+item.vehicle.id">
                             {{item.vehicle.name}}
                              </router-link>
                              </span>  
                            <span v-else>No Vehicle was added</span>  
                          </h2>   
                        </v-chip>
                        
                      </td>
                      <td>{{ item.title }}</td>
                      <td>
                        <span v-html="naira"></span>
                        {{ item.amount | formatNumber }}
                      </td>
                      <td class="myFocus" @click="viewSpentList(item)">
                        Breakdown
                      </td>
                      <!-- <td>
                        <span v-if="item.user != {}">{{ item.user.surname }} {{ item.user.firstname }}</span>
                        <span v-else>User was not Found</span>
                      </td> -->
                      <td> 
                          <v-chip color="success" dark>{{item.created_at}}</v-chip>    
                      </td>
                      <td>
                        <v-btn color="green" fab small dark @click="addAmountSpent(item)">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn color="blue" fab small dark @click="editItem(item)">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td>
                      
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>Total: {{ itemCount | formatNumber}}</v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- add item dialog -->
    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{add_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="addItemDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
             <v-form ref="addItemForm" @submit.prevent="addItem">
               <v-card-text>

                <v-layout row wrap>
                    <v-flex xs12 sm12>
                        <v-text-field
                          name="title"
                          v-model="title"
                          type="text"
                          label="Budget Title"
                          :rules="damagedWhatRules"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="vehicle"
                        v-model="vehicle"
                        :items="vehicles_list"
                        item-text="name"
                        label="Choose a Vehicle"
                        :rules="vehicleRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="amount"
                          v-model="amount"
                          type="tel"
                          v-mask="['###########']"
                          label="Budget Amount"
                          :rules="damagedWhatRules"
                          class="ml-5"
                        ></v-text-field>
                    </v-flex>
                    
                </v-layout>
            </v-card-text>
             </v-form>
            
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large  class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




<!-- edit item dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{edit_item_dialog_title}} ID: {{item_in_view.id}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
             <v-form ref="editItemForm" @submit.prevent="editItemArray(item_in_view)">
               <v-card-text>

                <v-layout row wrap>
                    <v-flex xs12 sm12>
                        <v-text-field
                          name="title"
                          v-model="title"
                          type="text"
                          label="Budget Title"
                          :rules="damagedWhatRules"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="vehicle"
                        v-model="vehicle"
                        :items="vehicles_list"
                        item-text="name"
                        label="Choose a Vehicle"
                        :rules="vehicleRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="amount"
                          v-model="amount"
                          type="tel"
                          v-mask="['###########']"
                          label="Budget Amount"
                          :rules="damagedWhatRules"
                          class="ml-5"
                        ></v-text-field>
                    </v-flex>
                    
                </v-layout>
            </v-card-text>
             </v-form>
            
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="warning" x-large  class="px-6" @click="editItemArray(item_in_view)">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Confirm delete dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="confirmDeleteDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{delete_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
               <h1>Do you want to delete <br><br>"{{item_in_view.title}}"<br><br> from Budgets log?</h1>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn color="warning" x-large  class="px-6 white--text" @click="confirmDeleteDialog = false">
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" x-large  class="px-6 white--text" @click="removeItem(item_in_view)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-if="spent_in_view != {}" v-model="confirmDeleteAmountSpentDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>Delete Amount Spent</span>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteAmountSpentDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
               <h1>Do you want to delete <br><br>"<span v-html="naira"></span>{{spent_in_view.amount}}"<br><br> from amount spent log?</h1>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn color="warning" x-large  class="px-6 white--text" @click="confirmDeleteAmountSpentDialog = false">
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" x-large  class="px-6 white--text" @click="removeAmountSpent(spent_in_view)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>









    <v-dialog v-if="spent_list_in_view != {} && item_in_view != {}" v-model="spentListDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{item_in_view.title}} [Total Spent:<span v-html="naira"></span>{{total_spent}}]</span>
            <v-spacer></v-spacer>
            <v-btn text @click="spentListDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
              
              <template>
                <v-simple-table fixed-header >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Description</th>
                        <th class="text-left">Amount</th>
                        <th class="text-left">Date</th>
                        <th class="text-left">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,i) in spent_list_in_view" :key="i">
                        <td>{{item.description}}</td>
                        <td>
                            <span v-html="naira"></span>
                            {{ item.amount | formatNumber }}
                        </td>
                        <td>
                          <v-chip color="success" dark>
                           {{ item.created_at }}
                          </v-chip>
                        </td>
                        <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveAmountSpent(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>

            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>


<!-- duke -->
    <v-dialog v-if="budget_in_view != {}" v-model="addAmountSpentItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{add_amount_spent_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="addAmountSpentItemDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>  
                
                
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="amount_spent_description"
                          v-model="amount_spent_description"
                          type="text"
                          label="Description"
                          :rules="damagedWhatRules"
                          class="ml-5"
                        ></v-text-field>
                    </v-flex>
                    
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-text-field
                          name="amount_spent"
                          v-model="amount_spent"
                          type="tel"
                          v-mask="['###########']"
                          label="Amount Spent"
                          :rules="damagedWhatRules"
                          class="ml-5"
                        ></v-text-field>
                    </v-flex>
                    
                </v-layout>
                <v-layout row wrap>
                  <h1 class="ml-5 red--text" v-if="isAdding == true">
                      {{calculateBalance(budget_in_view)}}
                  </h1>
                </v-layout>
            </v-card-text>
            </v-form>
            
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large  class="px-6" @click="addSpentItemToArray(budget_in_view)">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";

export default {
  created() {
    document.title = "Budget";
  },

  components: {
    InnerHeader
  },

  data() {
    return {
      isAdding: false,
      total_spent: 0,
      budget_index: -1,
      amount_spent: '',
      amount_spent_description: '',
      budget_in_view: {},
      spent_in_view: {},
      add_amount_spent_item_dialog_title:'Add Amount Spent',
      addAmountSpentItemDialog: false,
      amount_spent_list:[],
      spent_list_in_view:[],
      spentListDialog: false,
      amount: '',
      hasLoaded: false,
      vehicle: {},
      damaged_what: '',
      checked_what: '',
      replaced_what: '',
      user: this.$root.curuser,
      vehicles_list:[],
      delete_item_dialog_title: 'Delete a Budget',
      confirmDeleteDialog: false,
      confirmDeleteAmountSpentDialog: false,
      item_in_view : {},
      add_item_dialog_title : 'Add a Budget',
      edit_item_dialog_title : 'Edit Budget',
      minYear: new Date().getFullYear() + '-' + '01' + '-' + '01',
      maxYear: new Date().toISOString().substr(0, 10),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Budget",
        subtitle: "View and manage vehicle Budgets.",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard"
          },
          {
            text: "Budgets",
            disabled: true,
            href: "budget"
          }
        ]
      },
      users_list: [
        { id : 1, firstname : 'Chika', lastname : 'Enemnuo', othernames:'othername' },
      ],
      items: [],
      itemCount: 0,
      search: null,
      title: "",
      location: "",
      vendor: "",
      plate_number: "",
      status: "Available",
      headers: [
        { text: "Vehicle", value: "vehicle" },
        { text: "Title", value: "title" },  
        { text: "Amount", value: "amount" },
        { text: "Spent", value: "spent" },
   //     { text: "User", value: "user" },
        { text: "Date", value: "date" },
        { text: "Add", value: "add" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
      avatars: [],
      uploadDialog: false,
      naira: "&#8358;",
      users: [],
      proc_msg: "",
      showPins: false,
      dialog: false,
      modal: false,
      fetching: false,
      loading: true,
      saving: false,
      min_size: '2MB',
      checking: false,
      canUpload: false,
      upload_progress: 0,
      addItemDialog: false,
      editItemDialog: false,
      mrgn: "ma-4",
      image: null,
      error: false,
      errors: {},
      checkedWhatRules: [v => !!v || "This field is required"],
      replacedWhatRules: [v => !!v || "This field is required"],
      vehicleRules: [v => !!v || "Choose a vehicle"],
      damagedWhatRules: [
        v => !!v || "This field is required"
      ],
      amountRules: [v => !!v || "Amount spent is required"],
    };
  },

  mixins: [mounted, computed, methods]
};
</script>

<style>
.v-time-ago__text {
  font-size: 14px !important;
  cursor: pointer;
  color: #0097a7 !important;
}
.pinSize {
  font-size: 80px;
  width: 70% !important;
  text-align: center !important;
}
.myFocus{
  cursor: pointer;
  text-decoration: underline;
}
</style>
