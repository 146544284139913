<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <v-btn fab color="blue" class="white--text" @click="refreshTable()">
                <v-icon>refresh</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="green white--text" @click="sortItemByVendorDialog = true">
                <v-icon left>search</v-icon> by Vendor
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="warning" @click="sortItemDialog = true">
                <v-icon left>search</v-icon>by Location
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn v-if="locations_list != {}" fab color="secondary" @click="addItemDialog = true">
                <v-icon>add</v-icon>
                <!-- Add a Truck -->
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td width="10">
                        <v-chip
                          class="ma-2"
                          color="green"
                          text-color="white"
                        >
                          <v-icon left>local_shipping</v-icon>
                          <h2>
                            <router-link class="white--text myFocus" :to="'truckDetail/'+item.slug+'/'+item.id">
                              {{item.name}}
                            </router-link>
                          </h2>
                        </v-chip>
                      </td>
                      <td>{{ item.plate_number }}</td>
                      <td>
                        <span v-if="item.location != null">{{ item.location.name }}</span>
                        <span v-else>No Location was set</span>
                      </td>
                      <td>
                        <span v-if="item.vendor != null" @click="viewVendor(item.vendor)" class="myFocus">{{ item.vendor.name }}</span>
                        <span v-else>No Vendor</span>
                      </td>
                      <td> 
                          <v-chip color="success" dark>{{ item.status }}</v-chip>    
                      </td>
                      <td>
                        <v-btn color="blue" fab small dark @click="editItem(item)">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td>
                      
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>Total: {{ itemCount | formatNumber}}</v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- add item dialog -->
    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{add_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="addItemForm" @submit.prevent="addItem">
              <v-card-text>

              <v-layout row wrap>
                <v-flex xs12 sm9>
                  <v-text-field
                    name="title"
                    v-model="title"
                    label="Name Of Truck"
                    type="text"
                    :rules="titleRules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3>
                  <v-text-field
                    name="plate_number"
                    v-model="plate_number"
                    type="text"
                    label="Plate No."
                    class="ml-5"
                    :rules="plateNumberRules"
                  ></v-text-field>
                </v-flex>
              </v-layout>


                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="location"
                        v-model="location"
                        :items="locations_list"
                        item-text="name"
                        label="Location"
                        :rules="locationRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="vendor"
                        v-model="vendor"
                        :items="vendors_list"
                        item-text="name"
                        label="Vendor"
                        :rules="vendorRules"
                        return-object
                        class="ml-5"
                    >
                    </v-autocomplete>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="vehicle_status"
                        v-model="vehicle_status"
                        :items="status_list"
                        item-text="name"
                        label="Vehicle Status"
                        :rules="locationRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    
                </v-layout>


              
            </v-card-text>
            </v-form>
            
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large  class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




<!-- edit item dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{edit_item_dialog_title}} ID: {{item_in_view.id}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="editItemForm" @submit.prevent="editItemArray(item_in_view)">
              <v-card-text>

              <v-layout row wrap>
                <v-flex xs12 sm9>
                  <v-text-field
                    name="title"
                    v-model="title"
                    label="Name Of Truck"
                    type="text"
                    :rules="titleRules"
                    :value="title"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3>
                  <v-text-field
                    name="plate_number"
                    v-model="plate_number"
                    type="text"
                    label="Plate No."
                    class="ml-5"
                    :value="plate_number"
                    :rules="plateNumberRules"
                  ></v-text-field>
                </v-flex>
              </v-layout>


                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="location"
                        v-model="location"
                        :items="locations_list"
                        item-text="name"
                        label="Location"
                        :rules="locationRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="vendor"
                        v-model="vendor"
                        :items="vendors_list"
                        item-text="name"
                        label="Vendor"
                        :rules="vendorRules"
                        return-object
                        class="ml-5"
                    >
                    </v-autocomplete>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="vehicle_status"
                        v-model="vehicle_status"
                        :items="status_list"
                        item-text="name"
                        label="Vehicle Status"
                        :rules="locationRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    
                </v-layout>

              
            </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="warning" x-large  class="px-6" @click="editItemArray(item_in_view)">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Confirm delete dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="confirmDeleteDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{delete_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
               <h1>Do you want to delete {{item_in_view.name}} ?</h1>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn color="warning" x-large  class="px-6 white--text" @click="confirmDeleteDialog = false">
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" x-large  class="px-6 white--text" @click="removeItem(item_in_view)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- View Vendor dialog -->
    <v-dialog v-if="vendor_in_view != {}" v-model="vendorViewDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{vendor_in_view.name}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="vendorViewDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
                <h4>Company: {{vendor_in_view.company}} </h4>
                <h4>Phone: {{vendor_in_view.phone_number}} </h4>
                <h4>Email: {{vendor_in_view.email}} </h4>
                <h4>Address: {{vendor_in_view.address}} </h4>
                <h4>Contact Person: {{vendor_in_view.contact_person}} </h4>
                <h4>State: {{vendor_in_view.state}} </h4>
                <h4>Country: {{vendor_in_view.country}} </h4>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- search items by location -->
    <v-dialog v-model="sortItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{sort_item_dialog_title}} </span>
            <v-spacer></v-spacer>
            <v-btn text @click="sortItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="search_location"
                        v-model="search_location"
                        :items="locations_list"
                        item-text="name"
                        label="Choose a Location"
                        :rules="locationRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                </v-layout>
    
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="warning" x-large class="px-6" @click="searchItemsByLocation(search_location)">
            <v-icon left>search</v-icon>
            Find
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- search by vendor -->
    <v-dialog v-model="sortItemByVendorDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{sort_item_by_vendor_dialog_title}} </span>
            <v-spacer></v-spacer>
            <v-btn text @click="sortItemByVendorDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="search_vendor"
                        v-model="search_vendor"
                        :items="vendors_list"
                        item-text="name"
                        label="Choose a Vendor"
                        :rules="vendorRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                </v-layout>
    
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="warning" x-large class="px-6" @click="searchItemsByVendor(search_vendor)">
            <v-icon left>search</v-icon>
            Find
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";

export default {
  created() {
    document.title = "Trucks";
  },

  components: {
    InnerHeader
  },

  data() {
    return {
      sort_item_by_vendor_dialog_title: 'Search by Vendor',
      sortItemByVendorDialog: false,
      search_location: '',
      search_vendor: '',
      sort_item_dialog_title: 'Search Trucks by location',
      sortItemDialog: false,
      vendorViewDialog: false,
      vendor_in_view:{},
      vendors_list:[],
      status_list:this.$root.vehicle_states,
      locations_list:[],
      delete_item_dialog_title: 'Delete a Truck',
      confirmDeleteDialog: false,
      item_in_view : {},
      add_item_dialog_title : 'Add a Truck',
      edit_item_dialog_title : 'Edit Truck',
      minYear: new Date().getFullYear() + '-' + '01' + '-' + '01',
      maxYear: new Date().toISOString().substr(0, 10),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Trucks",
        subtitle: "View and manage your Trucks.",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard"
          },
          {
            text: "Trucks",
            disabled: true,
            href: "truck"
          }
        ]
      },
      items: [],
      itemCount: 0,
      search: null,
      title: "",
      location: "",
      vendor: "",
      vehicle_status: "",
      plate_number: "",
      status: "Available",
      headers: [
        { text: "Name", value: "name" },
        { text: "Plate Number", value: "plate_number" },
        { text: "Location", value: "location" },
        { text: "Vendor", value: "vendor" },
        { text: "Status", value: "status" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
      avatars: [],
      uploadDialog: false,
      naira: "&#8358;",
      users: [],
      proc_msg: "",
      showPins: false,
      dialog: false,
      modal: false,
      fetching: false,
      loading: true,
      saving: false,
      min_size: '2MB',
      checking: false,
      canUpload: false,
      upload_progress: 0,
      addItemDialog: false,
      editItemDialog: false,
      mrgn: "ma-4",
      image: null,
      error: false,
      errors: {},
      titleRules: [v => !!v || "Name of Truck is required"],
      plateNumberRules: [v => !!v || "Plate number is required"],
      departmentRules: [v => !!v || "Official department/unit is required"],
      locationRules: [v => !!v || "Location is required"],
      vendorRules: [v => !!v || "A Vendor is required"],
      transactionDateRules: [
        v => !!v || "Date at which expense occured is required"
      ],
      amountRules: [v => !!v || "Amount spent is required"],
    };
  },

  mixins: [mounted, computed, methods]
};
</script>

<style>
.v-time-ago__text {
  font-size: 14px !important;
  cursor: pointer;
  color: #0097a7 !important;
}
.pinSize {
  font-size: 80px;
  width: 70% !important;
  text-align: center !important;
}
.myFocus{
  cursor: pointer;
  text-decoration: underline;
}
</style>
