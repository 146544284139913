<template>
  <div>
    <v-card>
                      <v-card-title>
                        Damages Information
                      </v-card-title>
                      <v-divider class="primary"></v-divider>
                      <v-card-text>
                        
                          <template>
                          <v-simple-table fixed-header >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Description</th>
                                  <th class="text-left">Evidence</th>
                                  <th class="text-left">Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item,i) in DamagesInformation" :key="i">
                                  <td>{{ item.description }}</td>
                                  <td @click="openEvidenceDialog(item)" class="myFocus">
                                    <span v-if="item.evidence != '' && item.evidence != null">
                                      <v-img
                                          :src="item.evidence"
                                          class="is-clickable"
                                          alt="Evidence Img"
                                          height="100%"
                                          width="40px"
                                        ></v-img>
                                      </span>
                                      <span v-else>
                                        add Evidence
                                      </span>
                                  </td>
                                  <td>
                                    <v-chip color="success" dark>
                                    {{ item.created_at }}
                                    </v-chip>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                      </template>

                      </v-card-text>
                      <v-card-actions></v-card-actions>
                    </v-card>
                    <!-- evidence dialog -->
    <v-dialog v-if="item_in_view != {} && evidenceDialog" v-model="evidenceDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>Upload Evidence</span>
            <v-spacer></v-spacer>
            <v-btn text @click="evidenceDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-flex xs12>
                  <v-card flat>
                    <v-card-text>
                      <h3>
                        Ensure the image file is
                        <span class="bold">JPG</span> or
                        <span class="bold">PNG</span> and the size should not be more than
                        <span
                          class="bold"
                        >{{ min_size }} MegaByte</span>
                      </h3>
                      <v-layout row wrap>
                        <v-flex xs12 sm3></v-flex>
                        <v-flex xs12 sm6>
                          <input
                            type="file"
                            ref="fileInput"
                            :rules="uploadRules"
                            class="no-display"
                             accept="image/jpeg"
                            @change="onFilePicked"
                          />
                          <v-card class="ma-4" shaped max-width="400">
                            <v-img
                              v-if="image_url"
                              class="white--text align-end"
                              height="400px"
                              :src="image_url"
                            ></v-img>
                            <v-img
                              v-else-if="item_in_view.evidence != null"
                              :src="item_in_view.evidence"
                              @click="pickFile"
                              class="is-clickable"
                            ></v-img>
                            <v-img
                              v-else
                              :src="images.avatar"
                              @click="pickFile"
                              class="is-clickable"
                            ></v-img>
                          </v-card>
                          <v-layout row wrap>
                            <v-flex xs12>
                              <h3>
                                Name:
                                <span class="bold">{{ image_name }}</span> &nbsp; Format:
                                <span class="bold">{{ image_type }}</span> &nbsp; Size:
                                <span class="bold">{{ image_size }}</span>
                              </h3>
                            </v-flex>
                          </v-layout>
                          <v-alert v-if="err" :value="true" type="error">{{ errMsg }}</v-alert>
                          <span class="ma-5">
                            <v-btn
                              x-large
                              outlined
                              color="primary"
                              @click="pickFile"
                            >Choose Image File To Upload</v-btn>
                          </span>
                          <v-layout row wrap v-if="saving" class="mt-5">
                            <v-flex xs12>
                              <v-progress-linear color="primary lighten-2" buffer-value="0" stream></v-progress-linear>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm3></v-flex>
                      </v-layout>
                    </v-card-text>
                    <v-card-actions v-if="canUpload">
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="canUpload"
                        :disabled="saving"
                        color="primary"
                        @click="uploadEvidenceImage(item_in_view)"
                      >
                        {{ saving ? 'Updating Evidence...' : 'Upload &amp; Save' }}
                        <v-icon right v-if="!saving">cloud_upload</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>     
        </v-card-text>
      </v-card>
    </v-dialog>

                    

  </div>
</template>

<script>
import methods from "./methods";
export default {
  name: "DamagesInformation",
  props: ["DamagesInformation"],
  data(){
    return {
      item_in_view : {},
      evidenceDialog : false,
      images: {
        avatar: require("@/assets/avatar.png")
      },
      err: false,
      errMsg: "",
      min_size: 0,
      image_name: "",
      image_size: "",
      image_type: "",
      image_url: "",
      proc_msg: "",
      showPins: false,
      dialog: false,
      modal: false,
      fetching: false,
      loading: true,
      saving: false,
      canUpload: false,
      upload_progress: 0,
   
      uploadRules: [
        value =>
          !value ||
          value.size < 2000000 ||
          "Evidence size should be less than 2 MB!"
      ],
    }
  },
  mixins: [
    //mounted, computed,
     methods
     ]
};
</script>
