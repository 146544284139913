<template>
<v-card>
                      <v-card-title>
                        Repairs Information
                      </v-card-title>
                      <v-divider class="primary"></v-divider>
                      <v-card-text>
                        
                          <template>
                          <v-simple-table fixed-header >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Description</th>
                                  <th class="text-left">Spare Parts</th>
                                  <th class="text-left">QTY</th>
                                  <th class="text-left">Status</th>
                                  <th class="text-left">Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item,i) in RepairsInformation" :key="i">
                                  <td>{{ item.description }}</td>
                                  <td>{{ item.spare_parts }}</td>
                                  <td>{{ item.spare_part_qty }}</td>
                                  <td>{{ item.status }}</td>
                                  <td>
                                    <v-chip color="success" dark>
                                    {{ item.created_at }}
                                    </v-chip>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                      </template>

                      </v-card-text>
                      <v-card-actions></v-card-actions>
                    </v-card>
</template>

<script>
export default {
  name: "RepairsInformation",
  props: ["RepairsInformation"]
};
</script>
