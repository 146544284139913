import { apiUrl } from "@/config";
import Axios from "axios";

export default {
    methods: {
        viewSpentList(item){
            this.item_in_view = item
            this.spentListDialog = true
            this.spent_list_in_view = item.tmsamountspents
            this.total_spent = 0
            this.spent_list_in_view.forEach(spent =>{
                this.total_spent += parseInt(spent.amount) 
            })
        },
        getTruck() {
                Axios.get(
                    `${apiUrl}/tmsvehicles/${this.truck_id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$root.auth}`
                        }
                    }
                )
                .then(response => {
                    let data = response.data.data
                    this.truck = data
                    this.fuel_budget_information_data.truck = data
                    this.getVendor(data.tmsvendor_id)
                    this.getAllRepairsBudgets(data)
                    this.getAllFuelingBudgets(data)
                    this.getAllRepairs(data)
                    this.getAllMaintenances(data)
                    this.getAllLastJobs(data)
                    this.getAllDamages(data)
                    this.loading = false
                })
                .catch(({ response }) => {
                    this.loading = false
                    console.log(response)
                });
        },
        getVendor(vendor_id) {
            Axios.get(
                `${apiUrl}/tmsvendors/${vendor_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                let data = response.data.data
                this.truck.tmsvendor = data[0]
                this.loadingVendor = false
            })
            .catch(({ response }) => {
                console.log(response)
            });
    },
    prepRepairsBudget(truck,data){
        let obj = []
        data.forEach(element => {
            if(element.tmsvehicle_id == truck.id){
                obj.push(element)
                this.total_budgeted += parseInt(element.amount)
                element.tmsamountspents.forEach(spent_data=>{
                    this.amount_summary_data.total_repairs_spent += spent_data.amount           
                })
            }
        });
        this.budget_data = obj
        this.loadingBudget = false
        this.amount_summary_data.total_repairs_budget = this.total_budgeted
    },

    prepFuelingsBudget(truck,data){
        data.forEach(element => {
            if(element.tmsvehicle_id == truck.id){
                this.fuel_budget_information_data.total_budgeted += element.amount
                this.amount_summary_data.total_fuelling_budget += parseInt(element.amount)
                element.tmsamountspentfuelings.forEach(spent_data=>{
                    this.amount_summary_data.total_fuelling_spent += spent_data.amount
                })
            }
        });
        this.fuel_budget_information_data.fuel_budget_data = data
        this.fuel_budget_information_data.loading = false
    },

    getAllRepairsBudgets(truck) {
        Axios.get(
            `${apiUrl}/tmsbudgets`,
            {
                headers: {
                    Authorization: `Bearer ${this.$root.auth}`
                }
            }
        )
        .then(response => {
            let data = response.data.data
            this.prepRepairsBudget(truck,data)     
        })
        .catch(({ response }) => {
            console.log(response)
        });
    },
    
    getAllFuelingBudgets(truck) {
        Axios.get(
            `${apiUrl}/tmsfuelings`,
            {
                headers: {
                    Authorization: `Bearer ${this.$root.auth}`
                }
            }
        )
        .then(response => {
            let data = response.data.data
            this.prepFuelingsBudget(truck,data)   
        })
        .catch(({ response }) => {
            console.log(response)
        });
    },

    prepRepairs(truck,data){
            let repairs = []
            data.forEach(repair=>{
                if(truck.id == repair.tmsvehicle_id){
                    repairs.push(repair)
                }
            })
            this.repairs_data = repairs
            this.loadingRepairs = false
      },
     getAllRepairs(truck) {
         Axios.get(
             `${apiUrl}/tmsrepairs`,
             {
                 headers: {
                     Authorization: `Bearer ${this.$root.auth}`
                 }
             }
         )
         .then(response => {
             let data = response.data.data.reverse()
             this.prepRepairs(truck,data)
         })
         .catch(({ response }) => {
            this.loadingRepairs = false 
            console.log(response)
         });
    },

    prepMaintenances(truck,data){
        let maintenances = []
        data.forEach(item =>{
            if(item.tmsvehicle_id == truck.id){
                maintenances.push(item)
            }
        })
        this.maintenances_data = maintenances
        this.loadingMaintenance = false
    },
     getAllMaintenances(truck) {
        Axios.get(
             `${apiUrl}/tmsmaintenances`,
             {
                 headers: {
                     Authorization: `Bearer ${this.$root.auth}`
                 }
             }
         )
         .then(response => {
             let data = response.data.data.reverse()
             this.prepMaintenances(truck,data)
         })
         .catch(({ response }) => {
             console.log(response)
             this.loadingMaintenance = false
         });
    },
      prepLastJobs(truck,data){
        let jobs = []  
        data.forEach(item =>{
            if(truck.id == item.tmsvehicle_id){
                jobs.push(item)
            }
        })
        this.last_job_data = jobs
        this.loadingLastJob = false
      },
     getAllLastJobs(truck) {
         Axios.get(
             `${apiUrl}/tmsvehiclelastjobs`,
             {
                 headers: {
                     Authorization: `Bearer ${this.$root.auth}`
                 }
             }
         )
         .then(response => {
             let data = response.data.data.reverse()
             this.prepLastJobs(truck,data)
         })
         .catch(({ response }) => {
             console.log(response)
             this.loading = false
         });
    },
    prepDamages(truck,data){
        let damages = []
        data.forEach(damage=>{
            if(truck.id == damage.tmsvehicle_id){
                damages.push(damage)
            }
        })
        this.damages_data = damages
    },
    getAllDamages(truck) {
         Axios.get(
             `${apiUrl}/tmsdamages`,
             {
                 headers: {
                     Authorization: `Bearer ${this.$root.auth}`
                 }
             }
         )
         .then(response => {
             let data = response.data.data.reverse()
             this.prepDamages(truck,data)
             this.loadingDamages = false
         })
         .catch(({ response }) => {
             console.log(response)
             this.loadingDamages = false
         });
        },
    }
}