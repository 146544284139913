<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap class="mt-4">
        <v-flex xs12>
          <v-card>
            <v-card-title class="primary--text text-uppercase">
              <h3>{{ supportHeader }}</h3>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 sm4>
                  <v-card
                    class="mb-3"
                    v-for="supportContact in supportContacts"
                    :key="supportContact.email"
                  >
                    <v-card-text>
                      <v-layout row wrap>
                        <v-flex xs12 sm4>
                          <v-img :src="supportContact.avatar"></v-img>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <p class="support-header">{{ supportContact.name }}</p>
                          <p class="support-subheader">{{ supportContact.phone }}</p>
                          <p class="support-subheader">
                            <a
                              class="blue-grey--text"
                              :href="'mailto:' + supportContact.email"
                            >{{ supportContact.email }}</a>
                          </p>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>

                <v-flex xs12 sm8>
                  <v-card>
                    <v-card-text>
                      <v-layout row wrap>
                        <v-flex xs12 sm6>
                          <v-text-field
                            name="senderSurname"
                            v-model="senderSurname"
                            label="Surname"
                            type="text"
                            disabled
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6>
                          <v-text-field
                            name="senderFirstname"
                            v-model="senderFirstname"
                            label="First Name"
                            type="text"
                            disabled
                          ></v-text-field>
                        </v-flex>
                      </v-layout>

                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-text-field
                            name="senderEmail"
                            v-model="senderEmail"
                            label="Email Address"
                            type="text"
                            disabled
                          ></v-text-field>
                        </v-flex>
                      </v-layout>

                      <v-layout row wrap>
                        <v-flex xs12>
                          <p>Message</p>
                          <ckeditor
                            :editor="editor"
                            :config="editorConfig"
                            name="message"
                            v-model="message"
                          ></ckeditor>
                        </v-flex>
                      </v-layout>
                      <v-alert v-if="ss" :value="true" type="success">{{ ssMsg }}</v-alert>
                      <v-alert v-if="err" :value="true" type="warning">
                        <span class="black--text">{{ errMsg }}</span>
                      </v-alert>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" :disabled="loading" @click="sendMessage()">
                        Send Message
                        <v-icon right>send</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// Scripts
import mounted from "./mounted";
import methods from "./methods";

export default {
  created() {
    document.title = "Application Console";
  },

  components: {
    InnerHeader
  },

  data() {
    return {
      headerData: {
        title: "Application Console",
        subtitle: "Active and upcoming applications to make your daily tasks easier.",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard"
          }
        ]
      },

      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
      user_id: this.$root.curuser.id,
      user_role: this.$root.curuserroles[0],
      supportHeader: "Contact Support",
      supportContacts: [
        {
          name: "Ibeh Ernest",
          phone: "08020689069",
          email: "e.ibeh@icms.ng",
          avatar: "avatar1.png"
        },
        {
          name: "John Doe",
          phone: "07120689068",
          email: "j.doe@icms.ng",
          avatar: "avatar1.png"
        },
        {
          name: "Jane Shinene",
          phone: "07020689067",
          email: "j.shinene@icms.ng",
          avatar: "avatar1.png"
        }
      ],
      loading: false,
      ss: false,
      err: false,
      ssMsg: "",
      errMsg: "",
      error: false,
      errors: {},
      response: "",
      senderSurname: this.$root.curuser.surname,
      senderFirstname: this.$root.curuser.firstname,
      senderEmail: this.$root.curuser.email,
      fullname: "",
      message: ""
    };
  },

  mixins: [mounted, methods]
};
</script>

<style scoped>
</style>
