<template>
  <v-app class="grey lighten-4">
      
    <!-- <nav-bar /> -->

    <v-content class="mx-4 my-4">
      <router-view></router-view>
    </v-content>

    <footer-bar></footer-bar>

    <notifications :group="group" :position="position" :speed="speed" />
  </v-app>
</template>

<script>
// import NavBar from "@/components/Global/NavBar";
import FooterBar from "@/components/Global/FooterBar";
export default {
  components: {
    // NavBar,
    FooterBar
  },
  data() {
    return {
      // loginDialog: false
      group: "platform",
      position: "bottom right",
      speed: 500
    };
  }
};
</script>
