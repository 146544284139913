<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              
              <v-spacer></v-spacer> 
              <v-btn  fab color="secondary" @click="addItemDialog = true">
                <v-icon>add</v-icon>
                <!-- Add a Maintenance -->
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td width="10">
                        <v-chip
                          color="green"
                          text-color="white"
                        >
                          <v-icon left>local_shipping</v-icon>
                          <h2>
                            <span v-if="item.tmsvehicle != {}">
                                <router-link class="white--text myFocus" :to="'truckDetail/'+item.tmsvehicle.slug+'/'+item.tmsvehicle.id">
                                    {{item.tmsvehicle.name}}
                                </router-link>
                              </span>  
                            <span v-else>No Vehicle was added</span>  
                          </h2>   
                        </v-chip>
                        
                      </td>

                      <td width="10">
                        
                        <span v-if="item.tmsdriver != null">
                        <v-chip
                          class="myFocus"
                          color="green"
                          text-color="white"
                          @click="userProfileDialog(item)"
                        >  
                            <v-avatar size="350">
                                <img :src="item.tmsdriver.avatar_id" alt />
                            </v-avatar>
                            <h2>
                              <span>{{item.tmsdriver.surname}} {{item.tmsdriver.firstname}} {{item.tmsdriver.othernames}}</span>  
                            </h2>

                        </v-chip>
                        </span>
                        <span v-else>No Driver added</span>
                        
                      </td>

                      <td width="10">
                        <span v-if="item.cmo != null">
                        <v-chip
                          class="myFocus"
                          color="green"
                          text-color="white"
                          @click="userProfileDialog(item)"
                        >
                          <v-avatar size="350">
                              <img :src="item.cmo.avatar_id" alt />
                          </v-avatar>
                          <h2>
                            <span>{{item.cmo.surname}} {{item.cmo.firstname}} {{item.cmo.othernames}}</span>  
                          </h2>   
                        </v-chip>
                        </span>
                        <span v-else>No CMO was added</span>
                      </td>

                      <td>
                        <span v-if="item.client != null">{{item.client.name}}</span>
                        <span v-else>No Client was Added</span>
                      </td>
                      <td>{{item.citclientbranch.name}}</td>
                      <td class="myFocus" @click="viewMovementDetail(item,i)">Detail</td>
                      <td> 
                          <v-chip :color="(item.status =='Initiated')?'success':'red'" dark>{{item.status}}</v-chip>    
                      </td>
                      
                      <td>
                        <v-btn color="blue" fab small dark @click="editItem(item)">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td>
                      
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>Total: {{ itemCount | formatNumber}}</v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- add item dialog -->
    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
          <v-form ref="addItemForm" @submit.prevent="addItem">
         
            <v-card-text>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="vehicle"
                        v-model="vehicle"
                        :items="vehicles_list"
                        item-text="name"
                        label="Choose a Vehicle"
                        :rules="vehicleRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    
                    <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="dispatched_on"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="dispatched_on"
                                  label="Dispatched On"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  class="ml-5"
                                ></v-text-field>
                              </template>
                              <v-date-picker 
                                color="primary" 
                                :min="minYear" 
                                :max="maxYear" 
                                v-model="dispatched_on" 
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialog.save(dispatched_on)"
                                >OK</v-btn>
                              </v-date-picker>
                            </v-dialog>
                </v-layout>

                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="driver"
                        v-model="driver"
                        :items="users_list"
                        item-text="name"
                        label="Choose a Driver"
                        :rules="requiredRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="cmo"
                        v-model="cmo"
                        :items="users_list"
                        item-text="name"
                        label="Choose a CMO"
                        class="ml-5"
                        :rules="requiredRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    
                </v-layout>
                <v-layout row wrap>
                    
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="client"
                        v-model="client"
                        :items="clients_list"
                        item-text="name"
                        label="Choose a Client"
                        :rules="requiredRules"
                        @change="setBranches()"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="branch"
                        v-model="branch"
                        :items="branches_list"
                        item-text="name"
                        label="Choose a Branch"
                        class="ml-5"
                        :rules="requiredRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="number_of_escort"
                      v-model="number_of_escort"
                      label="Number of escort"
                      type="text"
                      v-mask="['######']"
                      :rules="requiredRules"
                      @keyup.native="checkEscorts()"
                    ></v-text-field>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs12 sm6>
                      <h3 v-if="!correct_escort_number" class="red--text mt-7">{{escort_error}}</h3>
                      <span v-else> <v-icon class="blue--text mt-7 ml-7">done_all</v-icon></span>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <template>
                    <v-flex xs12 class="to-center ma-3 warning--text">
                      <h3>Names of Escort: (Hint: Type a name, then add a comma, then hit Enter).</h3>
                    </v-flex>
                    <div>
                      <vue-tags-input
                        class="ma-3"
                        xs12
                        v-model="tag"
                        :tags="tags"
                        @tags-changed="newTags => tags = newTags"
                        @keyup.native="checkEscorts()"
                      />
                    </div>
                  </template>
                  </v-flex>
                </v-layout>


                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="estimated_return_time"
                      v-model="estimated_return_time"
                      label="Estimated Return Time"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="estimated_distance"
                      v-model="estimated_distance"
                      label="Estimated Distance"
                      type="text"
                      :rules="requiredRules"
                      class="ml-5"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                
            </v-card-text>
          </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large  class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




<!-- edit item dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{edit_item_dialog_title}} ID: {{item_in_view.id}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-form ref="editItemForm" @submit.prevent="editItemArray(item_in_view)">
            <v-card-text>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="vehicle"
                        v-model="vehicle"
                        :items="vehicles_list"
                        item-text="name"
                        label="Choose a Vehicle"
                        :rules="vehicleRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    
                    <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="dispatched_on"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="dispatched_on"
                                  label="Dispatched On"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  class="ml-5"
                                ></v-text-field>
                              </template>
                              <v-date-picker 
                                color="primary" 
                                :min="minYear" 
                                :max="maxYear" 
                                v-model="dispatched_on" 
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialog.save(dispatched_on)"
                                >OK</v-btn>
                              </v-date-picker>
                            </v-dialog>
                </v-layout>

                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="driver"
                        v-model="driver"
                        :items="users_list"
                        item-text="name"
                        label="Choose a Driver"
                        :rules="requiredRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="cmo"
                        v-model="cmo"
                        :items="users_list"
                        item-text="name"
                        label="Choose a CMO"
                        class="ml-5"
                        :rules="requiredRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    
                </v-layout>
                <v-layout row wrap>
                    
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="client"
                        v-model="client"
                        :items="clients_list"
                        item-text="name"
                        label="Choose a Client"
                        :rules="requiredRules"
                        @change="setBranches()"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="branch"
                        v-model="branch"
                        :items="branches_list"
                        item-text="name"
                        label="Choose a Branch"
                        class="ml-5"
                        :rules="requiredRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="number_of_escort"
                      v-model="number_of_escort"
                      label="Number of escort"
                      type="text"
                      v-mask="['######']"
                      :rules="requiredRules"
                      @keyup.native="checkEscorts()"
                    ></v-text-field>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs12 sm6>
                      <h3 v-if="!correct_escort_number" class="red--text mt-7">{{escort_error}}</h3>
                      <span v-else> <v-icon class="blue--text mt-7 ml-7">done_all</v-icon></span>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <template>
                    <v-flex xs12 class="to-center ma-3 warning--text">
                      <h3>Names of Escort: (Hint: Type a name, then add a comma, then hit Enter).</h3>
                    </v-flex>
                    <div>
                      <vue-tags-input
                        class="ma-3"
                        xs12
                        v-model="tag"
                        :tags="tags"
                        @tags-changed="newTags => tags = newTags"
                        @keyup.native="checkEscorts()"
                      />
                    </div>
                  </template>
                  </v-flex>
                </v-layout>


                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="estimated_return_time"
                      v-model="estimated_return_time"
                      label="Estimated Return Time"
                      type="text"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs12 sm6>
                    <v-text-field
                      name="estimated_distance"
                      v-model="estimated_distance"
                      label="Estimated Distance"
                      type="text"
                      :rules="requiredRules"
                      class="ml-5"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                
            </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="warning" x-large  class="px-6" @click="editItemArray(item_in_view)">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Confirm delete dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="confirmDeleteDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{delete_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
               <h1>Do you want to delete <br><br>"{{item_in_view.description}}"<br><br> from damages log?</h1>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn color="warning" x-large  class="px-6 white--text" @click="confirmDeleteDialog = false">
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" x-large  class="px-6 white--text" @click="removeItem(item_in_view)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        
    <Details-dialog :Detail="movementDetail" @updateDetailComponent="updateDetailComponent($event)"></Details-dialog>
    
  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";
import VueTagsInput from '@johmun/vue-tags-input';
import DetailsDialog from "./DetailsDialog/page";


export default {
  created() {
    document.title = "CIT Movements";
  },

  components: {
    InnerHeader,
    VueTagsInput,
    DetailsDialog,
  },

  data() {
    return {
      movementDetail:{
        dialog: false,
        item_in_view: {},
        index: -1,
        vehicles_list:[],
        users_list:[],
        clients_list: [],
      },
      viewMovementDetailDialog: false,
      correct_escort_number: false,
      escort_error:'',
      estimated_distance:'',
      estimated_return_time:'',
      branch: {},
      client: {},
      clients_list: [],
      branches_list: [],
      number_of_escort: '1',
      tag: '',
      tags: [],
      search: null,
      dispatched_on: "",
      modal: false,
      driver:{},
      cmo:{},
      users_list: [],
      vehicle:{},
      vehicles_list:[],
      status_list:this.$root.client_statuses,
      images: {
        avatar: require("@/assets/avatar.png")
      },
      
      statusRules: [v => !!v || "Location is required"],
      
      user: this.$root.curuser,
      delete_item_dialog_title: 'Delete a CIT Movements',
      confirmDeleteDialog: false,
      item_in_view : {},
      item_dialog_title : 'Add a CIT Movements',
      edit_item_dialog_title : 'Edit CIT Movements',
      minYear: new Date().getFullYear() + '-' + '01' + '-' + '01',
      maxYear: new Date().toISOString().substr(0, 10),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "CIT Movements",
        subtitle: "View and manage CIT Movements.",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard"
          },
          {
            text: "CIT Movements",
            disabled: true,
            href: "citmovements"
          }
        ]
      },
      
      items: [],
      itemCount: 0,
      status: "Available",
      headers: [
        { text: "Vehicle", value: "vehicle" },
        { text: "Driver", value: "driver" },
        { text: "CMO", value: "cmo" },
        { text: "Client", value: "client" },
        { text: "Branch", value: "branch" },
        { text: "Detail", value: "detail" },
        { text: "Status", value: "status" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
      avatars: [],
      uploadDialog: false,
      naira: "&#8358;",
      dialog: false,
      loading: true,
      addItemDialog: false,
      editItemDialog: false,
      mrgn: "ma-4",
      image: null,
      error: false,
      errors: {},
      vehicleRules: [v => !!v || "Choose a vehicle"],
      requiredRules: [
        v => !!v || "This field is required"
      ],
      amountRules: [v => !!v || "Amount spent is required"],
    };
  },

  mixins: [mounted, computed, methods]
};
</script>

<style>
.v-time-ago__text {
  font-size: 14px !important;
  cursor: pointer;
  color: #0097a7 !important;
}
.pinSize {
  font-size: 80px;
  width: 70% !important;
  text-align: center !important;
}
.myFocus{
  cursor: pointer;
  text-decoration: underline;
}
</style>
