<template class="cover my-4">
  <div class="login">
    <v-container fluid>
      <v-layout class="leftDiv">
        <v-flex xs12>
          <span>
            <img src="~@/assets/trmsLogo.png" alt="iCMS Portal" />
          </span>
          <v-card class="elevation-12">
            <v-toolbar color="white">
              <v-toolbar-title>
                <h3> <v-icon left color="black">security</v-icon> Authentication</h3>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <h4 class="subheading">Welcome, enter your credentials below to proceed</h4>
              <v-form ref="loginform" @submit.prevent="login">
                <v-text-field
                  prepend-icon="email"
                  name="email"
                  v-model="email"
                  :rules="emailRules"
                  label="Email Address"
                  type="text"
                  autocomplete="off"
                ></v-text-field>
                <v-text-field
                  prepend-icon="lock"
                  name="password"
                  label="Password"
                  v-model="password"
                  :rules="passwordRules"
                  type="password"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <span class="blue-grey--text text--darken-3 text-uppercase ml-3">
                <!-- <router-link to="/resetPassword">Forgot password?</router-link> -->
              </span>
              <v-spacer></v-spacer>
              <v-btn :disabled="loading" color="primary" @click="login()">
                {{ loading ? 'Authenticating...' : 'Sign In' }}
                <v-icon right v-if="!loading">exit_to_app</v-icon>
              </v-btn>
            </v-card-actions>
            <v-divider class="mt-3"></v-divider>
            <div class="ma-3 pa-3">
              <span class="blue-grey--text text--lighten-1">New to this portal? &nbsp;</span>
              <span class="blue-grey--text text--darken-3">
                <router-link to="/signup">CALL: +234 (0) 802 068 9069</router-link>
              </span>
              <span class="blue-grey--text text--lighten-1">&nbsp; for account setup</span>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog v-model="modalOpen" persistent max-width="700">
      <v-card>
        <v-card-title class="headline">Integrated Cash Management Services Limited</v-card-title>

        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 sm6>
              <p>
                Welcome
                <v-icon color="teal">tag_faces</v-icon>, we are happy you could make it to work today.
              </p>
              <p>
                It is mandatory that you select the SHIFT and LOCATION you would be working from before proceeding.
                <span
                  class="primary--text"
                >Failure to do this would lead to difficulty using this application.</span> So we encourage you to do that now. Thank You.
              </p>
              <p
                class="text-uppercase primary--text"
              >At the end of your chosen shift, endeavor to sign out. All activities on the application is closely monitored and tied to user account. Remember one can not always be too careful.</p>
            </v-flex>
            <v-flex xs12 sm6>
              <v-card class="elevation-12">
                <v-card-title>Shift and Location Selector</v-card-title>
                <v-card-text>
                  <v-form ref="newForm" @submit.prevent="setShiftLocation">
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-select
                          v-model="sel_shift"
                          :items="shifts"
                          label="Select Shift"
                          persistent-hint
                          :rules="shiftRules"
                        ></v-select>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-autocomplete
                          v-model="location"
                          :items="locations"
                          item-text="name"
                          label="Select Location"
                          persistent-hint
                          return-object
                          single-line
                          :rules="locationRules"
                        ></v-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="saving"
                    color="primary"
                    @click="setShiftLocation()"
                  >{{ loading ? 'Processing...' : 'Set Shift & Location' }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import methods from "./methods";

export default {
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("auth")) {
      return next({ path: "/dashboard" });
    }
    next();
  },

  created() {
    document.title = "Login";
  },

  data() {
    return {
      drawer: null,
      shiftlocation: [],
      locations: [],
      shifts: ["Morning", "Afternoon", "Evening"],
      modalOpen: false,
      saving: false,
      valid: false,
      loading: false,
      errors: {},
      location: "",
      sel_shift: "",
      response: "",
      email: "",
      password: "",
      emailRules: [
        v => !!v || "Email address name is required",
        v =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Invalid Email address"
      ],
      passwordRules: [
        v => !!v || "Password is required",
        v => v.length >= 8 || "Minimum of 8 characters required"
      ],
      shiftRules: [v => !!v || "Shift is required"],
      locationRules: [v => !!v || "Location is required"]
    };
  },

  mixins: [methods]
};
</script>

<style>
a {
  color: #37474f;
  cursor: pointer;
  text-decoration: none;
}
a:visited {
  color: #37474f;
  cursor: pointer;
  text-decoration: none;
}
a:active {
  color: #37474f;
  cursor: pointer;
  text-decoration: none;
}

.login {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("~@/assets/bg.png") no-repeat;
  box-sizing: border-box;
}

.leftDiv {
  position: absolute;
  top: 0;
  right: 0;
  padding: 110px 30px 50px 30px;
  width: auto;
  min-height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* color: white; */
  box-sizing: border-box;
}
</style>