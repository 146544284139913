import { apiUrl } from "@/config";
import Axios from "axios";

export default {
    methods: {
        // GoTo Home
        home() {
            this.$router.push({ path: "/dashboard" })
        },
        // Load Theme
        loadTheme() {
            if ( this.$root.curuser.pref_theme != 'light' ) {
                this.$vuetify.theme.dark = true
                this.$vuetify.theme.light = false
                this.themeLogo = "white"
            } else {
                this.$vuetify.theme.dark = false
                this.$vuetify.theme.light = true
                this.themeLogo = "red"
            }
        },
        // Load Profile
        loadProfile(name, id) {
            this.$router.push({ path: `/profile/${name}${id}` });
        },
        // Get Notifications
        getNotifications() {
            Axios.get(`${apiUrl}/usernotifications/unread/user/${this.user_id}`, {
                headers: {
                    Authorization: `Bearer ${this.$root.auth}`
                }
            })
                .then(response => {
                    this.loadingProfile = false
                    this.notifications = response.data.data;
                    this.unread_notifications = this.notifications.length;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        // Open Notification Dialog
        readNotification(id) {
            this.openNotification = true
            this.fetching = true
            Axios.get(`${apiUrl}/usernotifications/${id}`, {
                headers: {
                    Authorization: `Bearer ${this.$root.auth}`
                }
            })
                .then(response => {
                    this.getNotifications()
                    this.notification = response.data.data;
                    if (this.notification) {
                        this.fetching = false
                    }
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        // Get Messages
        getUnreadMessages() {
            Axios.get(`${apiUrl}/usermessages/unread/user/${this.user_id}`, {
                headers: {
                    Authorization: `Bearer ${this.$root.auth}`
                }
            })
                .then(response => {
                    this.messages = response.data.data;
                    this.unread_messages = this.messages.length;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        // Open message Dialog
        readMessage(id) {
            this.openMessage = true
            this.fetching = true
            Axios.get(`${apiUrl}/usermessages/${id}`, {
                headers: {
                    Authorization: `Bearer ${this.$root.auth}`
                }
            })
                .then(response => {
                    this.getUnreadMessages()
                    this.message = response.data.data;
                    if (this.message) {
                        this.fetching = false
                    }
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        updateDateTime() {
            var cd = new Date();
            this.time =
                this.zeroPadding(cd.getHours(), 2) +
                ":" +
                this.zeroPadding(cd.getMinutes(), 2) +
                ":" +
                this.zeroPadding(cd.getSeconds(), 2);
            this.date =
                this.zeroPadding(cd.getFullYear(), 4) +
                "-" +
                this.zeroPadding(cd.getMonth() + 1, 2) +
                "-" +
                this.zeroPadding(cd.getDate(), 2) +
                " " +
                this.week[cd.getDay()];
        },
        zeroPadding(num, digit) {
            var zero = "";
            for (var i = 0; i < digit; i++) {
                zero += "0";
            }
            return (zero + num).slice(-digit);
        },
        logout() {
            localStorage.removeItem("authenticated");
            this.$root.authenticated = false;
            localStorage.removeItem("auth");
            this.$root.auth = {};
            localStorage.removeItem("curuser");
            this.$root.curuser = {};
            localStorage.removeItem("curuserroles");
            this.$root.curuserroles = {};
            localStorage.removeItem("curprofile");
            this.$root.curprofile = {};
            localStorage.removeItem("curlocation");
            this.$root.curlocation = {};
            localStorage.removeItem("curuserpermissions");
            this.$root.curuserpermissions = {};
            localStorage.removeItem("curuseravatar");
            this.$root.curuseravatar = {};
            this.$notify({
                group: "platform",
                duration: 4000,
                type: "success",
                title: "Sign Out Successful",
                text:
                    "You have successfully signed out of Platform. Please protect your account credentials."
            });
            // location.reload();
            this.$router.push({ path: "/" });
        }
    }
}