import { apiUrl,isLive } from "@/config";
import Axios from "axios";

export default {
    methods: {
        addUser() {
            if(isLive == false){
                alert('Not Live')
            }else{
                Axios.post(
                    `${URL}/addUser`,
                    {
                        first_name: this.first_name,
                        last_name: this.last_name,
                        email: this.email,
                        other_names: this.other_names,
                        phone: this.phone,
                    },
                    {
                        // headers: {
                        //     Authorization: `Bearer ${this.$root.auth}`
                        // }
                    }
                )
                    .then(response => {
                      alert(JSON.stringify(response))
                        //  this.users = response.data.data;
                    })
                    .catch(({ response }) => {
                        alert(JSON.stringify(response))
                        console.log(response)
                    });
            }
            
        },
        
        
        // Get users
        getUsers() {
            Axios.get(
                `${apiUrl}/users/all`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                    this.users = response.data.data;
                })
                .catch(({ response }) => {
                    console.log(response.data)
                });
        },
        // Get Departments
        getDepartments() {
            Axios.get(
                `${apiUrl}/departments`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                    this.departments = response.data;
                })
                .catch(({ response }) => {
                    console.log(response.data)
                });
        },
        // Get HODs
        getHods() {
            Axios.get(
                `${apiUrl}/departments/hods/all`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                    this.hods = response.data.data;
                })
                .catch(({ response }) => {
                    console.log(response.data)
                });
        },
        // Get ExpenseTypes
        getExpenseTypes() {
            Axios.get(
                `${apiUrl}/expensetypes`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                    this.expenseTypes = response.data;
                })
                .catch(({ response }) => {
                    console.log(response.data)
                });
        },
        // Get Full Name
        getFullName() {
            this.hodName = this.hod.surname + ' ' + this.hod.firstname;
            this.department_hod = this.hod.id;
        },
        // Get user expenses 
        getExpenses() {
            Axios.get(
                `${apiUrl}/expenses/user/${this.user_id}/get-all`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                    this.loading = false
                    this.items = response.data.data;
                    this.itemCount = this.items.length
                    this.avatars = response.data.avatars;
                })
                .catch(({ response }) => {
                    this.loading = false;
                    console.log(response.data)
                });
        },
        // Light Box
        lightBox(title, image) {
            this.lightbox_title = title
            this.lightbox_image = image
            this.lightboxDialog = true
        },
        // Convert Number to kb -> yb
        formatBytes(bytes, decimals) {
            if (bytes == 0) return '0 Bytes';
            const k = 1024,
                dm = decimals <= 0 ? 0 : decimals || 2,
                sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },
        // Change Avatar
        pickFile() {
            this.$refs.fileInput.click()
        },
        onFilePicked(e) {
            const minSize = 2097152 // 2MB
            const files = e.target.files
            const file = files[0]
            const imageSize = file.size
            if (file !== undefined) {
                this.image_name = file.name
                this.image_size = this.formatBytes(file.size)
                this.image_type = file.type

                if (this.image_name.lastIndexOf('.') <= 0) {
                    return
                }

                // Validations
                if (imageSize > minSize) {
                    this.canUpload = false
                    this.err = true
                    this.errMsg = "The chosen file is higher than " + this.min_size + " MegaByte"
                } else {
                    const fr = new FileReader()
                    fr.readAsDataURL(file)
                    fr.addEventListener('load', () => {
                        this.image_url = fr.result
                        this.image = file
                    })
                    this.canUpload = true
                    this.err = false
                }

            } else {
                this.image = ""
                this.image_name = ""
                this.image_size = ""
                this.image_type = ""
                this.image_url = ""
            }
        },
        uploadProfileImage() {
            this.saving = true;
            const fd = new FormData();
            fd.append("image", this.image);
            fd.append("model_id", this.expense_id)

            Axios.post(`${apiUrl}/expenses/user/${this.user_id}/upload`, fd,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                    this.saving = false;
                    this.getExpenses()
                    this.uploadDialog = false
                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'success',
                        title: 'Request successful',
                        text: response.data.message
                    });
                })
                .catch(({ response }) => {
                    this.saving = false;
                    console.log(response.data)
                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: "Upload failed!",
                        text: response.data.message
                    });
                });
        },
        // Verify Form
        verifyForm() {
            if (this.title != "" && this.department != "" && this.transaction_type != "" && this.transaction_date != "" && this.total_amount != 0) {
                this.showPins = true
            } else {
                this.showPins = false
            }
        },
        kuPin1() {
            this.$refs.pin2.focus();
        },
        kuPin2() {
            this.$refs.pin3.focus();
        },
        kuPin3() {
            this.$refs.pin4.focus();
        },
        kuPin4() {
            this.$refs.pin5.focus();
        },
        kuPin5() {
            this.$refs.pin6.focus();
        },
        // Check PIn
        checkPinLength() {
            if (this.pin1 != "" && this.pin2 != "" && this.pin3 != "" && this.pin4 != "" && this.pin5 != "" && this.pin6 != "") {
                this.pin = this.pin1 + this.pin2 + this.pin3 + this.pin4 + this.pin5 + this.pin6
                this.disablePins = true
                this.proc_msg = "Verifying PIN"
                this.verifyPin()
            }
        },
        // Verify pin before saving expense
        verifyPin() {
            this.saving = true;
            Axios.post(
                `${apiUrl}/user/pin/check`,
                {
                    pin: this.pin,
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                    if (response.data.status) {
                        this.proc_msg = "Processing Request"
                        this.disablePins = false
                        this.showPins = false
                        this.saveExpense()
                    }
                })
                .catch(({ response }) => {
                    this.saving = false;
                    this.disablePins = false
                    this.pin1 = ""
                    this.pin2 = ""
                    this.pin3 = ""
                    this.pin4 = ""
                    this.pin5 = ""
                    this.pin6 = ""
                    this.$refs.pin1.focus();
                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: 'Request failed',
                        text: "We are unable to verify your PIN. Kindly type in the right pin"
                    });
                    console.log(response.data);
                });
        },
        // Verify Amount
        verifyAmount() {
            if (this.total_amount > 10000) {
                this.err = true
                this.errMsg = "You can only claim a sum total of 10,000 using this medium"
            } else {
                this.err = false
            }
        },
        // Save expenses
        saveExpense() {
            Axios.post(
                `${apiUrl}/${this.user_id}/expenses`,
                {
                    user_id: this.user_id,
                    title: this.title,
                    department: this.department.id,
                    transaction_type: this.transaction_type.id,
                    transaction_date: this.transaction_date,
                    total_amount: this.total_amount,
                    hod: this.department.hod
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                    this.saving = false;
                    this.disablePins = false
                    this.expenseDialog = false
                    this.getExpenses()
                    this.$notify({
                        group: 'platform',
                        duration: 15000,
                        type: 'success',
                        title: 'Request Successful',
                        text: response.data.message
                    })
                })
                .catch(({ response }) => {
                    console.log(response.data);
                    this.saving = false;
                    this.disablePins = false
                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: 'Request failed',
                        text: response.data.message
                    });
                });
        },
        loadUploader(id) {
            this.uploadDialog = true
            this.expense_id = id
        },
        // Conclude expenses
        preConclude(id) {
            this.concludeDialog = true
            this.conclude_id = id
        },
        concludeExpense() {
            this.saving = true
            Axios.put(
                `${apiUrl}/expenses/user/${this.user_id}/conclude/`,
                {
                    expense_id: this.conclude_id
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                    this.saving = false;
                    this.getExpenses()
                    this.concludeDialog = false
                    this.$notify({
                        group: 'platform',
                        duration: 15000,
                        type: 'success',
                        title: 'Request Successful',
                        text: response.data.message
                    })
                })
                .catch(({ response }) => {
                    console.log(response.data);
                    this.saving = false;
                    this.concludeDialog = false
                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: 'Request failed',
                        text: response.data.message
                    });
                });
        },
    }
}