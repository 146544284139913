<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <amount-summary-information :AmountSummaryInformation="amount_summary_data"></amount-summary-information>
      <v-layout row wrap>
          <!-- truck details -->
          <v-flex xs12 sm4>
          <v-card>
            <span v-if="loading">
              <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
            </span>
            <span v-else>
              
              <v-img :src="images.avatar" height="300px">
                <v-layout column fill-height>
                  <v-spacer></v-spacer>

                  <v-card-title class="pl-5 pt-5">
                    <div class="display-1 pl-5 pt-5">
                      {{ truck.name }}
                    </div>
                  </v-card-title>
                </v-layout>
              </v-img>
            </span>

              <v-divider inset></v-divider>

              <span v-if="loading">
                <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">person</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ truck.name }}</v-list-item-title>
                    <v-list-item-subtitle>Name</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>
              <v-divider inset></v-divider>

            <v-list two-line>
              <span v-if="loading">
                <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">person</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ truck.plate_number }}</v-list-item-title>
                    <v-list-item-subtitle>Plate Number</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loading">
                <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">person</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ truck.tmslocation.name }}</v-list-item-title>
                    <v-list-item-subtitle>Current Location</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loadingVendor">
                <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">portrait</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ truck.tmsvendor.name }}
                      </v-list-item-title>
                    <v-list-item-subtitle>Vendor Name</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loading">
                <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">account_tree</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ truck.status }}</v-list-item-title>
                    <v-list-item-subtitle>Status</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>

              <v-divider inset></v-divider>

              <span v-if="loading">
                <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
              </span>
              <span v-else>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">date_range</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ truck.created_at }}</v-list-item-title>
                    <v-list-item-subtitle>
                      Truck created &nbsp;
                      <time-ago
                        refresh
                        :datetime="truck.created_at"
                        long
                        tooltip="top"
                        class="time-ago-medium"
                      ></time-ago>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>
            </v-list>
          </v-card>
          </v-flex>
          <!-- truck details -->
          <v-flex xs12 sm8>
                <v-layout row wrap>
                  

                  <v-flex v-if="!loadingBudget" xs12>
                    <v-card>
                      <v-card-title>
                         Total Budgeted for Repairs: <span v-html="naira"></span> {{total_budgeted | formatNumber}}
                      </v-card-title>
                      <v-divider class="primary"></v-divider>
                      <v-card-text>
                        
                     <template>
                          <v-simple-table fixed-header >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Amount</th>
                                  <th class="text-left">Spent</th>
                                  <th class="text-left">Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item,i) in budget_data" :key="i">
                                  <td>
                                      <span v-html="naira"></span>
                                      {{ item.amount | formatNumber }}
                                  </td>
                                  <td class="myFocus" @click="viewSpentList(item)">Breakdown</td>
                                  <td>
                                    <v-chip color="success" dark>
                                    {{ item.created_at }}
                                    </v-chip>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                      </template>


                      </v-card-text>
                      <v-card-actions></v-card-actions>
                    </v-card>
                  </v-flex>
                  <v-flex v-else xs12>
                    <v-skeleton-loader class="mx-auto" type="list-item-three-line"></v-skeleton-loader>
                  </v-flex>

                  <v-flex xs 12>
                    <fuel-budget-information :FuelBudgetInformation="fuel_budget_information_data"></fuel-budget-information>
                  </v-flex>
                  

                  <v-flex v-if="!loadingRepairs" xs12>
                      <repairs-information :RepairsInformation="repairs_data"></repairs-information>
                  </v-flex>
                  <v-flex v-else xs12>
                    <v-skeleton-loader class="mx-auto" type="list-item-three-line"></v-skeleton-loader>
                  </v-flex>

                  <v-flex v-if="!loadingMaintenance" xs12>
                    <maintenance-information :MaintenanceInformation="maintenances_data"></maintenance-information>
                  </v-flex>
                  <v-flex v-else xs12>
                    <v-skeleton-loader class="mx-auto" type="list-item-three-line"></v-skeleton-loader>
                  </v-flex>
                  
                  <v-flex v-if="!loadingLastJob" xs12>
                    <last-job-information :LastJobInformation="last_job_data"></last-job-information>
                  </v-flex>
                  <v-flex v-else xs12>
                    <v-skeleton-loader class="mx-auto" type="list-item-three-line"></v-skeleton-loader>
                  </v-flex>

                  <v-flex v-if="!loadingDamages" xs12>
                    <damages-information :DamagesInformation="damages_data"></damages-information>
                  </v-flex>
                  <v-flex v-else xs12>
                    <v-skeleton-loader class="mx-auto" type="list-item-three-line"></v-skeleton-loader>
                  </v-flex>
                  

            </v-layout>
          </v-flex>

      </v-layout>
    </v-container>

    <v-dialog v-if="spent_list_in_view != {} && item_in_view != {}" v-model="spentListDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{item_in_view.title}} [Total Spent:<span v-html="naira"></span>{{total_spent}}]</span>
            <v-spacer></v-spacer>
            <v-btn text @click="spentListDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
              
              <template>
                <v-simple-table fixed-header >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Amount</th>
                        <th class="text-left">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,i) in spent_list_in_view" :key="i">
                        <td>
                            <span v-html="naira"></span>
                            {{ item.amount | formatNumber }}
                        </td>
                        <td>
                          <v-chip color="success" dark>
                           {{ item.created_at }}
                          </v-chip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>

            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";
import MaintenanceInformation from "./MaintenanceInformation/page";
import RepairsInformation from "./RepairsInformation/page";
import LastJobInformation from "./LastJobInformation/page";
import DamagesInformation from "./DamagesInformation/page";
import AmountSummaryInformation from "./AmountSummaryInformation/page";
import FuelBudgetInformation from "./FuelBudgetInformation/page";

export default {
  created() {
    document.title = "Truck Detail";
  },

  components: {
    InnerHeader,
    MaintenanceInformation,
    RepairsInformation,
    LastJobInformation,
    DamagesInformation,
    AmountSummaryInformation,
    FuelBudgetInformation,
  },

  data() {
    return {
      fuel_budget_information_data: {
        truck: {},
        fuel_budget_data:[],
        loading:true,
        total_budgeted: 0,
      },
      amount_summary_data:{
        total_repairs_budget: 0,
        total_repairs_spent: 0,
        total_fuelling_budget: 0,
        total_fuelling_spent: 0,
      },
      maintenances_data: [],
      repairs_data: [],
      last_job_data: [],
      damages_data: [],
      total_spent: 0,
      spentListDialog: false,
      spent_list_in_view:[],
      total_budgeted: 0,
      budget_data:[],
      truck:{},
      images: {
        avatar: require("@/assets/concept_truck.jpg")
      },
      truck_id:this.$route.params.id,
      truck_slug:this.$route.params.slug,
      item_in_view : {},
      minYear: new Date().getFullYear() + '-' + '01' + '-' + '01',
      maxYear: new Date().toISOString().substr(0, 10),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Truck Detail",
        subtitle: "View all truck details",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard"
          },
          {
            text: "Detail",
            disabled: true,
            href: "location"
          }
        ]
      },
      itemCount: 0,
      headers: [
        { text: "Name", value: "name" },
        { text: "Region", value: "region" },
        { text: "Edit", value: "edit" },
     //   { text: "Delete", value: "delete" },
      ],
      naira: "&#8358;",
      loading: true,
      loadingVendor: true,
      loadingBudget: true,
      loadingRepairs: true,
      loadingMaintenance: true,
      loadingLastJob: true,
      loadingDamages: true,
    };
  },

  mixins: [
    mounted,
    computed, 
    methods
  ]
};
</script>

<style>
.v-time-ago__text {
  font-size: 14px !important;
  cursor: pointer;
  color: #0097a7 !important;
}
.pinSize {
  font-size: 80px;
  width: 70% !important;
  text-align: center !important;
}
.myFocus{
  cursor: pointer;
  text-decoration: underline;
}
</style>
