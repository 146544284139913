import Vue from 'vue'
import Router from 'vue-router'

import auth from './middleware/auth'
//import { admin, finance, ermc, manager, cs, amh } from "./middleware/roles";
 import { admin } from "./middleware/roles";

// import Dashboard from './views/Dashboard.vue'
import Dashboard from './components/General/Dashboard.vue'
import Profile from './components/Profile/Profile.vue'
import Login from './components/Login/Login.vue'
import ResetPassword from './components/ChangePassword/ResetPassword.vue'
import UserManagement from './components/Setting/User/UserManagement.vue'
import RolesPermission from './components/Setting/RolesPermission/RolesPermission.vue'
import Message from './components/Message/Message.vue'
import Vendors from './components/Vendors/Page.vue'
import Locations from './components/Locations/Page.vue'
import Trucks from './components/Trucks/Page.vue'
import Maintenance from './components/Maintenance/Page.vue'
import Damages from './components/Damages/Page.vue'
import Budget from './components/Budget/Page.vue'
import Fueling from './components/Budget_fueling/Page.vue'
import CITClients from './components/CITClients/Page.vue'
import CITMovements from './components/CITMovements/Page.vue'
// import AmountSpent from './components/AmountSpent/Page.vue'
 import TruckDetail from './components/TruckDetail/Page.vue'
import LastJob from './components/LastJob/Page.vue'
import JobOrders from './components/JobOrders/Page.vue'
import Repairs from './components/Repairs/Page.vue'
import Drivers from './components/Drivers/Page.vue'
import Users from './components/Users/Page.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        middleware: [auth]
      },
    },
    {
      path: '*',
      name: '404',
      meta: { layout: 'Authentication' },
      component: require("@/components/General/404.vue").default
    },
    {
      path: '/',
      name: 'login',
      meta: { layout: 'Authentication' },
      component: Login
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      meta: { layout: 'Authentication' },
      component: ResetPassword
    },
    {
      path: '/profile/:id',
      name: 'profile',
      component: Profile,
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '/trucks',
      name: 'Trucks',
      component: Trucks,
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '/joborders',
      name: 'jobOrders',
      component: JobOrders,
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '/repairs',
      name: 'repairs',
      component: Repairs,
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '/user-management',
      name: 'user-management',
      component: UserManagement,
      meta: {
        middleware: [auth, admin]
      }
    },
    {
      path: '/message',
      name: 'message',
      component: Message,
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '/vendors',
      name: 'Vendors',
      component: Vendors,
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '/locations',
      name: 'Locations',
      component: Locations,
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance,
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '/damages',
      name: 'damages',
      component: Damages,
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '/budget',
      name: 'budget',
      component: Budget,
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '/fueling',
      name: 'fueling',
      component: Fueling,
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '/citclients',
      name: 'citclients',
      component: CITClients,
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '/citmovements',
      name: 'citmovements',
      component: CITMovements,
      meta: {
        middleware: [auth]
      }
    },
    
    // {
    //   path: '/amountspent',
    //   name: 'amountspent',
    //   component: AmountSpent,
    //   meta: {
    //     middleware: [auth]
    //   }
    // },
    {
      path: '/truckDetail/:slug/:id',
      name: 'TruckDetail',
      component: TruckDetail,
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '/lastJob',
      name: 'lastJob',
      component: LastJob,
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '/drivers',
      name: 'drivers',
      component: Drivers,
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '/roles-permissions',
      name: 'roles-permissions',
      component: RolesPermission,
      meta: {
        middleware: [auth, admin]
      }
    },
  ],
//  mode: 'history',
  base: process.env.BASE_URL,
})

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Than run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
