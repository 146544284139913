export default {
    mounted() {
        this.getProfile();
        this.getBanks()
        this.min_size = this.formatBytes(2097152)
        this.countries = [
            { name: "Afghanistan", "code": "AF" },
            { name: "Åland Islands", "code": "AX" },
            { name: "Albania", "code": "AL" },
            { name: "Algeria", "code": "DZ" },
            { name: "American Samoa", "code": "AS" },
            { name: "AndorrA", "code": "AD" },
            { name: "Angola", "code": "AO" },
            { name: "Anguilla", "code": "AI" },
            { name: "Antarctica", "code": "AQ" },
            { name: "Antigua and Barbuda", "code": "AG" },
            { name: "Argentina", "code": "AR" },
            { name: "Armenia", "code": "AM" },
            { name: "Aruba", "code": "AW" },
            { name: "Australia", "code": "AU" },
            { name: "Austria", "code": "AT" },
            { name: "Azerbaijan", "code": "AZ" },
            { name: "Bahamas", "code": "BS" },
            { name: "Bahrain", "code": "BH" },
            { name: "Bangladesh", "code": "BD" },
            { name: "Barbados", "code": "BB" },
            { name: "Belarus", "code": "BY" },
            { name: "Belgium", "code": "BE" },
            { name: "Belize", "code": "BZ" },
            { name: "Benin", "code": "BJ" },
            { name: "Bermuda", "code": "BM" },
            { name: "Bhutan", "code": "BT" },
            { name: "Bolivia", "code": "BO" },
            { name: "Bosnia and Herzegovina", "code": "BA" },
            { name: "Botswana", "code": "BW" },
            { name: "Bouvet Island", "code": "BV" },
            { name: "Brazil", "code": "BR" },
            { name: "British Indian Ocean Territory", "code": "IO" },
            { name: "Brunei Darussalam", "code": "BN" },
            { name: "Bulgaria", "code": "BG" },
            { name: "Burkina Faso", "code": "BF" },
            { name: "Burundi", "code": "BI" },
            { name: "Cambodia", "code": "KH" },
            { name: "Cameroon", "code": "CM" },
            { name: "Canada", "code": "CA" },
            { name: "Cape Verde", "code": "CV" },
            { name: "Cayman Islands", "code": "KY" },
            { name: "Central African Republic", "code": "CF" },
            { name: "Chad", "code": "TD" },
            { name: "Chile", "code": "CL" },
            { name: "China", "code": "CN" },
            { name: "Christmas Island", "code": "CX" },
            { name: "Cocos (Keeling) Islands", "code": "CC" },
            { name: "Colombia", "code": "CO" },
            { name: "Comoros", "code": "KM" },
            { name: "Congo", "code": "CG" },
            { name: "Congo, The Democratic Republic of the", "code": "CD" },
            { name: "Cook Islands", "code": "CK" },
            { name: "Costa Rica", "code": "CR" },
            { name: "Cote D'Ivoire", "code": "CI" },
            { name: "Croatia", "code": "HR" },
            { name: "Cuba", "code": "CU" },
            { name: "Cyprus", "code": "CY" },
            { name: "Czech Republic", "code": "CZ" },
            { name: "Denmark", "code": "DK" },
            { name: "Djibouti", "code": "DJ" },
            { name: "Dominica", "code": "DM" },
            { name: "Dominican Republic", "code": "DO" },
            { name: "Ecuador", "code": "EC" },
            { name: "Egypt", "code": "EG" },
            { name: "El Salvador", "code": "SV" },
            { name: "Equatorial Guinea", "code": "GQ" },
            { name: "Eritrea", "code": "ER" },
            { name: "Estonia", "code": "EE" },
            { name: "Ethiopia", "code": "ET" },
            { name: "Falkland Islands (Malvinas)", "code": "FK" },
            { name: "Faroe Islands", "code": "FO" },
            { name: "Fiji", "code": "FJ" },
            { name: "Finland", "code": "FI" },
            { name: "France", "code": "FR" },
            { name: "French Guiana", "code": "GF" },
            { name: "French Polynesia", "code": "PF" },
            { name: "French Southern Territories", "code": "TF" },
            { name: "Gabon", "code": "GA" },
            { name: "Gambia", "code": "GM" },
            { name: "Georgia", "code": "GE" },
            { name: "Germany", "code": "DE" },
            { name: "Ghana", "code": "GH" },
            { name: "Gibraltar", "code": "GI" },
            { name: "Greece", "code": "GR" },
            { name: "Greenland", "code": "GL" },
            { name: "Grenada", "code": "GD" },
            { name: "Guadeloupe", "code": "GP" },
            { name: "Guam", "code": "GU" },
            { name: "Guatemala", "code": "GT" },
            { name: "Guernsey", "code": "GG" },
            { name: "Guinea", "code": "GN" },
            { name: "Guinea-Bissau", "code": "GW" },
            { name: "Guyana", "code": "GY" },
            { name: "Haiti", "code": "HT" },
            { name: "Heard Island and Mcdonald Islands", "code": "HM" },
            { name: "Holy See (Vatican City State)", "code": "VA" },
            { name: "Honduras", "code": "HN" },
            { name: "Hong Kong", "code": "HK" },
            { name: "Hungary", "code": "HU" },
            { name: "Iceland", "code": "IS" },
            { name: "India", "code": "IN" },
            { name: "Indonesia", "code": "ID" },
            { name: "Iran, Islamic Republic Of", "code": "IR" },
            { name: "Iraq", "code": "IQ" },
            { name: "Ireland", "code": "IE" },
            { name: "Isle of Man", "code": "IM" },
            { name: "Israel", "code": "IL" },
            { name: "Italy", "code": "IT" },
            { name: "Jamaica", "code": "JM" },
            { name: "Japan", "code": "JP" },
            { name: "Jersey", "code": "JE" },
            { name: "Jordan", "code": "JO" },
            { name: "Kazakhstan", "code": "KZ" },
            { name: "Kenya", "code": "KE" },
            { name: "Kiribati", "code": "KI" },
            { name: "Korea, Democratic People'S Republic of", "code": "KP" },
            { name: "Korea, Republic of", "code": "KR" },
            { name: "Kuwait", "code": "KW" },
            { name: "Kyrgyzstan", "code": "KG" },
            { name: "Lao People'S Democratic Republic", "code": "LA" },
            { name: "Latvia", "code": "LV" },
            { name: "Lebanon", "code": "LB" },
            { name: "Lesotho", "code": "LS" },
            { name: "Liberia", "code": "LR" },
            { name: "Libyan Arab Jamahiriya", "code": "LY" },
            { name: "Liechtenstein", "code": "LI" },
            { name: "Lithuania", "code": "LT" },
            { name: "Luxembourg", "code": "LU" },
            { name: "Macao", "code": "MO" },
            { name: "Macedonia, The Former Yugoslav Republic of", "code": "MK" },
            { name: "Madagascar", "code": "MG" },
            { name: "Malawi", "code": "MW" },
            { name: "Malaysia", "code": "MY" },
            { name: "Maldives", "code": "MV" },
            { name: "Mali", "code": "ML" },
            { name: "Malta", "code": "MT" },
            { name: "Marshall Islands", "code": "MH" },
            { name: "Martinique", "code": "MQ" },
            { name: "Mauritania", "code": "MR" },
            { name: "Mauritius", "code": "MU" },
            { name: "Mayotte", "code": "YT" },
            { name: "Mexico", "code": "MX" },
            { name: "Micronesia, Federated States of", "code": "FM" },
            { name: "Moldova, Republic of", "code": "MD" },
            { name: "Monaco", "code": "MC" },
            { name: "Mongolia", "code": "MN" },
            { name: "Montserrat", "code": "MS" },
            { name: "Morocco", "code": "MA" },
            { name: "Mozambique", "code": "MZ" },
            { name: "Myanmar", "code": "MM" },
            { name: "Namibia", "code": "NA" },
            { name: "Nauru", "code": "NR" },
            { name: "Nepal", "code": "NP" },
            { name: "Netherlands", "code": "NL" },
            { name: "Netherlands Antilles", "code": "AN" },
            { name: "New Caledonia", "code": "NC" },
            { name: "New Zealand", "code": "NZ" },
            { name: "Nicaragua", "code": "NI" },
            { name: "Niger", "code": "NE" },
            { name: "Nigeria", "code": "NG" },
            { name: "Niue", "code": "NU" },
            { name: "Norfolk Island", "code": "NF" },
            { name: "Northern Mariana Islands", "code": "MP" },
            { name: "Norway", "code": "NO" },
            { name: "Oman", "code": "OM" },
            { name: "Pakistan", "code": "PK" },
            { name: "Palau", "code": "PW" },
            { name: "Palestinian Territory, Occupied", "code": "PS" },
            { name: "Panama", "code": "PA" },
            { name: "Papua New Guinea", "code": "PG" },
            { name: "Paraguay", "code": "PY" },
            { name: "Peru", "code": "PE" },
            { name: "Philippines", "code": "PH" },
            { name: "Pitcairn", "code": "PN" },
            { name: "Poland", "code": "PL" },
            { name: "Portugal", "code": "PT" },
            { name: "Puerto Rico", "code": "PR" },
            { name: "Qatar", "code": "QA" },
            { name: "Reunion", "code": "RE" },
            { name: "Romania", "code": "RO" },
            { name: "Russian Federation", "code": "RU" },
            { name: "RWANDA", "code": "RW" },
            { name: "Saint Helena", "code": "SH" },
            { name: "Saint Kitts and Nevis", "code": "KN" },
            { name: "Saint Lucia", "code": "LC" },
            { name: "Saint Pierre and Miquelon", "code": "PM" },
            { name: "Saint Vincent and the Grenadines", "code": "VC" },
            { name: "Samoa", "code": "WS" },
            { name: "San Marino", "code": "SM" },
            { name: "Sao Tome and Principe", "code": "ST" },
            { name: "Saudi Arabia", "code": "SA" },
            { name: "Senegal", "code": "SN" },
            { name: "Serbia and Montenegro", "code": "CS" },
            { name: "Seychelles", "code": "SC" },
            { name: "Sierra Leone", "code": "SL" },
            { name: "Singapore", "code": "SG" },
            { name: "Slovakia", "code": "SK" },
            { name: "Slovenia", "code": "SI" },
            { name: "Solomon Islands", "code": "SB" },
            { name: "Somalia", "code": "SO" },
            { name: "South Africa", "code": "ZA" },
            { name: "South Georgia and the South Sandwich Islands", "code": "GS" },
            { name: "Spain", "code": "ES" },
            { name: "Sri Lanka", "code": "LK" },
            { name: "Sudan", "code": "SD" },
            { name: "Suriname", "code": "SR" },
            { name: "Svalbard and Jan Mayen", "code": "SJ" },
            { name: "Swaziland", "code": "SZ" },
            { name: "Sweden", "code": "SE" },
            { name: "Switzerland", "code": "CH" },
            { name: "Syrian Arab Republic", "code": "SY" },
            { name: "Taiwan, Province of China", "code": "TW" },
            { name: "Tajikistan", "code": "TJ" },
            { name: "Tanzania, United Republic of", "code": "TZ" },
            { name: "Thailand", "code": "TH" },
            { name: "Timor-Leste", "code": "TL" },
            { name: "Togo", "code": "TG" },
            { name: "Tokelau", "code": "TK" },
            { name: "Tonga", "code": "TO" },
            { name: "Trinidad and Tobago", "code": "TT" },
            { name: "Tunisia", "code": "TN" },
            { name: "Turkey", "code": "TR" },
            { name: "Turkmenistan", "code": "TM" },
            { name: "Turks and Caicos Islands", "code": "TC" },
            { name: "Tuvalu", "code": "TV" },
            { name: "Uganda", "code": "UG" },
            { name: "Ukraine", "code": "UA" },
            { name: "United Arab Emirates", "code": "AE" },
            { name: "United Kingdom", "code": "GB" },
            { name: "United States", "code": "US" },
            { name: "United States Minor Outlying Islands", "code": "UM" },
            { name: "Uruguay", "code": "UY" },
            { name: "Uzbekistan", "code": "UZ" },
            { name: "Vanuatu", "code": "VU" },
            { name: "Venezuela", "code": "VE" },
            { name: "Viet Nam", "code": "VN" },
            { name: "Virgin Islands, British", "code": "VG" },
            { name: "Virgin Islands, U.S.", "code": "VI" },
            { name: "Wallis and Futuna", "code": "WF" },
            { name: "Western Sahara", "code": "EH" },
            { name: "Yemen", "code": "YE" },
            { name: "Zambia", "code": "ZM" },
            { name: "Zimbabwe", "code": "ZW" }
        ]
        this.states = [
            {
                name: "Abia",
                lgas: [
                    "Aba North",
                    "Aba South",
                    "Arochukwu",
                    "Bende",
                    "Ikwuano",
                    "Isiala-Ngwa North",
                    "Isiala-Ngwa South",
                    "Isuikwato",
                    "Obi Nwa",
                    "Ohafia",
                    "Osisioma",
                    "Ngwa",
                    "Ugwunagbo",
                    "Ukwa East",
                    "Ukwa West",
                    "Umuahia North",
                    "Umuahia South",
                    "Umu-Neochi"
                ],
            },
            {
                name: "Adamawa",
                lgas: [
                    "Demsa",
                    "Fufore",
                    "Ganaye",
                    "Gireri",
                    "Gombi",
                    "Guyuk",
                    "Hong",
                    "Jada",
                    "Lamurde",
                    "Madagali",
                    "Maiha",
                    "Mayo-Belwa",
                    "Michika",
                    "Mubi North",
                    "Mubi South",
                    "Numan",
                    "Shelleng",
                    "Song",
                    "Toungo",
                    "Yola North",
                    "Yola South"
                ]
            },
            {
                name: "Anambra",
                lgas: [
                    "Aguata",
                    "Anambra East",
                    "Anambra West",
                    "Anaocha",
                    "Awka North",
                    "Awka South",
                    "Ayamelum",
                    "Dunukofia",
                    "Ekwusigo",
                    "Idemili North",
                    "Idemili south",
                    "Ihiala",
                    "Njikoka",
                    "Nnewi North",
                    "Nnewi South",
                    "Ogbaru",
                    "Onitsha North",
                    "Onitsha South",
                    "Orumba North",
                    "Orumba South",
                    "Oyi"
                ]
            },
            {
                name: "Akwa Ibom",
                lgas: [
                    "Abak",
                    "Eastern Obolo",
                    "Eket",
                    "Esit Eket",
                    "Essien Udim",
                    "Etim Ekpo",
                    "Etinan",
                    "Ibeno",
                    "Ibesikpo Asutan",
                    "Ibiono Ibom",
                    "Ika",
                    "Ikono",
                    "Ikot Abasi",
                    "Ikot Ekpene",
                    "Ini",
                    "Itu",
                    "Mbo",
                    "Mkpat Enin",
                    "Nsit Atai",
                    "Nsit Ibom",
                    "Nsit Ubium",
                    "Obot Akara",
                    "Okobo",
                    "Onna",
                    "Oron",
                    "Oruk Anam",
                    "Udung Uko",
                    "Ukanafun",
                    "Uruan",
                    "Urue-Offong/Oruko ",
                    "Uyo"
                ]
            },
            {
                name: "Bauchi",
                lgas: [
                    "Alkaleri",
                    "Bauchi",
                    "Bogoro",
                    "Damban",
                    "Darazo",
                    "Dass",
                    "Ganjuwa",
                    "Giade",
                    "Itas/Gadau",
                    "Jama'are",
                    "Katagum",
                    "Kirfi",
                    "Misau",
                    "Ningi",
                    "Shira",
                    "Tafawa-Balewa",
                    "Toro",
                    "Warji",
                    "Zaki"
                ]
            },
            {
                name: "Bayelsa",
                lgas: [
                    "Brass",
                    "Ekeremor",
                    "Kolokuma/Opokuma",
                    "Nembe",
                    "Ogbia",
                    "Sagbama",
                    "Southern Jaw",
                    "Yenegoa"
                ]
            },
            {
                name: "Benue",
                lgas: [
                    "Ado",
                    "Agatu",
                    "Apa",
                    "Buruku",
                    "Gboko",
                    "Guma",
                    "Gwer East",
                    "Gwer West",
                    "Katsina-Ala",
                    "Konshisha",
                    "Kwande",
                    "Logo",
                    "Makurdi",
                    "Obi",
                    "Ogbadibo",
                    "Oju",
                    "Okpokwu",
                    "Ohimini",
                    "Oturkpo",
                    "Tarka",
                    "Ukum",
                    "Ushongo",
                    "Vandeikya"
                ]
            },
            {
                name: "Borno",
                lgas: [
                    "Abadam",
                    "Askira/Uba",
                    "Bama",
                    "Bayo",
                    "Biu",
                    "Chibok",
                    "Damboa",
                    "Dikwa",
                    "Gubio",
                    "Guzamala",
                    "Gwoza",
                    "Hawul",
                    "Jere",
                    "Kaga",
                    "Kala/Balge",
                    "Konduga",
                    "Kukawa",
                    "Kwaya Kusar",
                    "Mafa",
                    "Magumeri",
                    "Maiduguri",
                    "Marte",
                    "Mobbar",
                    "Monguno",
                    "Ngala",
                    "Nganzai",
                    "Shani"
                ]
            },
            {
                name: "Cross River",
                lgas: [
                    "Akpabuyo",
                    "Odukpani",
                    "Akamkpa",
                    "Biase",
                    "Abi",
                    "Ikom",
                    "Yarkur",
                    "Odubra",
                    "Boki",
                    "Ogoja",
                    "Yala",
                    "Obanliku",
                    "Obudu",
                    "Calabar South",
                    "Etung",
                    "Bekwara",
                    "Bakassi",
                    "Calabar Municipality"
                ]
            },
            {
                name: "Delta",
                lgas: [
                    "Oshimili",
                    "Aniocha",
                    "Aniocha South",
                    "Ika South",
                    "Ika North-East",
                    "Ndokwa West",
                    "Ndokwa East",
                    "Isoko south",
                    "Isoko North",
                    "Bomadi",
                    "Burutu",
                    "Ughelli South",
                    "Ughelli North",
                    "Ethiope West",
                    "Ethiope East",
                    "Sapele",
                    "Okpe",
                    "Warri North",
                    "Warri South",
                    "Uvwie",
                    "Udu",
                    "Warri Central",
                    "Ukwani",
                    "Oshimili North",
                    "Patani"
                ]
            },
            {
                name: "Ebonyi",
                lgas: [
                    "Afikpo South",
                    "Afikpo North",
                    "Onicha",
                    "Ohaozara",
                    "Abakaliki",
                    "Ishielu",
                    "lkwo",
                    "Ezza",
                    "Ezza South",
                    "Ohaukwu",
                    "Ebonyi",
                    "Ivo"
                ]
            },
            {
                name: "Enugu",
                lgas: [
                    "Enugu South,",
                    "Igbo-Eze South",
                    "Enugu North",
                    "Nkanu",
                    "Udi Agwu",
                    "Oji-River",
                    "Ezeagu",
                    "IgboEze North",
                    "Isi-Uzo",
                    "Nsukka",
                    "Igbo-Ekiti",
                    "Uzo-Uwani",
                    "Enugu Eas",
                    "Aninri",
                    "Nkanu East",
                    "Udenu."
                ]
            },
            {
                name: "Edo",
                lgas: [
                    "Esan North-East",
                    "Esan Central",
                    "Esan West",
                    "Egor",
                    "Ukpoba",
                    "Central",
                    "Etsako Central",
                    "Igueben",
                    "Oredo",
                    "Ovia SouthWest",
                    "Ovia South-East",
                    "Orhionwon",
                    "Uhunmwonde",
                    "Etsako East",
                    "Esan South-East"
                ]
            },
            {
                name: "Ekiti",
                lgas: [
                    "Ado",
                    "Ekiti-East",
                    "Ekiti-West",
                    "Emure/Ise/Orun",
                    "Ekiti South-West",
                    "Ikare",
                    "Irepodun",
                    "Ijero,",
                    "Ido/Osi",
                    "Oye",
                    "Ikole",
                    "Moba",
                    "Gbonyin",
                    "Efon",
                    "Ise/Orun",
                    "Ilejemeje."
                ]
            },
            {
                name: "FCT - Abuja",
                lgas: [
                    "Abaji",
                    "Abuja Municipal",
                    "Bwari",
                    "Gwagwalada",
                    "Kuje",
                    "Kwali"
                ]
            },
            {
                name: "Gombe",
                lgas: [
                    "Akko",
                    "Balanga",
                    "Billiri",
                    "Dukku",
                    "Kaltungo",
                    "Kwami",
                    "Shomgom",
                    "Funakaye",
                    "Gombe",
                    "Nafada/Bajoga",
                    "Yamaltu/Delta."
                ]
            },
            {
                name: "Imo",
                lgas: [
                    "Aboh-Mbaise",
                    "Ahiazu-Mbaise",
                    "Ehime-Mbano",
                    "Ezinihitte",
                    "Ideato North",
                    "Ideato South",
                    "Ihitte/Uboma",
                    "Ikeduru",
                    "Isiala Mbano",
                    "Isu",
                    "Mbaitoli",
                    "Mbaitoli",
                    "Ngor-Okpala",
                    "Njaba",
                    "Nwangele",
                    "Nkwerre",
                    "Obowo",
                    "Oguta",
                    "Ohaji/Egbema",
                    "Okigwe",
                    "Orlu",
                    "Orsu",
                    "Oru East",
                    "Oru West",
                    "Owerri-Municipal",
                    "Owerri North",
                    "Owerri West"
                ]
            },
            {
                name: "Jigawa",
                lgas: [
                    "Auyo",
                    "Babura",
                    "Birni Kudu",
                    "Biriniwa",
                    "Buji",
                    "Dutse",
                    "Gagarawa",
                    "Garki",
                    "Gumel",
                    "Guri",
                    "Gwaram",
                    "Gwiwa",
                    "Hadejia",
                    "Jahun",
                    "Kafin Hausa",
                    "Kaugama Kazaure",
                    "Kiri Kasamma",
                    "Kiyawa",
                    "Maigatari",
                    "Malam Madori",
                    "Miga",
                    "Ringim",
                    "Roni",
                    "Sule-Tankarkar",
                    "Taura",
                    "Yankwashi"
                ]
            },
            {
                name: "Kaduna",
                lgas: [
                    "Birni-Gwari",
                    "Chikun",
                    "Giwa",
                    "Igabi",
                    "Ikara",
                    "jaba",
                    "Jema'a",
                    "Kachia",
                    "Kaduna North",
                    "Kaduna South",
                    "Kagarko",
                    "Kajuru",
                    "Kaura",
                    "Kauru",
                    "Kubau",
                    "Kudan",
                    "Lere",
                    "Makarfi",
                    "Sabon-Gari",
                    "Sanga",
                    "Soba",
                    "Zango-Kataf",
                    "Zaria"
                ]
            },
            {
                name: "Kano",
                lgas: [
                    "Ajingi",
                    "Albasu",
                    "Bagwai",
                    "Bebeji",
                    "Bichi",
                    "Bunkure",
                    "Dala",
                    "Dambatta",
                    "Dawakin Kudu",
                    "Dawakin Tofa",
                    "Doguwa",
                    "Fagge",
                    "Gabasawa",
                    "Garko",
                    "Garum",
                    "Mallam",
                    "Gaya",
                    "Gezawa",
                    "Gwale",
                    "Gwarzo",
                    "Kabo",
                    "Kano Municipal",
                    "Karaye",
                    "Kibiya",
                    "Kiru",
                    "kumbotso",
                    "Kunchi",
                    "Kura",
                    "Madobi",
                    "Makoda",
                    "Minjibir",
                    "Nasarawa",
                    "Rano",
                    "Rimin Gado",
                    "Rogo",
                    "Shanono",
                    "Sumaila",
                    "Takali",
                    "Tarauni",
                    "Tofa",
                    "Tsanyawa",
                    "Tudun Wada",
                    "Ungogo",
                    "Warawa",
                    "Wudil"
                ]
            },
            {
                name: "Katsina",
                lgas: [
                    "Bakori",
                    "Batagarawa",
                    "Batsari",
                    "Baure",
                    "Bindawa",
                    "Charanchi",
                    "Dandume",
                    "Danja",
                    "Dan Musa",
                    "Daura",
                    "Dutsi",
                    "Dutsin-Ma",
                    "Faskari",
                    "Funtua",
                    "Ingawa",
                    "Jibia",
                    "Kafur",
                    "Kaita",
                    "Kankara",
                    "Kankia",
                    "Katsina",
                    "Kurfi",
                    "Kusada",
                    "Mai'Adua",
                    "Malumfashi",
                    "Mani",
                    "Mashi",
                    "Matazuu",
                    "Musawa",
                    "Rimi",
                    "Sabuwa",
                    "Safana",
                    "Sandamu",
                    "Zango"
                ]
            },
            {
                name: "Kebbi",
                lgas: [
                    "Aleiro",
                    "Arewa-Dandi",
                    "Argungu",
                    "Augie",
                    "Bagudo",
                    "Birnin Kebbi",
                    "Bunza",
                    "Dandi",
                    "Fakai",
                    "Gwandu",
                    "Jega",
                    "Kalgo",
                    "Koko/Besse",
                    "Maiyama",
                    "Ngaski",
                    "Sakaba",
                    "Shanga",
                    "Suru",
                    "Wasagu/Danko",
                    "Yauri",
                    "Zuru"
                ]
            },
            {
                name: "Kogi",
                lgas: [
                    "Adavi",
                    "Ajaokuta",
                    "Ankpa",
                    "Bassa",
                    "Dekina",
                    "Ibaji",
                    "Idah",
                    "Igalamela-Odolu",
                    "Ijumu",
                    "Kabba/Bunu",
                    "Kogi",
                    "Lokoja",
                    "Mopa-Muro",
                    "Ofu",
                    "Ogori/Mangongo",
                    "Okehi",
                    "Okene",
                    "Olamabolo",
                    "Omala",
                    "Yagba East",
                    "Yagba West"
                ]
            },
            {
                name: "Kwara",
                lgas: [
                    "Asa",
                    "Baruten",
                    "Edu",
                    "Ekiti",
                    "Ifelodun",
                    "Ilorin East",
                    "Ilorin West",
                    "Irepodun",
                    "Isin",
                    "Kaiama",
                    "Moro",
                    "Offa",
                    "Oke-Ero",
                    "Oyun",
                    "Pategi"
                ]
            },
            {
                name: "Lagos",
                lgas: [
                    "Agege",
                    "Ajeromi-Ifelodun",
                    "Alimosho",
                    "Amuwo-Odofin",
                    "Apapa",
                    "Badagry",
                    "Epe",
                    "Eti-Osa",
                    "Ibeju/Lekki",
                    "Ifako-Ijaye",
                    "Ikeja",
                    "Ikorodu",
                    "Kosofe",
                    "Lagos Island",
                    "Lagos Mainland",
                    "Mushin",
                    "Ojo",
                    "Oshodi-Isolo",
                    "Shomolu",
                    "Surulere"
                ]
            },
            {
                name: "Nasarawa",
                lgas: [
                    "Akwanga",
                    "Awe",
                    "Doma",
                    "Karu",
                    "Keana",
                    "Keffi",
                    "Kokona",
                    "Lafia",
                    "Nasarawa",
                    "Nasarawa-Eggon",
                    "Obi",
                    "Toto",
                    "Wamba"
                ]
            },
            {
                name: "Niger",
                lgas: [
                    "Agaie",
                    "Agwara",
                    "Bida",
                    "Borgu",
                    "Bosso",
                    "Chanchaga",
                    "Edati",
                    "Gbako",
                    "Gurara",
                    "Katcha",
                    "Kontagora",
                    "Lapai",
                    "Lavun",
                    "Magama",
                    "Mariga",
                    "Mashegu",
                    "Mokwa",
                    "Muya",
                    "Pailoro",
                    "Rafi",
                    "Rijau",
                    "Shiroro",
                    "Suleja",
                    "Tafa",
                    "Wushishi"
                ]
            },
            {
                name: "Ogun",
                lgas: [
                    "Abeokuta North",
                    "Abeokuta South",
                    "Ado-Odo/Ota",
                    "Egbado North",
                    "Egbado South",
                    "Ewekoro",
                    "Ifo",
                    "Ijebu East",
                    "Ijebu North",
                    "Ijebu North East",
                    "Ijebu Ode",
                    "Ikenne",
                    "Imeko-Afon",
                    "Ipokia",
                    "Obafemi-Owode",
                    "Ogun Waterside",
                    "Odeda",
                    "Odogbolu",
                    "Remo North",
                    "Shagamu"
                ]
            },
            {
                name: "Ondo",
                lgas: [
                    "Akoko North East",
                    "Akoko North West",
                    "Akoko South Akure East",
                    "Akoko South West",
                    "Akure North",
                    "Akure South",
                    "Ese-Odo",
                    "Idanre",
                    "Ifedore",
                    "Ilaje",
                    "Ile-Oluji",
                    "Okeigbo",
                    "Irele",
                    "Odigbo",
                    "Okitipupa",
                    "Ondo East",
                    "Ondo West",
                    "Ose",
                    "Owo"
                ]
            },
            {
                name: "Osun",
                lgas: [
                    "Aiyedade",
                    "Aiyedire",
                    "Atakumosa East",
                    "Atakumosa West",
                    "Boluwaduro",
                    "Boripe",
                    "Ede North",
                    "Ede South",
                    "Egbedore",
                    "Ejigbo",
                    "Ife Central",
                    "Ife East",
                    "Ife North",
                    "Ife South",
                    "Ifedayo",
                    "Ifelodun",
                    "Ila",
                    "Ilesha East",
                    "Ilesha West",
                    "Irepodun",
                    "Irewole",
                    "Isokan",
                    "Iwo",
                    "Obokun",
                    "Odo-Otin",
                    "Ola-Oluwa",
                    "Olorunda",
                    "Oriade",
                    "Orolu",
                    "Osogbo"
                ]
            },
            {
                name: "Oyo",
                lgas: [
                    "Afijio",
                    "Akinyele",
                    "Atiba",
                    "Atigbo",
                    "Egbeda",
                    "Ibadan Central",
                    "Ibadan North",
                    "Ibadan North West",
                    "Ibadan South East",
                    "Ibadan South West",
                    "Ibarapa Central",
                    "Ibarapa East",
                    "Ibarapa North",
                    "Ido",
                    "Irepo",
                    "Iseyin",
                    "Itesiwaju",
                    "Iwajowa",
                    "Kajola",
                    "Lagelu Ogbomosho North",
                    "Ogbmosho South",
                    "Ogo Oluwa",
                    "Olorunsogo",
                    "Oluyole",
                    "Ona-Ara",
                    "Orelope",
                    "Ori Ire",
                    "Oyo East",
                    "Oyo West",
                    "Saki East",
                    "Saki West",
                    "Surulere"
                ]
            },
            {
                name: "Plateau",
                lgas: [
                    "Barikin Ladi",
                    "Bassa",
                    "Bokkos",
                    "Jos East",
                    "Jos North",
                    "Jos South",
                    "Kanam",
                    "Kanke",
                    "Langtang North",
                    "Langtang South",
                    "Mangu",
                    "Mikang",
                    "Pankshin",
                    "Qua'an Pan",
                    "Riyom",
                    "Shendam",
                    "Wase"
                ]
            },
            {
                name: "Rivers",
                lgas: [
                    "Abua/Odual",
                    "Ahoada East",
                    "Ahoada West",
                    "Akuku Toru",
                    "Andoni",
                    "Asari-Toru",
                    "Bonny",
                    "Degema",
                    "Emohua",
                    "Eleme",
                    "Etche",
                    "Gokana",
                    "Ikwerre",
                    "Khana",
                    "Obia/Akpor",
                    "Ogba/Egbema/Ndoni",
                    "Ogu/Bolo",
                    "Okrika",
                    "Omumma",
                    "Opobo/Nkoro",
                    "Oyigbo",
                    "Port-Harcourt",
                    "Tai"
                ]
            },
            {
                name: "Sokoto",
                lgas: [
                    "Binji",
                    "Bodinga",
                    "Dange-shnsi",
                    "Gada",
                    "Goronyo",
                    "Gudu",
                    "Gawabawa",
                    "Illela",
                    "Isa",
                    "Kware",
                    "kebbe",
                    "Rabah",
                    "Sabon birni",
                    "Shagari",
                    "Silame",
                    "Sokoto North",
                    "Sokoto South",
                    "Tambuwal",
                    "Tqngaza",
                    "Tureta",
                    "Wamako",
                    "Wurno",
                    "Yabo"
                ]
            },
            {
                name: "Taraba",
                lgas: [
                    "Ardo-kola",
                    "Bali",
                    "Donga",
                    "Gashaka",
                    "Cassol",
                    "Ibi",
                    "Jalingo",
                    "Karin-Lamido",
                    "Kurmi",
                    "Lau",
                    "Sardauna",
                    "Takum",
                    "Ussa",
                    "Wukari",
                    "Yorro",
                    "Zing"
                ]
            },
            {
                name: "Yobe",
                lgas: [
                    "Bade",
                    "Bursari",
                    "Damaturu",
                    "Fika",
                    "Fune",
                    "Geidam",
                    "Gujba",
                    "Gulani",
                    "Jakusko",
                    "Karasuwa",
                    "Karawa",
                    "Machina",
                    "Nangere",
                    "Nguru Potiskum",
                    "Tarmua",
                    "Yunusari",
                    "Yusufari"
                ]
            },
            {
                name: "Zamfara",
                lgas: [
                    "Anka",
                    "Bakura",
                    "Birnin Magaji",
                    "Bukkuyum",
                    "Bungudu",
                    "Gummi",
                    "Gusau",
                    "Kaura",
                    "Namoda",
                    "Maradun",
                    "Maru",
                    "Shinkafi",
                    "Talata Mafara",
                    "Tsafe",
                    "Zurmi"
                ]
            },
            {
                name: "Others",
                lgas: ["Others"]
            }
        ]
    }
}