import { apiUrl,isEmpty } from "@/config";
import Axios from "axios";

export default {
    methods: {
        searchItemsByVendor(vendor){
            if(!isEmpty(vendor)){
                this.sortItemByVendorDialog = false
                this.items = []
                this.loading = true
                this.searchItemsByVendorInAPI(vendor)
            }else{
                alert('Choose a location')
            }
        },
        publishSearchTable(data){
            data.forEach((itemm)=>{
                itemm.tmsvehicles.forEach(item=>{
                 //   alert(item.name)
                    var location = null
                    this.locations_list.forEach(loc => {
                        if(loc.id == item.tmslocation_id){
                                location = loc
                        }
                    })
                    var vendor = null
                    this.vendors_list.forEach(vend => {
                        if(vend.id == item.tmsvendor_id){
                            vendor = vend            
                        }
                    })
                    var row = {
                        id:item.id,
                        name:item.name,
                        plate_number:item.plate_number,
                        location:location,
                        vendor: vendor,
                        status: item.status,
                    }
                    this.items.push(row)
                })
            })
            this.loading = false
        },
        searchItemsByVendorInAPI(vendor){
            Axios.get(
                `${apiUrl}/tmsvendors/${vendor.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                let data = response.data.data  
                this.publishSearchTable(data) 
               console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Search Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
        },
        searchItemsByLocation(location){
            if(!isEmpty(location)){
                this.sortItemDialog = false
                this.searchItemsByLocationInAPI(location)
            }else{
                alert('Choose a location')
            }
        },
        searchItemsByLocationInAPI(location){
            this.items = []
            this.loading = true
            Axios.get(
                `${apiUrl}/tmslocations/${location.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                let data = response.data.data
                this.publishSearchTable(data)  
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Search Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
        },
        viewVendor(vendor){
            this.vendor_in_view = vendor
            this.vendorViewDialog = true
        },
        clearFields(){
            this.title = ''
            this.plate_number = ''
            this.location = {}
            this.vendor = {}
          //  this.status = ''
        },
        removeItemFromAPI(item){
            Axios.delete(
                `${apiUrl}/tmsvehicles/${item.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Truck Deleted!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Delete Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
        },
        proceedToRemoveItem(item){
            this.item_in_view = item
            this.confirmDeleteDialog = true
        },
        removeItem(item){
            this.items.forEach((element,index) => {
                if(element == item){
                    this.removeItemFromAPI(item)
                    this.items.splice(index,1) 
                }
            });
            this.confirmDeleteDialog = false
             
        },
        updateItemOnAPI(item){
            Axios.put(
                `${apiUrl}/tmsvehicles/${item.id}`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Updated Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Update Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
      
        },
        editItemArray(item){
            this.$refs.editItemForm.validate()
            if(this.validateForm()== false){
                return
            }
            let truck = 
            {
                id: item.id,
                name: this.title,
                plate_number: this.plate_number,
                location: this.location,
                vendor: this.vendor,
                status: this.vehicle_status.name,
                slug: item.slug,
                is_deleted: item.is_deleted,
                added_by: item.added_by,
                created_at: item.created_at,
                updated_at: item.updated_at,
            };

            let new_items = []
            this.items.forEach((element,index) => {
                new_items[index] = element
              if(element == item){
                    new_items[index] = truck
                    
                    let itemm = 
                    {
                        id: item.id,
                        name: this.title,
                        plate_number: this.plate_number,
                        location_id: this.location.id,
                        vendor_id: this.vendor.id,
                        status: truck.status
                    };
                    this.updateItemOnAPI(itemm)
                }
            });             
            this.items = new_items 
            this.editItemDialog = false
            this.clearFields()
        },
        editItem(item){
            this.item_in_view = item
            this.title = this.item_in_view.name
            this.plate_number = this.item_in_view.plate_number
            this.location = this.item_in_view.location
            this.vendor = this.item_in_view.vendor
            this.editItemDialog = true 
        },
        validateForm(){
            if(this.title == ''){
                return false
            }
            if(this.plate_number == ''){
                return false
            }
            if(isEmpty(this.location)){
                return false
            }
            return true
        },
        addItem() {
            this.$refs.addItemForm.validate()
            if(this.validateForm()== false){
                return
            }
            
            let truck = 
            {
                id: 0,
                name: this.title,
                plate_number: this.plate_number,
                location: this.location,
                vendor: this.vendor,
                status: this.vehicle_status.name,
                slug: 'Random slug',
                is_deleted: 'NO',
                added_by: this.user_id,
                created_at: 'Just Now',
                updated_at: 'Just Now',
            };
            this.items.push(truck)
            this.items.reverse()
            this.addItemDialog = false
            let new_item = 
            {
                name: this.title,
                plate_number: this.plate_number,
                location_id: isEmpty(this.location)? 0 : this.location.id,
                vendor_id: isEmpty(this.vendor)? 0 : this.vendor.id ,
                status: truck.status,
            };
            this.addItemToAPI(new_item)
            this.clearFields()
        },

        addItemToAPI(item){
            Axios.post(
                `${apiUrl}/tmsvehicles`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                   let data = response.data.data
                   //update the ID of the truck
                    if(data.name == item.name && data.plate_number == item.plate_number){
                        this.items.forEach((storedData,index)=>{
                            if(storedData.name == item.name && storedData.plate_number == item.plate_number){
                                this.items[index].id = data.id
                                this.items[index].slug = data.slug
                                this.items[index].is_deleted = data.is_deleted
                                this.items[index].added_by = data.added_by
                                this.items[index].created_at = data.created_at
                                this.items[index].updated_at = data.updated_at
                            }             
                        })
                    }
                   this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Truck Added!",
                    text: response.data.message
                });
                })
                .catch(({ response }) => {
                    //since the id on the datatable is defaulted to ZERO
                    item.id = 0
                    this.items.forEach((element,index) => {
                        if(element.name == item.name && element.plate_number == item.plate_number && element.id == item.id){
                            this.items.splice(index,1) 
                        }
                    });

                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: "An Error Occured",
                        text: response.data.message
                    });
                    console.log(response.data)
                });
        },
        publishTable(data){
            data.forEach(item=>{
                var location = item.tmslocation
                var vendor = {}
                this.vendors_list.forEach(vend => {
                    if(vend.id == item.tmsvendor_id){
                        vendor = vend            
                    }
                })
                var row = {
                    id:item.id,
                    name:item.name,
                    plate_number:item.plate_number,
                    location:location,
                    vendor: vendor,
                    status: item.status,
                    slug: item.slug,
                    is_deleted: item.is_deleted,
                    added_by: item.added_by,
                    created_at: item.created_at,
                    updated_at: item.updated_at,
                }
                this.items.push(row)
            })
            //since API now returns location object
            // data.forEach((item)=>{
            //     this.locations_list.forEach(loc => {
            //         var location = null
            //         if(loc.id == item.tmslocation_id){
            //                 location = loc
            //                 var vendor = null
            //                 this.vendors_list.forEach(vend => {
            //                     if(vend.id == item.tmsvendor_id){
            //                         vendor = vend            
            //                     }
            //                 })
            //                 var row = {
            //                     id:item.id,
            //                     name:item.name,
            //                     plate_number:item.plate_number,
            //                     location:location,
            //                     vendor: vendor,
            //                     status: item.status,
            //                 }
            //                 this.items.push(row)
               
            //         }
            //     })
                                    
            // })
        },
        refreshTable(){
            this.loading = true
            this.items = []
            this.getAllData()
        },
       async getAllData() {
               await Axios.get(
                    `${apiUrl}/tmsvehicles`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$root.auth}`
                        }
                    }
                )
                .then(response => {
                    let data = response.data.data.reverse()
                    this.publishTable(data)
                    this.loading = false 
                })
                .catch(({ response }) => {
                    console.log(response)
                    this.loading = false
                });
        },
        
       async getAllLocations() {
           await Axios.get(
                `${apiUrl}/tmslocations`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                let data = response.data.data.reverse()
                this.locations_list = data
            })
            .catch(({ response }) => {
                console.log(response)
            });
    },

        async getAllVendors() {
            await  Axios.get(
                    `${apiUrl}/tmsvendors`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$root.auth}`
                        }
                    }
                )
                .then(response => {
                    console.log(response)
               
                      let data = response.data.data.reverse()
                      this.vendors_list = data
                    // this.publishTable(data)
                   // this.loading = false 
                })
                .catch(({ response }) => {
                    console.log(response)
                });
        },
        
        
        
        
        
        
        
        
    }
}