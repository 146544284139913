export default {
    mounted() {
        //for some weird reasons this isn't giving results even when they are queued on wait
        //i have moved the functions inside each other's then callback
        this.getAllUsers()
        // this.getAllVehicles()
        //  this.getAllClients()
        //this.getAllData()
     
    }
}