import { apiUrl,isEmpty } from "@/config";
import Axios from "axios";

export default {
    methods: {
        userProfileDialog(item){
            this.item_in_view = item
            this.profileDialog = true
        },
        clearFields(){
            this.drivers_license = ''
            this.staff = {}
        },
        removeItemFromAPI(item){
            Axios.delete(
                `${apiUrl}/tmsdrivers/${item.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Deleted Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Delete Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
        },
        proceedToRemoveItem(item){
            this.item_in_view = item
            this.confirmDeleteDialog = true
        },
        removeItem(item){
            this.items.forEach((element,index) => {
                if(element == item){
                    this.removeItemFromAPI(item)
                    this.items.splice(index,1) 
                }
            });
            this.confirmDeleteDialog = false
             
        },
        updateItemOnAPI(item){
            Axios.put(
                `${apiUrl}/tmsdrivers/${item.user_id}`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Updated Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Update Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
      
        },
        editItemArray(item){
            let mainn = 
            {
                id: item.id,
                surname: item.staff.user.surname,
                firstname: item.staff.user.firstname,
                othernames: item.staff.user.othernames,
                email: item.staff.user.email,
                phone_number: item.staff.user.phone_number,
                avatar_id: item.staff.user.avatar_id,
                staff_id: item.staff.user.staff_id,
                drivers_license: this.drivers_license,
                staff: item.staff.user,
               // cmo: item.cmo.user,
                cmo: this.cmo,
            };

            let new_items = []
            this.items.forEach((element,index) => {
                new_items[index] = element
              if(element == item){
                    new_items[index] = mainn
                    let itemm = 
                    {
                        user_id: mainn.id,
                        drivers_license: mainn.drivers_license,
                    };
                    this.updateItemOnAPI(itemm)
                }
            });             
            this.items = new_items 
            this.editItemDialog = false
        //    this.clearFields()
        },
        editItem(item){
            console.log(item)
            this.item_in_view = item
            this.drivers_license = this.item_in_view.drivers_license
            this.staff = this.item_in_view.staff
            this.editItemDialog = true 
        },
        addItem() {
            let fullname = this.staff.name.split(' ',3)
            let item = 
            {
                id: 0,
                surname: fullname[0],
                firstname: fullname[1],
                othernames: (fullname[2] != 'null') ? fullname[2] : '',
                email: this.staff.user.email,
                phone_number: this.staff.user.phone_number,
                avatar_id: this.staff.user.avatar_id,
                staff_id: this.staff.user.staff_id,
                drivers_license: this.drivers_license,
                staff: this.staff,
                cmo: this.cmo,
            };
            this.items.push(item)
            this.items.reverse()
            this.addItemDialog = false
            let new_item = 
            {
                drivers_license: isEmpty(item.drivers_license)? 0 : item.drivers_license,
                user_id: isEmpty(this.staff)? 0 : this.staff.user.id ,
               // cmo_user_id: isEmpty(this.cmo)? 0 : this.cmo.user.id ,
                email: item.email,
                phone_number: item.phone_number,
              
            };
            this.addItemToAPI(new_item)
            this.clearFields()
        },

        addItemToAPI(item){
            Axios.post(
                `${apiUrl}/tmsdrivers`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                   let data = response.data.data
                   //update the ID of the item
                    if(data.id == item.user_id && data.damaged_what == item.damaged_what){
                        this.items.forEach((storedData,index)=>{
                            if(storedData.phone_number == item.phone_number && storedData.email == item.email){
                                this.items[index].id = data.id
                            }             
                        })
                    }
                   this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Driver Added!",
                    text: response.data.message
                });
                })
                .catch(({ response }) => {
                    //since the id on the datatable is defaulted to ZERO
                    item.id = 0
                    this.items.forEach((element,index) => {
                        if(element.phone_number == item.phone_number && element.email == item.email && element.id == item.id){
                            this.items.splice(index,1) 
                        }
                    });

                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: "An Error Occured",
                        text: response.data.message
                    });
                    console.log(response.data)
                });
        },
        
        publishTable(data){
            //for some weird reasons, users_list doesn't update on mounted with async and await
            if(isEmpty(this.users_list)){
//                alert('List is empty')
                this.refreshTable()
                return
            }
            data.forEach(item=>{
                //i rebuilt the users_list array
                this.users_list.forEach(userr => {
                    if(userr.user.id == item.id){
                        var row = 
                        {
                            id: item.id,
                            surname: userr.user.surname,
                            firstname: userr.user.firstname,
                            othernames: userr.user.othernames,
                            email: userr.user.email,
                            phone_number: userr.user.phone_number,
                            avatar_id: userr.user.avatar_id,
                            staff_id: userr.user.staff_id,
                            drivers_license: item.drivers_license,
                            staff: userr,
                            cmo: userr,//this is for test purposes
                        };

                        this.items.push(row)
                    }
                })


            })
            
        },
        refreshTable(){
            this.loading = true
            this.items = []
            this.getAllData()
        },
       async getAllData() {
               await Axios.get(
                    `${apiUrl}/tmsdrivers`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$root.auth}`
                        }
                    }
                )
                .then(response => {
                    let data = response.data.data.reverse()
                    this.publishTable(data)
                    this.loading = false
                })
                .catch(({ response }) => {
                    console.log(response)
                    this.loading = false
                });
        },
        
      

        // async getAllVendors() {
        //     await  Axios.get(
        //             `${apiUrl}/tmsvendors`,
        //             {
        //                 headers: {
        //                     Authorization: `Bearer ${this.$root.auth}`
        //                 }
        //             }
        //         )
        //         .then(response => {
        //             console.log(response)
               
        //               let data = response.data.data.reverse()
        //               this.vendors_list = data
        //             // this.publishTable(data)
        //            // this.loading = false 
        //         })
        //         .catch(({ response }) => {
        //             console.log(response)
        //         });
        // },
        
       async getAllUsers() {
          await Axios.get(
                `${apiUrl}/users/all`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                //since v-autocomplete requires a name variable in the object
                let users = []
                let data = response.data.data;
                data.forEach((user)=>{
                    var arr = {name:user.surname+' '+user.firstname+' '+user.othernames,user}
                    users.push(arr)
                })
                this.users_list = users
            })
            .catch(({ response }) => {
                console.log(response.data)
            });
        },   
    }
}