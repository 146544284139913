<template>
  <v-card>
                      <v-card-title>
                        Maintenance Information
                      </v-card-title>
                      <v-divider class="primary"></v-divider>
                      <v-card-text>
                        
                          <template>
                          <v-simple-table fixed-header >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Damaged</th>
                                  <th class="text-left">Checked</th>
                                  <th class="text-left">Replaced</th>
                                  <th class="text-left">Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item,i) in MaintenanceInformation" :key="i">
                                  <td>{{ item.damaged_what }}</td>
                                  <td>{{ item.checked_what }}</td>
                                  <td>{{ item.replaced_what }}</td>
                                  <td>
                                    <v-chip color="success" dark>
                                    {{ item.created_at }}
                                    </v-chip>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                      </template>



                      </v-card-text>
                      <v-card-actions></v-card-actions>
                    </v-card>
</template>

<script>
export default {
  name: "MaintenanceInformation",
  props: ["MaintenanceInformation"]
};
</script>
