<template>
  <div>
    
          <v-layout row wrap>
        <v-flex xs12>
            <v-layout row wrap mb-3>
              
                    <v-flex v-if="FuelBudgetInformation.loading == false" xs12 sm12>
                    <v-card>
                      <v-card-title>
                         Total Budgeted for Fuel: 
                         <span v-html="naira"></span> {{FuelBudgetInformation.total_budgeted | formatNumber}}
                      </v-card-title>
                      <v-divider class="primary"></v-divider>
                      <v-card-text>
                        
                     <template>
                          <v-simple-table fixed-header >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Amount</th>
                                  <th class="text-left">Spent</th>
                                  <th class="text-left">Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item,i) in FuelBudgetInformation.fuel_budget_data" :key="i">
                                  <td>
                                      <span v-html="naira"></span>
                                      {{ item.amount | formatNumber }}
                                  </td>
                                  <td class="myFocus" @click="viewSpentList(item)">Breakdown</td>
                                  <td>
                                    <v-chip color="success" dark>
                                    {{ item.created_at }}
                                    </v-chip>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                      </template>


                      </v-card-text>
                      <v-card-actions></v-card-actions>
                    </v-card>
                  </v-flex>
                  <v-flex v-else xs12>
                    <v-skeleton-loader class="mx-auto" type="list-item-three-line"></v-skeleton-loader>
                  </v-flex> 
                 <!-- {{FuelBudgetInformation}} -->
            </v-layout>
        </v-flex>
      
      </v-layout> 

    <v-dialog v-if="spent_list_in_view != {} && item_in_view != {}" v-model="spentListDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{item_in_view.title}} [Total Spent:<span v-html="naira"></span>{{total_spent}}]</span>
            <v-spacer></v-spacer>
            <v-btn text @click="spentListDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
              
              <template>
                <v-simple-table fixed-header >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Amount</th>
                        <th class="text-left">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,i) in spent_list_in_view" :key="i">
                        <td>
                            <span v-html="naira"></span>
                            {{ item.amount | formatNumber }}
                        </td>
                        <td>
                          <v-chip color="success" dark>
                           {{ item.created_at }}
                          </v-chip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>

            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

      
  </div>
</template>

<script>
import methods from "./methods";
import mounted from "./mounted";
export default {
  name: "FuelBudgetInformation",
  props: ["FuelBudgetInformation"],
  created() {
      
    },
  data(){
    return {
      item_in_view : {},
      naira: "&#8358;",
      truck_id:this.$route.params.id,
      spentListDialog: false,
      spent_list_in_view:[],
      total_budgeted: 0,
      total_spent: 0,
      budget_data:[],
      truck: this.FuelBudgetInformation.truck,
      loading: this.FuelBudgetInformation.loading,
      amount_summary_data:{
        total_repairs_budget: 0,
        total_repairs_spent: 0,
        total_fuelling_budget: 0,
        total_fuelling_spent: 0,
      },
    }
  },
  mixins: [
    mounted,
    // computed,
     methods
     ]
};
</script>
