<template>
  <div class="dashboard">
    <inner-header :headerData="headerData"></inner-header>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              
              <!-- <v-spacer></v-spacer> 
              <v-btn fab color="blue" class="white--text" @click="refreshTable()">
                <v-icon>refresh</v-icon>
              </v-btn> -->
              <v-spacer></v-spacer> 
              <v-btn  fab color="secondary" @click="addItemDialog = true">
                <v-icon>add</v-icon>
                <!-- Add a Maintenance -->
              </v-btn>
            </v-card-title>
            <v-divider class="primary"></v-divider>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                      <td width="10">
                        <v-chip
                          class="ma-2"
                          color="green"
                          text-color="white"
                        >
                          <v-icon left>build</v-icon>
                          <h2>
                            <span v-if="item.vehicle != {}">
                                <router-link class="white--text myFocus" :to="'truckDetail/'+item.vehicle.slug+'/'+item.vehicle.id">
                                  {{item.vehicle.name}}
                                </router-link>
                              </span>  
                            <span v-else>No Vehicle was added</span>  
                          </h2>   
                        </v-chip>
                        
                      </td>
                      <td>{{ item.description }}</td>
                      <td @click="openEvidenceDialog(item)" class="myFocus">
                        <span v-if="item.evidence != '' && item.evidence != null">
                           <v-img
                              :src="item.evidence"
                              class="is-clickable"
                              alt="Evidence Img"
                              height="100%"
                              width="40px"
                            ></v-img>
                          </span>
                          <span v-else>
                            add Evidence
                          </span>
                      </td>
                      <td>
                        <span v-if="item.user != {}">{{ item.user.surname }} {{ item.user.firstname }}</span>
                        <span v-else>User was not Found</span>
                      </td>
                      <td> 
                          <v-chip color="success" dark>{{item.date_damaged}}</v-chip>    
                      </td>
                      <td>
                        <v-btn color="blue" fab small dark @click="editItem(item)">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn color="red" fab small dark @click="proceedToRemoveItem(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </td>
                      
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>Total: {{ itemCount | formatNumber}}</v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- add item dialog -->
    <v-dialog v-model="addItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{add_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="addItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>

                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="vehicle"
                        v-model="vehicle"
                        :items="vehicles_list"
                        item-text="name"
                        label="Choose a Vehicle"
                        :rules="vehicleRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    
                    <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="date_damaged"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="date_damaged"
                                  label="Date Of Damage"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  class="ml-5"
                                ></v-text-field>
                              </template>
                              <v-date-picker 
                                color="primary" 
                                :min="minYear" 
                                :max="maxYear" 
                                v-model="date_damaged" 
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialog.save(date_damaged)"
                                >OK</v-btn>
                              </v-date-picker>
                            </v-dialog>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm12>
                        <v-text-field
                          name="description"
                          v-model="description"
                          type="text"
                          label="Describe the damage"
                          :rules="damagedWhatRules"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <!-- <v-layout row wrap>
                    <v-flex xs12 sm12>
                        <v-text-field
                          name="evidence"
                          v-model="evidence"
                          type="text"
                          label="Evidence"
                          :rules="damagedWhatRules"
                        ></v-text-field>
                    </v-flex>
                </v-layout> -->

              
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="primary" x-large  class="px-6" @click="addItem()">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




<!-- edit item dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="editItemDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{edit_item_dialog_title}} ID: {{item_in_view.id}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="editItemDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>

                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="vehicle"
                        v-model="vehicle"
                        :items="vehicles_list"
                        item-text="name"
                        label="Choose a Vehicle"
                        :rules="vehicleRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                    <v-spacer></v-spacer>
                    
                    <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="date_damaged"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="date_damaged"
                                  label="Date Of Damage"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  class="ml-5"
                                ></v-text-field>
                              </template>
                              <v-date-picker 
                                color="primary" 
                                :min="minYear" 
                                :max="maxYear" 
                                v-model="date_damaged" 
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialog.save(date_damaged)"
                                >OK</v-btn>
                              </v-date-picker>
                            </v-dialog>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12 sm12>
                        <v-text-field
                          name="description"
                          v-model="description"
                          type="text"
                          label="Describe the damage"
                          :rules="damagedWhatRules"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <!-- <v-layout row wrap>
                    <v-flex xs12 sm12>
                        <v-text-field
                          name="evidence"
                          v-model="evidence"
                          type="text"
                          label="Evidence"
                          :rules="damagedWhatRules"
                        ></v-text-field>
                    </v-flex>
                </v-layout> -->

              
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <!-- v-if="saving" disabled -->
          <v-btn color="warning" x-large  class="px-6" @click="editItemArray(item_in_view)">
            <!-- <v-progress-circular :size="25" indeterminate></v-progress-circular> -->
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Confirm delete dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="confirmDeleteDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>{{delete_item_dialog_title}}</span>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
               <h1>Do you want to delete <br><br>"{{item_in_view.description}}"<br><br> from damages log?</h1>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn color="warning" x-large  class="px-6 white--text" @click="confirmDeleteDialog = false">
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" x-large  class="px-6 white--text" @click="removeItem(item_in_view)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- evidence dialog -->
    <v-dialog v-if="item_in_view != {} && evidenceDialog" v-model="evidenceDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>Upload Evidence</span>
            <v-spacer></v-spacer>
            <v-btn text @click="evidenceDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-flex xs12>
                  <v-card flat>
                    <v-card-text>
                      <h3>
                        Ensure the image file is
                        <span class="bold">JPG</span> or
                        <span class="bold">PNG</span> and the size should not be more than
                        <span
                          class="bold"
                        >{{ min_size }} MegaByte</span>
                      </h3>
                      <v-layout row wrap>
                        <v-flex xs12 sm3></v-flex>
                        <v-flex xs12 sm6>
                          <input
                            type="file"
                            ref="fileInput"
                            :rules="uploadRules"
                            class="no-display"
                             accept="image/jpeg"
                            @change="onFilePicked"
                          />
                          <v-card class="ma-4" shaped max-width="400">
                            <v-img
                              v-if="image_url"
                              class="white--text align-end"
                              height="400px"
                              :src="image_url"
                            ></v-img>
                            <v-img
                              v-else-if="item_in_view.evidence != null"
                              :src="item_in_view.evidence"
                              @click="pickFile"
                              class="is-clickable"
                            ></v-img>
                            <v-img
                              v-else
                              :src="images.avatar"
                              @click="pickFile"
                              class="is-clickable"
                            ></v-img>
                          </v-card>
                          <v-layout row wrap>
                            <v-flex xs12>
                              <h3>
                                Name:
                                <span class="bold">{{ image_name }}</span> &nbsp; Format:
                                <span class="bold">{{ image_type }}</span> &nbsp; Size:
                                <span class="bold">{{ image_size }}</span>
                              </h3>
                            </v-flex>
                          </v-layout>
                          <v-alert v-if="err" :value="true" type="error">{{ errMsg }}</v-alert>
                          <span class="ma-5">
                            <v-btn
                              x-large
                              outlined
                              color="primary"
                              @click="pickFile"
                            >Choose Image File To Upload</v-btn>
                          </span>
                          <v-layout row wrap v-if="saving" class="mt-5">
                            <v-flex xs12>
                              <v-progress-linear color="primary lighten-2" buffer-value="0" stream></v-progress-linear>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm3></v-flex>
                      </v-layout>
                    </v-card-text>
                    <v-card-actions v-if="canUpload">
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="canUpload"
                        :disabled="saving"
                        color="primary"
                        @click="uploadEvidenceImage(item_in_view)"
                      >
                        {{ saving ? 'Updating Evidence...' : 'Upload &amp; Save' }}
                        <v-icon right v-if="!saving">cloud_upload</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>     
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import InnerHeader from "@/components/Global/InnerHeader";
// Scripts
import mounted from "./mounted";
import computed from "./computed";
import methods from "./methods";

export default {
  created() {
    document.title = "Damages";
  },

  components: {
    InnerHeader
  },

  data() {
    return {
      images: {
        avatar: require("@/assets/avatar.png")
      },
      err: false,
      errMsg: "",
      min_size: 0,
      image_name: "",
      image_size: "",
      image_type: "",
      image_url: "",
      uploadRules: [
        value =>
          !value ||
          value.size < 2000000 ||
          "Evidence size should be less than 2 MB!"
      ],
      evidenceDialog: false,
      date_damaged: '',
      description: '',
      evidence: '',
      hasLoaded: false,
      vehicle: {},
      damaged_what: '',
      checked_what: '',
      replaced_what: '',
      user: this.$root.curuser,
      vehicles_list:[],
      delete_item_dialog_title: 'Delete a Damages',
      confirmDeleteDialog: false,
      item_in_view : {},
      add_item_dialog_title : 'Add a Damages',
      edit_item_dialog_title : 'Edit Damages',
      minYear: new Date().getFullYear() + '-' + '01' + '-' + '01',
      maxYear: new Date().toISOString().substr(0, 10),
      user_id: this.$root.curuser.id,
      headerData: {
        title: "Damages",
        subtitle: "View and manage vehicle Damages.",
        breadcrumbLinks: [
          {
            text: "Home",
            disabled: false,
            href: "dashboard"
          },
          {
            text: "Damages",
            disabled: true,
            href: "damage"
          }
        ]
      },
      users_list: [
        { id : 1, firstname : 'Chika', lastname : 'Enemnuo', othernames:'othername' },
      ],
      items: [],
      itemCount: 0,
      search: null,
      title: "",
      location: "",
      vendor: "",
      plate_number: "",
      status: "Available",
      headers: [
        { text: "Vehicle", value: "vehicle" },
        { text: "Description", value: "description" },
        { text: "Evidence", value: "evidence" },
        { text: "User", value: "user" },
        { text: "Date", value: "date" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
      avatars: [],
      uploadDialog: false,
      naira: "&#8358;",
      users: [],
      proc_msg: "",
      showPins: false,
      dialog: false,
      modal: false,
      fetching: false,
      loading: true,
      saving: false,
      canUpload: false,
      upload_progress: 0,
      addItemDialog: false,
      editItemDialog: false,
      mrgn: "ma-4",
      image: null,
      error: false,
      errors: {},
      checkedWhatRules: [v => !!v || "This field is required"],
      replacedWhatRules: [v => !!v || "This field is required"],
      vehicleRules: [v => !!v || "Choose a vehicle"],
      damagedWhatRules: [
        v => !!v || "This field is required"
      ],
      amountRules: [v => !!v || "Amount spent is required"],
    };
  },

  mixins: [mounted, computed, methods]
};
</script>

<style>
.v-time-ago__text {
  font-size: 14px !important;
  cursor: pointer;
  color: #0097a7 !important;
}
.pinSize {
  font-size: 80px;
  width: 70% !important;
  text-align: center !important;
}
.myFocus{
  cursor: pointer;
  text-decoration: underline;
}
</style>
