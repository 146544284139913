// Super Administrator
export const admin = function ({ next, router }) {
    const authUserRole = JSON.parse(window.localStorage.getItem("curuserroles"))
    if (authUserRole[0] === 'Super Administrator' || authUserRole[0] === 'Administrator') {
        next()
    } else {
        return router.push({ path: "/dashboard" })
    }
}
// ICT
export const ict = function ({ next, router }) {
    const authUserRole = JSON.parse(window.localStorage.getItem("curuserroles"))
    if (authUserRole[0] === 'ICT Executive' || authUserRole[0] === 'ICT Officer') {
        next()
    } else {
        return router.push({ path: "/dashboard" })
    }
}
// Finance
export const finance = function ({ next, router }) {
    const authUserRole = JSON.parse(window.localStorage.getItem("curuserroles"))
    if (authUserRole[0] === 'Finance Executive' || authUserRole[0] === 'Finance Officer') {
        next()
    } else {
        return router.push({ path: "/dashboard" })
    }
}
// Risk
export const ermc = function ({ next, router }) {
    const authUserRole = JSON.parse(window.localStorage.getItem("curuserroles"))
    if (authUserRole[0] === 'ERMC Executive' || authUserRole[0] === 'ERMC Officer') {
        next()
    } else {
        return router.push({ path: "/dashboard" })
    }
}
// Legal
export const legal = function ({ next, router }) {
    const authUserRole = JSON.parse(window.localStorage.getItem("curuserroles"))
    if (authUserRole[0] === 'Legal Executive' || authUserRole[0] === 'Legal Officer') {
        next()
    } else {
        return router.push({ path: "/dashboard" })
    }
}
// Project
export const project = function ({ next, router }) {
    const authUserRole = JSON.parse(window.localStorage.getItem("curuserroles"))
    if (authUserRole[0] === 'Project Management Executive' || authUserRole[0] === 'Project Management Officer') {
        next()
    } else {
        return router.push({ path: "/dashboard" })
    }
}
// BDMS
export const bdms = function ({ next, router }) {
    const authUserRole = JSON.parse(window.localStorage.getItem("curuserroles"))
    if (authUserRole[0] === 'BDMS Executive' || authUserRole[0] === 'BDMS Officer') {
        next()
    } else {
        return router.push({ path: "/dashboard" })
    }
}
// Operations
export const operations = function ({ next, router }) {
    const authUserRole = JSON.parse(window.localStorage.getItem("curuserroles"))
    if (authUserRole[0] === 'Operations Executive' || authUserRole[0] === 'Operations Officer') {
        next()
    } else {
        return router.push({ path: "/dashboard" })
    }
}
// HR
export const hr = function ({ next, router }) {
    const authUserRole = JSON.parse(window.localStorage.getItem("curuserroles"))
    if (authUserRole[0] === 'HR Executive' || authUserRole[0] === 'HR Officer') {
        next()
    } else {
        return router.push({ path: "/dashboard" })
    }
}
// Corporate Services
export const cs = function ({ next, router }) {
    const authUserRole = JSON.parse(window.localStorage.getItem("curuserroles"))
    if (authUserRole[0] === 'Corporate Services Executive' || authUserRole[0] === 'Corporate Services Officer') {
        next()
    } else {
        return router.push({ path: "/dashboard" })
    }
}
// Executive Office
export const executive = function ({ next, router }) {
    const authUserRole = JSON.parse(window.localStorage.getItem("curuserroles"))
    if (authUserRole[0] === 'Executive Officer') {
        next()
    } else {
        return router.push({ path: "/dashboard" })
    }
}
// Executive Office Manager
export const manager = function ({ next, router }) {
    const authUserRole = JSON.parse(window.localStorage.getItem("curuserroles"))
    if (authUserRole[0] === 'Manager') {
        next()
    } else {
        return router.push({ path: "/dashboard" })
    }
}
// Admin Manager and HODs
export const amh = function ({ next, router }) {
    const authUserRole = JSON.parse(window.localStorage.getItem("curuserroles"))
    if (authUserRole[0] === 'Super Administrator' || authUserRole[0] === 'Administrator' || authUserRole[0] === 'Manager' || authUserRole[0] === 'Head Of Department') {
        next()
    } else {
        return router.push({ path: "/dashboard" })
    }
}
