import { apiUrl,isLive } from "@/config";
import Axios from "axios";

export default {
    methods: {
        searchItemsByVendor(vendor){
            if(vendor != {}){
                this.sortItemByVendorDialog = false
                this.searchItemsByVendorInAPI(vendor)
            }else{
                alert('Choose a location')
            }
        },
        searchItemsByVendorInAPI(vendor){
            Axios.get(
                `${apiUrl}/tmsvehicles/${vendor.id}`,
                {
                    vendor_id: vendor.id
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.publishTable(response.data.data)        
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Search Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
        },
        searchItemsByLocation(location){
            if(location != {}){
                this.sortItemDialog = false
                this.searchItemsByLocationInAPI(location)
            }else{
                alert('Choose a location')
            }
        },
        searchItemsByLocationInAPI(location){
            Axios.get(
                `${apiUrl}/tmsvehicles/${location.id}`,
                {
                    location_id: location.id
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.publishTable(response.data.data)        
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Search Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
        },
        viewVendor(vendor){
            this.vendor_in_view = vendor
            this.vendorViewDialog = true
        },
        clearFields(){
            this.title = ''
            this.company = ''
            this.phone_number = ''
            this.email = ''
            this.address = ''
            this.contact_person = ''
        },
        removeItemFromAPI(item){
            Axios.delete(
                `${apiUrl}/tmsvendors/${item.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Vendor Deleted!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Delete Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
        },
        proceedToRemoveItem(item){
            this.item_in_view = item
            this.confirmDeleteDialog = true
        },
        removeItem(item){
            this.items.forEach((element,index) => {
                if(element == item){
                    this.removeItemFromAPI(item)
                    this.items.splice(index,1) 
                }
            });
            this.confirmDeleteDialog = false
             
        },
        updateItemOnAPI(item){
            Axios.put(
                `${apiUrl}/tmsvendors/${item.id}`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Updated Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Update Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
      
        },
        editItemArray(item){
            let vendor = 
            {
                id: item.id,
                name: this.title,
                company: this.company,
                email: this.email,
                phone_number: this.phone_number,
                state: this.state,
                country: this.country,
                contact_person: this.contact_person,
                address: this.address,
            };

            let new_items = []
            this.items.forEach((element,index) => {
                new_items[index] = element
           //   new_items.push(element)
              if(element == item){
                    new_items[index] = vendor
                    this.updateItemOnAPI(vendor)
                }
            });             
            this.items = new_items 
            this.editItemDialog = false
            this.clearFields()
        },
        editItem(item){
            this.item_in_view = item
            this.title = this.item_in_view.name
            this.company = this.item_in_view.company
            this.phone_number = this.item_in_view.phone_number
            this.email = this.item_in_view.email
            this.state = this.item_in_view.state
            this.address = this.item_in_view.address
            this.country = this.item_in_view.country
            this.contact_person = this.item_in_view.contact_person
            this.editItemDialog = true 
        },
        addItem() {
            let vendor = 
            {
                id: 0,
                name: this.title,
                company: this.company,
                phone_number: this.phone_number,
                email: this.email,
                address: this.address,
                contact_person: this.contact_person,
                state: this.state,
                country: this.country,
                
            };
         //   console.log(truck)
            this.items.push(vendor)
            this.items.reverse()
            this.addItemDialog = false
            // let new_item = 
            // {
            //     name: this.title,
            //     region: this.region,
            // };
            if(isLive==true)
            this.addItemToAPI(vendor)
            this.clearFields()
        },

        addItemToAPI(item){
            Axios.post(
                `${apiUrl}/tmsvendors`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                   let data = response.data.data
                   console.log(data)
                   //update the ID of the truck
                    if(data.name == item.name && data.region == item.region){
                        this.items.forEach((storedData,index)=>{
                            if(storedData.name == item.name && storedData.company == item.company && storedData.phone_number == item.phone_number){
                                this.items[index].id = data.id      
                            }             
                        })
                    }
                   this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Truck Added!",
                    text: response.data.message
                });
                })
                .catch(({ response }) => {
                    //since the id on the datatable is defaulted to ZERO
                    item.id = 0
                    this.items.forEach((element,index) => {
                        if(element.name == item.name && element.region == item.region && element.id == item.id){
                            this.items.splice(index,1) 
                        }
                    });

                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: "An Error Occured",
                        text: response.data.message
                    });
                    console.log(response.data)
                });
        },
        publishTable(data){
            data.forEach((item)=>{
                var row = {
                    id:item.id,
                    name:item.name,
                    company:item.company,
                    phone_number:item.phone_number,
                    email:item.email,
                    address:item.address,
                    contact_person:item.contact_person,
                    state:item.state,
                    country:item.country,
                }
                this.items.push(row)
            })
        },
       async getAllData() {
            await  Axios.get(
                    `${apiUrl}/tmsvendors`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$root.auth}`
                        }
                    }
                )
                .then(response => {
                     let data = response.data.data.reverse()
                     this.publishTable(data)
                    this.loading = false 
                })
                .catch(({ response }) => {
                    console.log(response)
                });
        },
        
        
        // Convert Number to kb -> yb
        formatBytes(bytes, decimals) {
            if (bytes == 0) return '0 Bytes';
            const k = 1024,
                dm = decimals <= 0 ? 0 : decimals || 2,
                sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },
        // Change Avatar
        pickFile() {
            this.$refs.fileInput.click()
        },
        onFilePicked(e) {
            const minSize = 2097152 // 2MB
            const files = e.target.files
            const file = files[0]
            const imageSize = file.size
            if (file !== undefined) {
                this.image_name = file.name
                this.image_size = this.formatBytes(file.size)
                this.image_type = file.type

                if (this.image_name.lastIndexOf('.') <= 0) {
                    return
                }

                // Validations
                if (imageSize > minSize) {
                    this.canUpload = false
                    this.err = true
                    this.errMsg = "The chosen file is higher than " + this.min_size + " MegaByte"
                } else {
                    const fr = new FileReader()
                    fr.readAsDataURL(file)
                    fr.addEventListener('load', () => {
                        this.image_url = fr.result
                        this.image = file
                    })
                    this.canUpload = true
                    this.err = false
                }

            } else {
                this.image = ""
                this.image_name = ""
                this.image_size = ""
                this.image_type = ""
                this.image_url = ""
            }
        },
        uploadProfileImage() {
            this.saving = true;
            const fd = new FormData();
            fd.append("image", this.image);
            fd.append("model_id", this.expense_id)

            Axios.post(`${apiUrl}/expenses/user/${this.user_id}/upload`, fd,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                    this.saving = false;
                    this.getExpenses()
                    this.uploadDialog = false
                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'success',
                        title: 'Request successful',
                        text: response.data.message
                    });
                })
                .catch(({ response }) => {
                    this.saving = false;
                    console.log(response.data)
                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: "Upload failed!",
                        text: response.data.message
                    });
                });
        },
        // Verify Form
        verifyForm() {
            if (this.title != "" && this.department != "" && this.transaction_type != "" && this.transaction_date != "" && this.total_amount != 0) {
                this.showPins = true
            } else {
                this.showPins = false
            }
        },
        kuPin1() {
            this.$refs.pin2.focus();
        },
        kuPin2() {
            this.$refs.pin3.focus();
        },
        kuPin3() {
            this.$refs.pin4.focus();
        },
        kuPin4() {
            this.$refs.pin5.focus();
        },
        kuPin5() {
            this.$refs.pin6.focus();
        },
        // Check PIn
        checkPinLength() {
            if (this.pin1 != "" && this.pin2 != "" && this.pin3 != "" && this.pin4 != "" && this.pin5 != "" && this.pin6 != "") {
                this.pin = this.pin1 + this.pin2 + this.pin3 + this.pin4 + this.pin5 + this.pin6
                this.disablePins = true
                this.proc_msg = "Verifying PIN"
                this.verifyPin()
            }
        },
        // Verify pin before saving expense
        verifyPin() {
            this.saving = true;
            Axios.post(
                `${apiUrl}/user/pin/check`,
                {
                    pin: this.pin,
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                    if (response.data.status) {
                        this.proc_msg = "Processing Request"
                        this.disablePins = false
                        this.showPins = false
                        this.saveExpense()
                    }
                })
                .catch(({ response }) => {
                    this.saving = false;
                    this.disablePins = false
                    this.pin1 = ""
                    this.pin2 = ""
                    this.pin3 = ""
                    this.pin4 = ""
                    this.pin5 = ""
                    this.pin6 = ""
                    this.$refs.pin1.focus();
                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: 'Request failed',
                        text: "We are unable to verify your PIN. Kindly type in the right pin"
                    });
                    console.log(response.data);
                });
        },
        // Verify Amount
        verifyAmount() {
            if (this.total_amount > 10000) {
                this.err = true
                this.errMsg = "You can only claim a sum total of 10,000 using this medium"
            } else {
                this.err = false
            }
        },
        // Save expenses
        saveExpense() {
            Axios.post(
                `${apiUrl}/${this.user_id}/expenses`,
                {
                    user_id: this.user_id,
                    title: this.title,
                    department: this.department.id,
                    transaction_type: this.transaction_type.id,
                    transaction_date: this.transaction_date,
                    total_amount: this.total_amount,
                    hod: this.department.hod
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                    this.saving = false;
                    this.disablePins = false
                    this.addTruckDialog = false
                    this.getExpenses()
                    this.$notify({
                        group: 'platform',
                        duration: 15000,
                        type: 'success',
                        title: 'Request Successful',
                        text: response.data.message
                    })
                })
                .catch(({ response }) => {
                    console.log(response.data);
                    this.saving = false;
                    this.disablePins = false
                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: 'Request failed',
                        text: response.data.message
                    });
                });
        },
        loadUploader(id) {
            this.uploadDialog = true
            this.expense_id = id
        },
        // Conclude expenses
        preConclude(id) {
            this.concludeDialog = true
            this.conclude_id = id
        },
        concludeExpense() {
            this.saving = true
            Axios.put(
                `${apiUrl}/expenses/user/${this.user_id}/conclude/`,
                {
                    expense_id: this.conclude_id
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                    this.saving = false;
                    this.getExpenses()
                    this.concludeDialog = false
                    this.$notify({
                        group: 'platform',
                        duration: 15000,
                        type: 'success',
                        title: 'Request Successful',
                        text: response.data.message
                    })
                })
                .catch(({ response }) => {
                    console.log(response.data);
                    this.saving = false;
                    this.concludeDialog = false
                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: 'Request failed',
                        text: response.data.message
                    });
                });
        },
    }
}