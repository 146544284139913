<template>
  <div>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12 sm8>
          <div class="inner-header-title">
            <h3 class="primary--text semi-bold" v-text="headerData.title"></h3>
          </div>
          <div class="inner-header-subtitle">
            <h5 v-text="headerData.subtitle"></h5>
          </div>
        </v-flex>
        <v-flex xs12 sm4>
          <v-breadcrumbs :items="headerData.breadcrumbLinks"></v-breadcrumbs>
          <!-- <div class="inner-header-title">
            <h3 v-text="headerData.signedInAs"></h3>
          </div>
          <div class="inner-header-subtitle">
            <h2 class="secondary--text">
              <small v-text="headerData.signedInLocation"></small> 
            </h2>
          </div> -->
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "InnerHeader",
  props: ["headerData"]
};
</script>
