import { apiUrl,isEmpty } from "@/config";
import Axios from "axios";

export default {
    methods: {
        
        clearFields(){
            this.description = ''
            this.tracking_number = ''
            this.vehicle = {}
        },
        removeItemFromAPI(item){
            Axios.delete(
                `${apiUrl}/tmsjoborders/${item.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Deleted Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Delete Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
        },
        proceedToRemoveItem(item){
            this.item_in_view = item
            this.confirmDeleteDialog = true
        },
        removeItem(item){
            this.items.forEach((element,index) => {
                if(element == item){
                    this.removeItemFromAPI(item)
                    this.items.splice(index,1) 
                }
            });
            this.confirmDeleteDialog = false
             
        },
        updateItemOnAPI(item){
            Axios.put(
                `${apiUrl}/tmsjoborders/${item.id}`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Updated Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Update Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
      
        },
        editItemArray(item){
            
            let mainn = 
            {
                id: item.id,
                vehicle: item.vehicle,//since they shouldn't change the vehicle
                description: this.description,
                tracking_number: this.tracking_number,
                created_at: item.created_at,
                    
            };

            let new_items = []
            this.items.forEach((element,index) => {
                new_items[index] = element
              if(element == item){
                    new_items[index] = mainn
                    let itemm = 
                    {
                        id: item.id,
                        vehicle_id: item.vehicle.id,
                        description: mainn.description,
                        tracking_number: mainn.tracking_number,
                     //   added_by: mainn.user.id,
                    };
                    this.updateItemOnAPI(itemm)
                }
            });             
            this.items = new_items 
            this.editItemDialog = false
            this.clearFields()
        },
        editItem(item){
            this.item_in_view = item
            this.tracking_number = this.item_in_view.tracking_number
            this.description = this.item_in_view.description
            this.vehicle = this.item_in_view.vehicle
            this.editItemDialog = true 
        },
        addItem() {
            let item = 
            {
                id: 0,
                vehicle: this.vehicle,
                description: this.description,
                tracking_number: this.tracking_number,
          //      user: this.user,
                created_at: 'Just Now'
                    
            };
            this.items.push(item)
            this.items.reverse()
            this.addItemDialog = false
            let new_item = 
            {
                tracking_number: item.tracking_number,
                description: item.description,
                vehicle_id: isEmpty(item.vehicle)? 0 : item.vehicle.id,
            //    added_by: isEmpty(this.user)? 0 : item.user.id ,
            };
            this.addItemToAPI(new_item)
            this.clearFields()
        },

        addItemToAPI(item){
            Axios.post(
                `${apiUrl}/tmsjoborders`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                   let data = response.data.data
                   //update the ID of the truck
                    if(data.tmsvehicle_id == item.vehicle_id && data.tracking_number == item.tracking_number){
                        this.items.forEach((storedData,index)=>{
                            if(storedData.tracking_number == item.tracking_number && storedData.vehicle.id == item.vehicle_id){
                                this.items[index].id = data.id
                                this.items[index].created_at = data.created_at
                            }             
                        })
                    }
                   this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Last Job Added!",
                    text: response.data.message
                });
                })
                .catch(({ response }) => {
                    //since the id on the datatable is defaulted to ZERO
                    item.id = 0
                    this.items.forEach((element,index) => {
                        if(element.tracking_number == item.tracking_number && element.vehicle.id == item.vehicle_id && element.id == item.id){
                            this.items.splice(index,1) 
                        }
                    });

                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: "An Error Occured",
                        text: response.data.message
                    });
                    console.log(response.data)
                });
        },
        async getAllVehicles() {
            await Axios.get(
                 `${apiUrl}/tmsvehicles`,
                 {
                     headers: {
                         Authorization: `Bearer ${this.$root.auth}`
                     }
                 }
             )
             .then(response => {
                let data = response.data.data.reverse()
                this.vehicles_list = data
             })
             .catch(({ response }) => {
                 console.log(response)
                 this.loading = false
             });
        },
        publishTable(data){
            //for some weird reasons, vehicles_list doesn't update on mounted with async and await
            if(isEmpty(this.vehicles_list)){
              //  alert('List is empty')
                this.getAllVehicles()
                this.refreshTable()
                return
            }
            
            data.forEach(item=>{
                // var user = {}
                // this.users_list.forEach(userr => {
                //     if(userr.id == item.added_by){
                //         user = userr            
                //     }
                // })

                var vehicle = {}
                this.vehicles_list.forEach(vehiclee => {
                    if(vehiclee.id == item.tmsvehicle_id){
                        vehicle = vehiclee           
                    }
                })

                let row = 
                    {
                        id: item.id,
                        vehicle: vehicle,
                        description: item.description,
                        tracking_number: item.tracking_number,
                   //     user: user,
                        created_at: item.created_at,
                            
                    };
                this.items.push(row)
            })
            
        },
        refreshTable(){
            this.loading = true
            this.items = []
            this.getAllData()
        },
       async getAllData() {
               await Axios.get(
                    `${apiUrl}/tmsjoborders`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$root.auth}`
                        }
                    }
                )
                .then(response => {
                    let data = response.data.data.reverse()
                    this.publishTable(data)
                    this.loading = false
                })
                .catch(({ response }) => {
                    console.log(response)
                    this.loading = false
                });
        },
        
      

        async getAllVendors() {
            await  Axios.get(
                    `${apiUrl}/tmsvendors`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$root.auth}`
                        }
                    }
                )
                .then(response => {
                    console.log(response)
               
                      let data = response.data.data.reverse()
                      this.vendors_list = data
                    // this.publishTable(data)
                   // this.loading = false 
                })
                .catch(({ response }) => {
                    console.log(response)
                });
        },
        
       async getAllUsers() {
          await Axios.get(
                `${apiUrl}/users/all`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.users_list = response.data.data;
            })
            .catch(({ response }) => {
                console.log(response.data)
            });
        },   
    }
}