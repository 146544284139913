// export default {
//     // Development
//     apiUrl: 'http://127.0.0.1:8000/api/v1',

//     // Production
//     // apiUrl: 'http://xloutsourcingltd.com/careers/api'
// };

// Development Local Host
// export const apiUrl = "http://127.0.0.1:8000/api/v1"
// Development Local Network
//export const apiUrl = "http://10.1.93.6:8001/api/v1"
//export const apiUrl = "https://xloutsourcingltd.com/expense/api/v1"
//export const apiUrl = "https://xlafricagroup.com/icmsportal/api/v1"
export const apiUrl = "https://icms.com.ng/portal/api/v1"
export const isLive = true

export const getHeader = function() {
    const tokenData = this.$root.auth
    const headers = {
        'Authorization': 'Bearer' + tokenData
    }
    return headers
}

export const isEmpty = function isEmpty(obj){
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function todayDate() { 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today =  yyyy + '-' + mm + '-' + dd;
    return today;
 }

 export function timeNow() { 
    let today = new Date();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    return time
 }