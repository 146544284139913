<template>
  <div>
      <v-layout row wrap>
        <v-flex xs12>
            <v-layout row wrap mb-3>
              
              <v-flex xs12 sm3>
                <v-card class="green pa-4 darken-3">
                  <v-card-title>
                    <h4 class="white--text">
                    Repair Budget 
                    </h4>
                  </v-card-title>
                  <v-card-text class="white--text">
                    <h6 class="display-1"> <span v-html="naira"></span> {{AmountSummaryInformation.total_repairs_budget| formatNumber}}</h6>
                  </v-card-text>
                </v-card>
              </v-flex>


              <v-flex xs12 sm3>
                <v-card class="red pa-4 darken-3">
                  <v-card-title>
                    <h4 class="white--text">
                    Spent on Repairs
                    </h4>
                  </v-card-title>
                  <v-card-text class="white--text">
                    <h6 class="display-1"> <span v-html="naira"></span> {{AmountSummaryInformation.total_repairs_spent| formatNumber}}</h6>
                  </v-card-text>
                </v-card>
              </v-flex>


              <v-flex xs12 sm3>
                <v-card class="blue pa-4 darken-3">
                  <v-card-title>
                    <h4 class="white--text">
                    Fuel Budget 
                    </h4>
                  </v-card-title>
                  <v-card-text class="white--text">
                    <h6 class="display-1"> <span v-html="naira"></span> {{AmountSummaryInformation.total_fuelling_budget | formatNumber}}</h6>
                  </v-card-text>
                </v-card>
              </v-flex>


              <v-flex xs12 sm3>
                <v-card class="white pa-4 darken-3">
                  <v-card-title>
                    <h4>
                    Spent on Fuel 
                    </h4>
                  </v-card-title>
                  <v-card-text>
                    <h6 class="display-1"> <span v-html="naira"></span> {{AmountSummaryInformation.total_fuelling_spent | formatNumber}}</h6>
                  </v-card-text>
                </v-card>
              </v-flex>

            </v-layout>
        </v-flex>
      
      </v-layout> 
  </div>
</template>

<script>
//import methods from "./methods";
export default {
  name: "AmountSummaryInformation",
  props: ["AmountSummaryInformation"],
  data(){
    return {
      item_in_view : {},
      naira: "&#8358;",
    }
  },
  mixins: [
    //mounted, computed,
   //  methods
     ]
};
</script>
