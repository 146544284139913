<template>
  <div>
      <!-- View Movement detail dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="Detail.dialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>Movement Detail</span>
            <v-spacer></v-spacer>
            <v-btn text @click="Detail.dialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
              
               <h4 class="font-weight-black ma-2"><span>Number of Escorts</span>: {{Detail.item_in_view.number_of_escorts}}</h4>
               <h4 class="font-weight-black  ma-2">Escorts: {{Detail.item_in_view.escort_ids}}</h4>
               <h4 class="font-weight-black ma-2">citclientbranch id: {{Detail.item_in_view.citclientbranch_id}}</h4>
               <h4 class="font-weight-black ma-2">Estimated Distance: {{Detail.item_in_view.estimated_distance}}</h4>
               <h4 class="font-weight-black ma-2"><span @click="openDispatchedByDialog(Detail)">is dispatched? <v-icon class="myFocus">edit</v-icon></span> <v-chip class="white--text ma-2" :color="(Detail.item_in_view.is_dispatched =='YES')?'green':'red'">{{Detail.item_in_view.is_dispatched}}</v-chip></h4>
               <h4 class="font-weight-black ma-2"><span @click="openDispatchedByDialog(Detail)">Dispatched by <v-icon class="myFocus">edit</v-icon></span>: 
                    <span v-if="Detail.item_in_view.dispatched_by_object != null">{{Detail.item_in_view.dispatched_by_object.surname + ' '+ Detail.item_in_view.dispatched_by_object.firstname}}</span>
               </h4>
               <h4 class="font-weight-black ma-2"><span>Dispatched on</span>: {{Detail.item_in_view.dispatched_on}}</h4>
               <h4 class="font-weight-black ma-2"><span>Dispatch Time</span>: {{Detail.item_in_view.dispatch_time}}</h4>
               <h4 class="font-weight-black ma-2"><span>Estimated Return Time</span>: {{Detail.item_in_view.estimated_return_time}}</h4>
               <h4 class="font-weight-black ma-2"><span  @click="openConfirmedByDialog(Detail)">Confirmed By <v-icon class="myFocus">edit</v-icon></span>: 
                    <span v-if="Detail.item_in_view.confirmed_by_object != null">{{Detail.item_in_view.confirmed_by_object.surname + ' '+ Detail.item_in_view.confirmed_by_object.firstname}}</span>
                </h4>
               <h4 class="font-weight-black ma-2"><span>Confirmed on</span>: {{Detail.item_in_view.confirmed_on}}</h4>
               <h4 class="font-weight-black ma-2"><span @click="openReturnedTimeDialog(Detail)">Returned Time <v-icon class="myFocus">edit</v-icon></span>: {{Detail.item_in_view.returned_time}}</h4>
               <h4 class="font-weight-black ma-2"><span @click="openReturnedTimeDialog(Detail)">is Returned? <v-icon class="myFocus">edit</v-icon></span>: <v-chip class="white--text ma-2" :color="(Detail.item_in_view.is_returned =='YES')?'green':'red'">{{Detail.item_in_view.is_returned}}</v-chip></h4>
               <h4 class="font-weight-black ma-2"><span @click="openTripFeedbackDialog(Detail)">Trip Feedback <v-icon class="myFocus">edit</v-icon></span>: {{Detail.item_in_view.trip_feedback}}</h4>
               <h4 class="font-weight-black ma-2"><span>Is cancelled?</span>: <v-chip class="white--text ma-2" :color="(Detail.item_in_view.is_cancelled =='YES')?'green':'red'">{{Detail.item_in_view.is_cancelled}}</v-chip></h4>
               <h4 class="font-weight-black ma-2"><span @click="openCancelledByDialog(Detail)">Cancelled by <v-icon class="myFocus">edit</v-icon></span>: 
                  <span v-if="Detail.item_in_view.cancelled_by_object != null">{{Detail.item_in_view.cancelled_by_object.surname + ' '+ Detail.item_in_view.cancelled_by_object.firstname}}</span>
               </h4>
               <h4 class="font-weight-black ma-2"><span @click="openCancelledByDialog(Detail)">Cancelled On <v-icon class="myFocus">edit</v-icon></span>: {{Detail.item_in_view.cancelled_on}}</h4>
               <h4 class="font-weight-black ma-2"><span  @click="openCancelledByDialog(Detail)">Reason for cancel <v-icon class="myFocus">edit</v-icon></span>: {{Detail.item_in_view.reason_for_cancel}}</h4>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- trip Feedback dialog -->
    <v-dialog v-if="item_in_view != {}" v-model="tripFeedbackDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>Trip Feedback</span>
            <v-spacer></v-spacer>
            <v-btn text @click="tripFeedbackDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
               <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <v-text-field
                      name="trip_feedback"
                      v-model="trip_feedback"
                      label="What happened on this trip?"
                      type="text"
                      :value="Detail.item_in_view.trip_feedback"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-flex>
                  
                </v-layout>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <v-btn color="red" x-large  class="px-6 white--text" @click="updateTrip(Detail)">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      
      <!-- dispatched_by dialog -->
      <v-dialog v-if="item_in_view != {}" v-model="dispatchedByDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>Choose dispatching officer</span>
            <v-spacer></v-spacer>
            <v-btn text @click="dispatchedByDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
               <v-layout row wrap>
                  <v-flex xs12 sm12>
                    
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="user"
                        v-model="user"
                        :items="Detail.users_list"
                        item-text="name"
                        label="Choose a Approving User"
                        :rules="requiredRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                  </v-flex>
                  
                </v-layout>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <v-btn color="red" x-large  class="px-6 white--text" @click="updateDispatchedBy(Detail)">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- confirmed_by dialog -->
      <v-dialog v-if="item_in_view != {}" v-model="confirmedByDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>Choose Confirming officer</span>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmedByDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
               <v-layout row wrap>
                  <v-flex xs12 sm12>
                    
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="user"
                        v-model="user"
                        :items="Detail.users_list"
                        item-text="name"
                        label="Choose a Confirming User"
                        :rules="requiredRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                  </v-flex>
                  
                </v-layout>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <v-btn color="red" x-large  class="px-6 white--text" @click="updateConfirmedBy(Detail)">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- cancelled_by dialog -->
      <v-dialog v-if="item_in_view != {}" v-model="cancelledByDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>Choose cancelling officer</span>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelledByDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>
               <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <v-flex xs12 sm6>
                        <v-autocomplete
                        name="user"
                        v-model="user"
                        :items="Detail.users_list"
                        item-text="name"
                        label="Choose a cancelling User"
                        :rules="requiredRules"
                        return-object
                    >
                    </v-autocomplete>
                    </v-flex>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <v-flex xs12 sm12>
                        <v-text-field
                          name="cancel_reason"
                          v-model="cancel_reason"
                          label="What's the reason for cancelling?"
                          type="text"
                          :rules="requiredRules"
                        ></v-text-field>
                    </v-flex>
                  </v-flex>
                </v-layout>


            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <v-btn color="red" x-large  class="px-6 white--text" @click="updateCancelledBy(Detail)">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- returned_by dialog -->
      <v-dialog v-if="item_in_view != {}" v-model="returnedTimeDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="pt-2 pb-2 primary--text">
            <span>Return Time from trip</span>
            <v-spacer></v-spacer>
            <v-btn text @click="returnedTimeDialog = false">
            <v-icon left>close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text>
          <v-card flat>
            <v-card-text>               
                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <v-flex xs12 sm12>
                        <v-text-field
                          name="return_time"
                          v-model="return_time"
                          label="What time did this trip end?"
                          type="text"
                          :rules="requiredRules"
                        ></v-text-field>
                    </v-flex>
                  </v-flex>
                </v-layout>


            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pa-4">
          
          <v-spacer></v-spacer>
          <v-btn color="red" x-large  class="px-6 white--text" @click="updateReturnTime(Detail)">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import methods from "./methods";
export default {
  name: "Detail",
  props: ["Detail"],
  data(){
    return {
      return_time : '',
      trip_feedback : '',
      cancel_reason : '',
      confirmedByDialog: false,
      dispatchedByDialog: false,
      tripFeedbackDialog: false,
      cancelledByDialog: false,
      returnedTimeDialog: false,
      user : {},
      item_in_view : {},
      naira: "&#8358;",
      requiredRules: [
        v => !!v || "This field is required"
      ],
    }
  },
  mixins: [
    //mounted, computed,
     methods
     ]
};
</script>
