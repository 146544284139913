import Axios from "axios";
import { apiUrl } from "@/config";

export default {
    methods: {
        login() {
            if (this.$refs.loginform.validate()) {
                this.loading = true;
                Axios.post(`${apiUrl}/login`, {
                    email: this.email,
                    password: this.password
                })
                    .then(response => {
                        this.loading = false;
                        this.$root.authenticated = true;
                        localStorage.setItem("authenticated", true);
                        this.$root.auth = response.data.token;
                        localStorage.setItem(
                            "auth",
                            JSON.stringify(response.data.token)
                        );
                        this.$root.curuser = response.data.user;
                        localStorage.setItem("curuser", JSON.stringify(response.data.user));
                        this.$root.curuseravatar = response.data.avatar;
                        localStorage.setItem(
                            "curuseravatar",
                            JSON.stringify(response.data.avatar)
                        );
                        this.$root.curuseravatarbig = response.data.cardImage;
                        localStorage.setItem(
                            "curuseravatarbig",
                            JSON.stringify(response.data.cardImage)
                        );
                        this.$root.curuserroles = response.data.roles;
                        localStorage.setItem(
                            "curuserroles",
                            JSON.stringify(response.data.roles)
                        );
                        this.$root.curuserpermissions = response.data.permissions;
                        localStorage.setItem(
                            "curuserpermissions",
                            JSON.stringify(response.data.permissions)
                        );
                        this.$router.push({ path: "/dashboard" })
                    })
                    .catch(({ response }) => {
                        this.loading = false;
                        this.error = true;
                        this.$root.authenticated = false;
                        localStorage.setItem("authenticated", false);
                        if (response.status === 401) {
                            this.$notify({
                                group: 'platform',
                                duration: 3000,
                                type: 'error',
                                title: 'Authentication failed',
                                text: 'These credentials do not match any of our records.'
                            });
                        } else {
                            this.errors = response.data.message;
                            // Show Successful Logged In Message
                            console.log(response.data)
                            this.$notify({
                                group: 'platform',
                                speed: 500,
                                duration: 3000,
                                type: 'error',
                                title: 'Authentication failed',
                                text: response.data.message
                            });
                        }
                    });
            }
        }
    }
}