import { apiUrl,isEmpty } from "@/config";
import Axios from "axios";

export default {
    methods: {
        updateDetailComponent(Detail){
             let data = Detail.item_in_view
             this.updateItemOnAPI(data)
        },
        viewMovementDetail(item,index){
            this.item_in_view = item
            this.viewMovementDetailDialog = true
            this.movementDetail.item_in_view = item
            this.movementDetail.index = index
            this.movementDetail.dialog = true
        },
        removeItemFromAPI(item){
            Axios.delete(
                `${apiUrl}/citmovements/${item.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Deleted Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Delete Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
        },
        proceedToRemoveItem(item){
            this.item_in_view = item
            this.confirmDeleteDialog = true
        },
        removeItem(item){
            this.items.forEach((element,index) => {
                if(element == item){
                    this.removeItemFromAPI(item)
                    this.items.splice(index,1) 
                }
            });
            this.confirmDeleteDialog = false
             
        },
        updateItemOnAPI(item){
            Axios.put(
                `${apiUrl}/citmovements/${item.id}`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Updated Successfully!",
                    text: response.data.message
                });
                console.log(response)
            })
            .catch(({ response }) => {
                this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'error',
                    title: "Update Failed!",
                    text: response.data.message
                });
                console.log(response)
            });
      
        },
        editItemArray(item){
            this.$refs.editItemForm.validate()
            if(this.validateForm()== false){
                return
            }
            
            let mainn = item
            mainn.tmsvehicle_id = this.vehicle.id            
            mainn.tmsdriver_id = this.driver.id            
            mainn.cmo_id = this.cmo.id            
            mainn.number_of_escorts = this.number_of_escort            
            mainn.citclientbranch_id = this.branch.id            
            mainn.estimated_distance = this.estimated_distance            
            mainn.dispatched_on = this.dispatched_on            
            mainn.estimated_return_time = this.estimated_return_time            
            mainn.citclientbranch = this.branch            
            mainn.tmsdriver = this.driver            
            mainn.cmo = this.cmo            
            mainn.client = this.client            
            mainn.tmsvehicle = this.vehicle            
            let escort_ids = ""
            this.tags.forEach(tag=>{
                escort_ids += tag.text
            })
            mainn.escort_ids = escort_ids.slice(0, -1)
            
            let new_items = []
            this.items.forEach((element,index) => {
                new_items[index] = element
              if(element == item){
                    new_items[index] = mainn
                    //remove client and CMO because i added it myself
                    // delete mainn.cmo
                    // delete mainn.client
                    // delete mainn.confirmed_by_object
                    // delete mainn.cancelled_by_object
                    // delete mainn.dispatched_by_object
                    // mainn.confirmed_by = (mainn.confirmed_by != null)? mainn.confirmed_by.id : null 
                    // mainn.cancelled_by = (mainn.cancelled_by != null)? mainn.cancelled_by.id : null 
                    // mainn.dispatched_by = (mainn.dispatched_by != null)? mainn.dispatched_by.id : null 
                    this.updateItemOnAPI(mainn)
                 //   this.updateItemOnAPI(item)
                }
            });             
            this.items = new_items 
            this.editItemDialog = false
            this.clearFields()
        },
        editItem(item){
            this.item_in_view = item
            this.vehicle = this.item_in_view.tmsvehicle
            this.dispatched_on = this.item_in_view.dispatched_on
            this.driver = this.item_in_view.tmsdriver
            this.cmo = this.item_in_view.cmo
            this.client = this.item_in_view.client
            this.branch = this.item_in_view.citclientbranch
            this.number_of_escort = this.item_in_view.number_of_escorts
            // this.tag = this.item_in_view.escort_ids
            // let ids = this.item_in_view.escort_ids.split(",");
            // this.tags = []
            // this.tag = ''
            // for(let i=0;i<ids.length;i++){
            //     this.tags.push(ids[i])
            // }
            // this.tag = "chika,Emeka,Stanley,"
            // this.tags = ['chika','Emeka','Stanley']
            this.estimated_return_time = this.item_in_view.estimated_return_time
            this.estimated_distance = this.item_in_view.estimated_distance
            this.editItemDialog = true 
        },
        clearFields(){
            this.vehicle = {}
            this.number_of_escort = ''
            this.dispatched_on = ''
            this.estimated_return_time = ''
            this.estimated_distance = ''
            this.branch = []
            this.client = []
            this.driver = {}
            this.cmo = {}
            this.tags = []
        },
        validateForm(){
            if(this.name == ''){
                return false
            }
            if(this.number_of_escort == '' || this.number_of_escort == '0'){
                return false
            }
            if(this.estimated_return_time == ''){
                return false
            }
            if(this.estimated_distance == ''){
                return false
            }
            if(isEmpty(this.branch)){
                return false
            }
            if(isEmpty(this.client)){
                return false
            }
            if(isEmpty(this.driver)){
                return false
            }
            if(isEmpty(this.cmo)){
                return false
            }
            return true
        },
        addItem() {
            this.$refs.addItemForm.validate()
            if(this.validateForm()== false){
                return
            }
            this.addItemDialog = false
            let escort_ids = ""
            this.tags.forEach(tag=>{
                escort_ids += tag.text
            })
            let item = 
            {
                id: 0,
                tmsvehicle_id: this.vehicle.id,
                tmsdriver_id: this.driver.id,
                cmo_id: this.cmo.id,
                number_of_escorts: this.number_of_escort,
                escort_ids: escort_ids.slice(0, -1),//"3,4,5"//remove the last comma
                citclientbranch_id: this.branch.id,
                estimated_distance: this.estimated_distance,
                is_dispatched: "NO",
                dispatched_by: null,
                dispatched_on: this.dispatched_on,
                dispatch_time: null,
                estimated_return_time: this.estimated_return_time,
                is_returned: "NO",
                confirmed_by: null,
                confirmed_on: null,
                returned_time: null,
                trip_feedback: null,
                is_cancelled: "NO",
                cancelled_by: null,
                cancelled_on: null,
                reason_for_cancel: null,
                status: "Initiated",
                slug: "111-1582560537",
                created_at: "2020-02-24 17:08:57",
                updated_at: "2020-02-24 17:08:57",
                citclientbranch:this.branch,
                tmsdriver: this.driver,
                cmo: this.cmo,//i added this myself
                client: this.client,//i added this myself
                confirmed_by_object: null,//i added this myself
                cancelled_by_object: null,//i added this myself
                dispatched_by_object: null,//i added this myself
                tmsvehicle: this.vehicle,
                    
            };
            this.items.push(item)
            this.items.reverse()
            let post_item = 
            {
                vehicle_id: item.tmsvehicle_id,
                number_of_escorts: item.number_of_escorts,
                escort_ids: item.escort_ids,
                client_branch_id: item.citclientbranch_id,
                estimated_return_time: item.estimated_return_time,
                estimated_distance: item.estimated_distance,
                cmo_id: item.cmo_id,
                driver_id: item.tmsdriver_id,
                dispatched_on:item.dispatched_on,
            };
          
            this.addItemToAPI(post_item)
            this.clearFields()
        },

        addItemToAPI(item){
            Axios.post(
                `${apiUrl}/citmovements`,
                item,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
                .then(response => {
                   let data = response.data.data
                   //update the ID of the item
                    if(data.tmsvehicle_id == item.vehicle_id && data.tmsdriver_id == item.driver_id && data.cmo_id == item.cmo_id){
                        this.items.forEach((storedData,index)=>{
                            if(storedData.tmsvehicle_id == item.vehicle_id && storedData.tmsdriver_id == item.driver_id && storedData.cmo_id == item.cmo_id){
                                this.items[index].id = data.id
                            }             
                        })
                    }
                   this.$notify({
                    group: 'platform',
                    duration: 3000,
                    type: 'success',
                    title: "Added Successfully!",
                    text: response.data.message
                });
                })
                .catch(({ response }) => {
                    //since the id on the datatable is defaulted to ZERO
                    item.id = 0
                    this.items.forEach((element,index) => {
                        if(element.tmsvehicle_id == item.tmsvehicle_id && element.tmsdriver_id == item.tmsdriver_id && element.id == item.id && item.cmo_id == element.cmo_id){
                            this.items.splice(index,1) 
                        }
                    });

                    this.$notify({
                        group: 'platform',
                        duration: 3000,
                        type: 'error',
                        title: "An Error Occured",
                        text: response.data.message
                    });
                    console.log(response.data)
                });
        },
        
        publishTable(data){
            data.forEach(item=>{
                //since API returns tmsdriver as null
                let cancelled_by_object = null
                let dispatched_by_object = null
                let confirmed_by_object = null
                let cmo = null
                let tmsdriver = null
                this.users_list.forEach(userr => {
                    if(userr.id == item.cmo_id){
                        cmo = userr            
                    }
                    if(userr.id == item.tmsdriver_id){
                        tmsdriver = userr            
                    }
                    if(item.dispatched_by != null)
                    if(userr.id == item.dispatched_by){
                        dispatched_by_object = userr            
                    }
                    if(item.cancelled_by != null)
                    if(userr.id == item.cancelled_by){
                        cancelled_by_object = userr            
                    }
                    if(item.confirmed_by != null)
                    if(userr.id == item.confirmed_by){
                        confirmed_by_object = userr            
                    }
                })
                let client = null
                this.clients_list.forEach(clientt => {
                    if(item.citclientbranch.citclient_id == clientt.id){
                        client = clientt
                    }
                })
                 item.cmo = cmo
                 item.tmsdriver = tmsdriver//this shouldn't be cos API is supposed to return it
                 item.client = client 
                 item.confirmed_by_object = confirmed_by_object 
                 item.cancelled_by_object = cancelled_by_object 
                 item.dispatched_by_object = dispatched_by_object 
                 this.items.push(item)
            })
            
        },
        refreshTable(){
            this.loading = true
            this.items = []
            this.getAllData()
        },
       async getAllData() {
        await Axios.get(
                    `${apiUrl}/citmovements`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$root.auth}`
                        }
                    }
                )
                .then(response => {
                    let data = response.data.data//.reverse() since its already in desc order
                    this.publishTable(data)
                    this.loading = false
                })
                .catch(({ response }) => {
                    console.log(response)
                    this.loading = false
                });
        },
               
       async getAllUsers() {
          await Axios.get(
                `${apiUrl}/users/all`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$root.auth}`
                    }
                }
            )
            .then(response => {
                let mylist = []
                let data = response.data.data;
                data.forEach(user =>{
                    user.name = user.surname + ' ' + user.firstname + ' ' + user.othernames
                    mylist.push(user)
                })
                this.users_list = mylist
                this.movementDetail.users_list = mylist
                this.getAllVehicles()
                
            })
            .catch(({ response }) => {
                console.log(response.data)
            });
        },
        
        async getAllVehicles() {
            await Axios.get(
                 `${apiUrl}/tmsvehicles`,
                 {
                     headers: {
                         Authorization: `Bearer ${this.$root.auth}`
                     }
                 }
             )
             .then(response => {
                let data = response.data.data.reverse()
                this.vehicles_list = data
                this.getAllClients()
             })
             .catch(({ response }) => {
                 console.log(response)
                 this.loading = false
             });
        },
        checkEscorts(){
            this.correct_escort_number = false
            if(this.number_of_escort == ''){
                this.escort_error = 'Input the number of Escorts' 
                return
            }
            if(this.tags.length != this.number_of_escort){
                this.escort_error = 'The number of Escorts does not match the names you sepecified' 
                return
            }
            this.escort_error = 'This is good to go!!!!!!!!!!' 
            this.correct_escort_number = true
        },
        setBranches(){
           this.branches_list = this.client.citclientbranches 
        },
        prepClients(data){
            data.forEach(item=>{
                var user = {}
                this.users_list.forEach(userr => {
                    if(userr.id == item.added_by){
                        user = userr            
                    }
                })
                var status_object = {}
                this.status_list.forEach(status=>{
                    if(status.name == item.status){
                        status_object = status
                    }
                })
                item.added_by = user
                item.status_object = status_object 
                this.clients_list.push(item)
                this.movementDetail.clients_list.push(item)
            })
        },
        async getAllClients() {
            await Axios.get(
                 `${apiUrl}/citclients`,
                 {
                     headers: {
                         Authorization: `Bearer ${this.$root.auth}`
                     }
                 }
             )
             .then(response => {
                 let data = response.data.data.reverse()
                 this.prepClients(data)
                 this.getAllData()
             })
             .catch(({ response }) => {
                 console.log(response)
             });
     },
    }
}