export default {
  mounted() {
    if (this.authUser) {
      this.loadTheme()
      this.getNotifications()
      this.getUnreadMessages()
      this.clock = setInterval(this.updateDateTime, 1000);
      this.fetchNotitfications = setInterval(this.getNotifications, 30000);
      this.fetchMessages = setInterval(this.getUnreadMessages, 30000);
    }
  }
}